import {Component, HostListener, OnInit} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { AuthService } from '../../../auth/auth.service';
import { Constants } from '../../../constants';
import { CaseService } from '../../cases.service';
import { ToastrService } from 'ngx-toastr';
import { FeedDataService } from '../feed-data.service';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-modal-content',
  templateUrl: './single-feed-modal.component.html',
  styleUrls: ['./single-feed-modal.component.scss']
})
export class SingleFeedModalComponent implements OnInit {
  public displayImageUrl;
  public befActDisplayImage;
  public tooltipText;
  public selected: any;
  public isBefAftActive = true;
  public isBlurActive = true;
  public isBoxHovered = false;
  public toggleTrans = true;
  public imageList = [];
  public modalImages;
  public originalModalImages;
  public currentUserId;
  public caseItem;
  public isCasePublic;
  public casePrivacyOptions;
  public casePrivacyValue;
  public modalRef: BsModalRef;
  public isSelectorShown = true;
  public placeholderImage;
  public angleIndex = 0;
  public angleSelectorIndex = 0;
  public minLimit;
  public maxLimit;
  treatmentsCount = 0;

  public embed = false;

  constructor(private sanitization: DomSanitizer, private _authService: AuthService, private constants: Constants,
              private _caseService: CaseService, private _toastr: ToastrService, private _feedDataService: FeedDataService,
              private _router: Router) {
    this.minLimit = this.constants.MIN_IMG;
    this.maxLimit = this.constants.MAX_IMG;
    this.casePrivacyOptions = this.constants.casePrivacyOptions;
    this.placeholderImage = this.sanitization.bypassSecurityTrustStyle(`url(../../../assets/images/profilePlaceholder.png)`);
  }
  @HostListener('window:scroll', [])
  onScroll(event): void {
    this.isBlurActive = this.isBefAftActive && event && event.target && event.target.scrollTop >= 0 && event.target.scrollTop <= 150;
  }

  sanitizePhoto() {
    this.originalModalImages = JSON.parse(JSON.stringify(this.imageList));
    this.originalModalImages.forEach(angle => {
      angle.forEach(value => {
        value.thumbnail_url = this.sanitization.bypassSecurityTrustStyle(`url(` + value.thumbnail_url + `)`);
        value.cropped_watermark_image_url =
          this.sanitization.bypassSecurityTrustStyle(`url(` + value.cropped_watermark_image_url + `)`);
      });
    });
    this.modalImages = [...this.originalModalImages];
  }
  ngOnInit() {
    this._feedDataService.currentModalService.subscribe(mdlService => this.modalRef = mdlService);
    this.caseItem.angles.forEach(angle => {
      this.imageList.push(angle);
      angle.forEach(treatment => {
        if (treatment.treatment_number > this.treatmentsCount) {
          this.treatmentsCount = treatment.treatment_number;
        }
      });
    });
    this.sanitizePhoto();
    this.tooltipText = 'Before/After';
    this.befActDisplayImage = this.imageList[0].length > 0 &&
      this.imageList[0][0].cropped_watermark_image_url ||
      '../../../assets/images/profilePlaceholder.png';
    this.displayImageUrl = this.originalModalImages[0][0].length > 0 &&
      this.originalModalImages[0][0].cropped_watermark_image_url ||
      '../../../assets/images/profilePlaceholder.png';
    if (this.originalModalImages.length > 0) {
      this.originalModalImages.forEach(image => {
        image[0].treatment_name = 'Before';
      });
    }
    this.currentUserId = this._authService.getCurrentUser() !== undefined ? this._authService.getCurrentUser().id : '';
    this.isCasePublic = this.caseItem.is_public;
    this.casePrivacyValue = this.isCasePublic ? this.casePrivacyOptions[0] : this.casePrivacyOptions[1];
    this.embed = this.caseItem.embed;
  }

  showTreatmentCount() {
    return this.treatmentsCount !== 0 ? `${this.treatmentsCount} ${this.treatmentsCount === 1 ? 'Treatment' : 'Treatments' }` : 'N/A';
  }

  closeModal() {
    this.modalRef.hide();
  }
  mouseEnter(thumbImageName) {
    this.tooltipText = thumbImageName;
    this.isBoxHovered = true;
  }
  mouseLeave() {
    this.isBoxHovered = false;
  }
  setDisplayImage(imageUrl, thumb) {
    this.displayImageUrl = imageUrl;
    this.selected = thumb;
    this.isBlurActive = false;
    this.isBefAftActive = false;
    this.toggleTrans = !this.toggleTrans;
  }
  isBoxActive(thumb) {
    return this.selected === thumb;
  }
  setBefAftDisplayImage() {
    this.displayImageUrl = this.befActDisplayImage;
    this.isBlurActive = true;
    this.isBefAftActive = true;
    this.selected = false;
  }
  editCase(editCase) {
    this.modalRef.hide();
    this._router.navigate(['/edit', this.caseItem.id]);
  }
  selectorMouseEnter() {
    this.isSelectorShown = true;
  }
  selectorMouseLeave() {
    this.isBefAftActive ? this.isSelectorShown = true : this.isSelectorShown = false;
  }

  angleUp() {
    if (this.angleIndex < this.originalModalImages.length - 1) {
      this.angleIndex += 1;
      this.angleSelectorIndex += 1;
    }
  }
  angleDown() {
    if (this.angleIndex > 0) {
      this.angleIndex -= 1;
      this.angleSelectorIndex -= 1;
    }
  }
  nextImg( ImageSelector) {
    if ((this.minLimit + this.constants.MAX_IMG) < ImageSelector.length - 1){
      this.minLimit += 1;
      this.maxLimit += 1;
    }
  }

  prevImg(imgIndex) {
    if (this.minLimit > this.constants.MIN_IMG) {
      this.minLimit -= 1;
      this.maxLimit -= 1;
    }
  }
}
