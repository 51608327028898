<div class="loader" *ngIf="updating">
  <span>Updating password...</span>
  <div class="animation"></div>
</div>

<div class="container" *ngIf="isTokenValid">
  <div class="row">
    <div class="login-wrapper">
      <div class="row logo">
        <div class="twelve columns">
          <img src="../../../assets/images/logo.png" />
        </div>
      </div>
      <form novalidate [formGroup]="form">
        <div class="row">
          <div class="twelve columns">
            <label for="password">To reset your password, please enter new password twice.</label>
          </div>
        </div>

        <div class="row">
          <div class="twelve columns">
            <input class="u-full-width" formControlName="formPassword" placeholder="New Password" id="password" type="password" name="password">
            <div class="form-control-feedback"
                 *ngIf="(formPassword.errors) && (formPassword.dirty || formPassword.touched)">
              <p *ngIf="formPassword.errors.minlength" class="error">Password must be at least 6 characters long</p>
              <p *ngIf="form.hasError('required', 'formPassword')" class="error">Password is required</p>
              <p *ngIf="!formPassword.errors.minlength && formPassword.errors.pattern" class="error">Password needs to have at least one uppercase one lowercase character and number</p>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="twelve columns">
            <input class="u-full-width" formControlName="formConfirmPassword" placeholder="Confirm New Password" id="passwordConfirm" type="password" name="passwordConfirm">
            <div class="form-control-feedback"
                 *ngIf="(formConfirmPassword.errors) && (formConfirmPassword.dirty || formConfirmPassword.touched)">
              <p *ngIf="form.hasError('required', 'formConfirmPassword')" class="error">Password fields must match</p>
            </div>
            <div class="form-control-feedback">
              <p *ngIf="validatorError" class="error">Password fields do not match!</p>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="twelve columns">
            <input class="button-primary u-full-width" value="Submit" type="submit" (click)="resetPassword()">
          </div>
        </div>
      </form>

    </div>
  </div>
</div>

<div class="container" *ngIf="!isTokenValid">
  <div class="row">
    <div class="login-wrapper">
      <div class="row">
        <div class="logo">
            <img src="../../../assets/images/logo.png" />
        </div>
      </div>
        <div class="row">
          <div class="twelve columns" style="text-align: center">
            <p>Your token has expired or has already been used. Please require new one by clicking
            <a href="#" routerLink="/forgot">here</a></p>
          </div>
        </div>
    </div>
  </div>
</div>
