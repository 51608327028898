import { Component, OnInit } from '@angular/core';
import { AdminService } from '../../admin.service';
import { ActivationEnd, Router} from '@angular/router';
import { Location } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { BsModalService } from 'ngx-bootstrap/modal/bs-modal.service';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import {Constants} from '../../../constants';
import {FormControl, FormGroup, Validators} from '@angular/forms';

@Component({
  selector: 'app-edit-user',
  templateUrl: './edit-user.component.html',
  styleUrls: ['../../admin.component.scss']
})
export class EditUserComponent implements OnInit {
  public user;
  private userId;
  modalRef: BsModalRef;
  public prefixes;
  form: FormGroup;
  public formNewPassword: FormControl;

  constructor(private adminService: AdminService, private router: Router, private location: Location,
              private toastr: ToastrService, private _modalService: BsModalService, private constants: Constants) {
    this.router.events.subscribe(event => {
      if (event instanceof ActivationEnd) {
        if (event.snapshot.params.id) {
          this.userId = event.snapshot.params.id;
        }
      }
    });
    this.prefixes = this.constants.prefixes;
  }

  ngOnInit() {
    this.formNewPassword = new FormControl('', [
      Validators.minLength(6),
      Validators.pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d_$@!^%?*().|\\{}\[\]/#&+-=]{6,}$/)
    ]);

    this.form = new FormGroup({
      formNewPassword: this.formNewPassword,
    });

    this.getUser();
  }

  getUser() {
    if (this.userId) {
      this.adminService.getUser(this.userId).subscribe(data => {
        this.user = data;
      });
    }
  }

  saveUser() {

    if (this.form.valid) {
      this.user.new_password =  this.form.get('formNewPassword').value;
      this.adminService.updateUser(this.userId, this.user).subscribe(
        data => {
          this.toastr.success('User successfully updated');
          this.router.navigate(['/admin/users']);
        }, err => {
          this.toastr.error('Could not update user');
        }
      );
    }

  }


  openModal(template) {
    this.modalRef = this._modalService.show(template, { class: 'modal-sm' });
  }

  closeModal() {
    this.modalRef.hide();
    this.toastr.info('OK');
  }

  revertOption(type) {
    this.user[type] = !this.user[type];
    this.modalRef.hide();
  }

  goBack() {
    this.location.back();
  }
}
