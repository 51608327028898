<div class="container admin">
  <button class="button save-button" [routerLink]="['/admin/treatment']">Add treatment</button>
  <table class="table">
    <thead class="thead-light">
    <tr>
      <th>Treatment name</th>
      <th>Is deleted</th>
      <th></th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let treatment of treatments" style="height: 30px">
      <td>{{treatment.name}}</td>
      <td>{{treatment.is_deleted ? 'YES' : 'NO'}}</td>
      <td>
        <i class="fa fa-edit" aria-hidden="true" [routerLink]="['/admin/treatment/' + treatment.id]"></i>
        <i class="fa fa-trash" aria-hidden="true" (click)="deleteTreatment(treatment.id)"></i>
      </td>
    </tr>
    </tbody>
  </table>
</div>

