<div class="logo">
    <img src="../../assets/images/logo.png" />
</div>
<i class="fa fa-chevron-left fa-2x back-button" aria-hidden="true" title="Go Back" (click)="goBack()"></i>
<div class="container add-news-box">
    <form (ngSubmit)="validateForm(f)" #f="ngForm" ngNativeValidate>
        <div class="form-group">
            <label>Title</label>
            <input type="text" class="u-full-width" name="title" placeholder="News title" [(ngModel)]="singleNews.title" value={{singleNews.title}} required>
        </div>
        <div class="form-group">
            <label>News body</label>
            <textarea class="u-full-width textarea-box" name="body" rows="3" placeholder="News body" [(ngModel)]="singleNews.body" value="singleNews.body" required maxlength="150"></textarea>
            <p class="info-message">Maximum number of characters is 150</p>
        </div>
        <div class="form-group">
            <label>Image</label>
            <input type="text" class="u-full-width" name="image" placeholder="Image url" [(ngModel)]="singleNews.image" value="singleNews.image" required>
        </div>
        <div class="form-group">
            <label>Author</label>
            <input type="text" class="u-full-width" name="author" placeholder="News author" [(ngModel)]="singleNews.author" value="singleNews.author" required>
        </div>
        <div class="form-group">
            <label>Type</label>
            <select class="u-full-width" [(ngModel)]="singleNews.news_type" name="news_type" data-ng-value="singleNews.news_type">
                <option>Blog</option>
                <option>News</option>
                <option>Sponsored</option>
            </select>
        </div>
        <div class="form-group">
            <label>Show news on landing page</label>
            <div class="u-full-width">
                <div class="form-check form-check-inline">
                    <input class="form-check-input news-checkbox" type="radio" name="showNewsOptions" id="showNews" value="true" [checked]="singleNews.is_showed === true"
                        (change)="onSelectionChange(true)">
                    <label class="form-check-label" for="showNews">Show</label>
                </div>
                <div class="form-check form-check-inline">
                    <input class="form-check-input news-checkbox" type="radio" name="showNewsOptions" id="noShowNews" value="false" [checked]="singleNews.is_showed === false"
                        (change)="onSelectionChange(false)">
                    <label class="form-check-label" for="noShowNews">Don't show</label>
                </div>
            </div>
        </div>
        <div class="form-group">
            <label>News url</label>
            <input type="text" class="u-full-width" name="news_url" placeholder="News url" [(ngModel)]="singleNews.news_url" value="singleNews.news_url">
        </div>
        <button type="submit" class="button save-button">Save</button>
    </form>
</div>
