import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import { AdminService } from '../../admin.service';

@Component({
  selector: 'app-create-edit-type',
  templateUrl: './create-edit-type.component.html',
  styleUrls: ['../../admin.component.scss']
})
export class CreateEditTypeComponent implements OnInit {

  private skinTypeId;
  public skinType;

  constructor(private router: Router, private adminService: AdminService,
              private toastr: ToastrService, private location: Location,
              private activeRouter: ActivatedRoute) {
    this.activeRouter.params.subscribe(params => {
      this.skinTypeId = params['id'];
    });
  }

  ngOnInit() {
    if (this.skinTypeId) {
      this.getSkinType();
    } else {
      this.skinType = {
        name: '',
        is_deleted: false
      };
    }
  }

  getSkinType() {
    this.adminService.getSkinType(this.skinTypeId).subscribe(
      data => {
        this.skinType = data;
      }, err => {
        this.toastr.error('Oh... something went wrong');
      }
    );
  }

  saveSkinType() {
    this.adminService.createSkinType(this.skinType).subscribe(
      data => {
        this.toastr.success('Successfully created');
        this.router.navigate(['/admin/skin-types']);
      }, err => {
        this.toastr.error('Could not create skin type');
      }
    );
  }

  updateSkinType() {
    this.adminService.updateSkinType(this.skinType).subscribe(
      data => {
        this.toastr.success('Successfully updated');
        this.router.navigate(['/admin/skin-types']);
      }, err => {
        this.toastr.error('Could not update skin type');
      }
    );
  }

  editSkinType() {
    if (this.skinTypeId) {
      this.updateSkinType();
    } else {
      this.saveSkinType();
    }
  }

  goBack() {
    this.location.back();
  }

}
