<i class="fa fa-chevron-left fa-2x back-button" aria-hidden="true" title="Go Back" (click)="goBack()"></i>

<div class="container" *ngIf="brand">
  <div class="twelve columns">

    <form (ngSubmit)="editBrand()" #form="ngForm">
      <div class="form-group">
        <label for="manufacturers">Manufacturer</label>
        <select id="manufacturers" [(ngModel)]="brand.manufacturer_id" name="manufacturer">
          <option *ngFor="let manufacturer of manufacturers" [value]="manufacturer.id">
            {{manufacturer.name}}
          </option>
        </select>
      </div>
      <div class="form-group">
        <label for="first-name">Name</label>
        <input id="first-name" name="first_name" class="u-full-width" [(ngModel)]="brand.name"/>
      </div>
      <div class="form-group">
        <label for="is-disabled">Is deleted</label>
        <input id="is-disabled" name="is_disabled" class="u-full-width" [(ngModel)]="brand.is_deleted" type="checkbox"/>
      </div>
      <button type="submit" class="button save-button">Save</button>

    </form>
  </div>

</div>
