<app-navbar></app-navbar>

<div class="container header">
  <div class="left-user"><a href="#" routerLink="/hm/{{currentUser.id}}" class="button cancel">Cancel</a></div>
  <h2 class="center">User Embed Page</h2>
  <div class="right-user"></div>
</div>

<div class="container">
  <div class="eleven columns">
    <div class="row wrapper">
      <div class="profile-form">
        <p class="form-titles">Website information</p>
        <div>
          <div class="row">
            <p>Using the below code snippets you can embed your HumazeMd gallery directly to your marketing site
              automatically! Select the gallery you would like to add to your site and use the code displayed.</p>
          </div>
          <div class="eleven columns">
            <div class="row">
              <div>
                <p>Entire gallery embed code</p>
              </div>
              <div class="eleven columns">
                <div class="embed">
                  <div class="row">
                    <textarea disabled [(ngModel)]="entireEmbedAreaValue"></iframe></textarea>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="eleven columns">
          <div class="row">
            <div>
              <p>Treatment or device specific embed code</p>
            </div>
          </div>
        </div>
        <div class="eleven columns">
          <div class="embed-dyn">
            <div class="row">
              <div class="five columns">
                <p class="option-tittle">Treatment(s)</p>
                <div *ngFor="let treatment of treatments" class="all-options">
                  <input type="checkbox" [(ngModel)]="treatmentSelection[treatment.name]" [value]="treatment.name"
                         (change)="selectionClicked()" [disabled]="dynEmbedOptionsDisabled"/>
                  <label (click)="treatmentLabelClick(treatment)" >{{treatment.name}}</label>
                </div>
              </div>
              <div class="five columns">
                <p class="brand-name-text">Brand(s)</p>
                <div *ngFor="let brand of brandsData" class="all-options">
                  <p class="brand-option-text">{{brand.name}}</p>
                  <div *ngFor="let manufacturer of brand.brands">
                    <input type="checkbox" [(ngModel)]="manufacturerSelection[manufacturer.name]"
                           [value]="manufacturer.name" (change)="selectionClicked()"
                           [disabled]="dynEmbedOptionsDisabled"/>
                    <label (click)="manufacturerLabelClick(manufacturer)">{{manufacturer.name}}</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="eleven columns">
          <div class="embed">
            <div class="row">
              <textarea disabled [(ngModel)]="dynEmbedAreaValue"></iframe></textarea>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<app-footer></app-footer>
