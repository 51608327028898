<div class="visitor-header" *ngIf="user">
  <div class="">
    <div class="ten columns provider-details" *ngIf="user">
      <div class="two columns">
        <img src="{{user.image_url ? user.image_url : '../../../assets/images/profilePlaceholder.png'}}"/>
      </div>
      <div class="nine columns">
        <div class="header-text">
          <p class="provider-name">
            {{user.prefix}} {{user.first_name}} {{user.last_name}}
          </p>
          <p class="title">
            {{user.company_name}}
          </p>
       </div>
      </div>
      <div class="eight columns">
        <div>
            <a class="edit-button" *ngIf="isOwner()" routerLink='/user/edit' >Edit Profile</a>
            <a class="website-integration-button" *ngIf="isOwner()" routerLink='/user/embed' >Website Integration</a>
        </div>

      </div>
    </div>
    <div class="two columns sn-icons">
      <a target="_blank" class="icons" href="{{user.web.includes('http') ? user.web : 'http://' + user.web}}" *ngIf="user.web">
        <i class="fa fa-globe social-icons"></i>
      </a>
      <a target="_blank" class="icons" href="{{user.facebook.includes('http') ? user.facebook : 'http://' + user.facebook}}" *ngIf="user.facebook">
        <i class="fa fa-facebook-f social-icons"></i>
      </a>
      <a target="_blank" class="icons" href="{{user.linkedin.includes('http') ? user.linkedin : 'http://' + user.linkedin}}" *ngIf="user.linkedin">
        <i class="fa fa-linkedin social-icons"></i>
      </a>
      <a target="_blank" class="icons" href="{{user.pinterest.includes('http') ? user.pinterest : 'http://' + user.pinterest}}" *ngIf="user.pinterest">
        <i class="fa fa-pinterest social-icons"></i>
      </a>
      <a target="_blank" class="icons" href="{{user.instagram.includes('http') ? user.instagram : 'http://' + user.instagram}}" *ngIf="user.instagram">
        <i class=" fa fa-instagram social-icons"></i>
      </a>
      <a target="_blank" class="icons" href="{{user.twitter.includes('http') ? user.twitter : 'http://' + user.twitter}}" *ngIf="user.twitter">
        <i class="fa fa-twitter social-icons"></i>
      </a>
    </div>
    <div class="profile-tabs" *ngIf="!isOwner()">
      <ul class="nav nav-tabs">
        <li class="nav-item">
          <a class="nav-link" [ngClass]="casesActive ? 'active' : ''" (click)="tabClicked('cases')">CASES</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" [ngClass]="aboutActive ? 'active' : ''" (click)="tabClicked('about')">ABOUT</a>
        </li>
      </ul>
    </div>

    <div class="profile-tabs" *ngIf="isOwner()">
      <ul class="nav nav-tabs">
        <li class="nav-item">
          <a class="nav-link" [ngClass]="casesActive ? 'active' : ''" (click)="tabClicked('cases')">MY CASES</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" [ngClass]="draftsActive ? 'active' : ''" (click)="tabClicked('drafts')">DRAFTS</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" [ngClass]="aboutActive ? 'active' : ''" (click)="tabClicked('about')">ABOUT</a>
        </li>
      </ul>
    </div>
  </div>
</div>
