import {AfterViewInit, Component, ElementRef, OnInit, ViewChild, ViewChildren} from '@angular/core';
import { AdminService } from '../admin.service';
import { ToastrService } from 'ngx-toastr';
import { saveAs } from 'file-saver/FileSaver';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-overall-kpis',
  templateUrl: './overall-kpis.component.html',
  styleUrls: ['../admin.component.scss', './overall-kpis.component.scss']
})
export class OverallKpisComponent implements OnInit {
  public overallKpis;
  dateFrom: Date;
  dateTo: Date;
  fromStr: String;
  toStr: String;
  isSearchAvail: boolean;
  isInvalid: boolean;
  errorMsg: String;
  @ViewChild('activeUser',{static: true}) activeUser: ElementRef;
  @ViewChild('disabledUser',{static: true}) disabledUser: ElementRef;

  constructor(private adminService: AdminService, private toastr: ToastrService,
              private datePipe: DatePipe) {
  }

  ngOnInit() {
    this.getOverall('', '');
  }

  getOverall(dateFrom, dateTo) {
    this.adminService.getOverallKpisFromDate(dateFrom, dateTo).subscribe(
      data => {
        this.overallKpis = data;
        this.toastr.success('Successfully loaded all details');
      },
      err => {
        this.toastr.error('Unable to load details');
      }
    );
  }

  exportToCsv() {
    if (!this.fromStr && !this.toStr) {
      this.fromStr = '';
      this.toStr = '';
    }
      this.adminService.exportOverallKpisToCsv(this.fromStr,  this.toStr ).subscribe(
        data => {
          const blob = new Blob([data], {type: 'text/csv'});
          saveAs(blob, 'overall-kpis' + this.datePipe.transform(Date.now(), 'yyyy-MM-dd') + '.csv');
          this.toastr.success('File is downloading');
        },
        err => {
          this.toastr.error('Unable to download file');
        }
      );
  }

  dateFromPickedHandler(date) {
    this.dateFrom = date;
    this.fromStr = this.datePipe.transform(this.dateFrom, 'MM-dd-yyyy');
    this.isSearchAvail = this.dateTo != null;
    this.isInvalid = false;
    this.errorMsg = '';
  }

  dateToPickedHandler(date) {
    this.dateTo = date;
    this.toStr = this.datePipe.transform(this.dateTo, 'MM-dd-yyyy');
    this.isSearchAvail = this.dateFrom != null;
    this.isInvalid = false;
    this.errorMsg = '';
  }

  searchData() {
    (<HTMLTableRowElement>this.activeUser.nativeElement).style.display = 'none';
    (<HTMLTableRowElement>this.disabledUser.nativeElement).style.display = 'none';
    this.loadData();
  }

  loadData() {
    if (this.isSearchAvail) {
      if (this.dateFrom > this.dateTo) {
        this.isInvalid = true;
        this.errorMsg = 'Date To must be greater or equal to Date From';
        this.overallKpis = null;
      } else {
        this.getOverall(this.fromStr,  this.toStr);
      }
    } else {
      this.isInvalid = true;
      this.errorMsg = 'Input fields are required';
    }
  }
}
