import {Component, ElementRef, OnInit, TemplateRef, ViewEncapsulation} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {AuthService} from '../../auth/auth.service';
import {UserService} from '../user.service';
import {ToastrService} from 'ngx-toastr';
import {UserModel} from '../user-model';
import {BsModalService} from 'ngx-bootstrap/modal/bs-modal.service';
import {BsModalRef} from 'ngx-bootstrap/modal/bs-modal-ref.service';
import {CaseService} from '../../cases/cases.service';
import {DataService} from '../../helpers/data-service';
import {Constants} from '../../constants';
import {Router} from '@angular/router';
import {ImageUtils} from '../../helpers/image.utils';

@Component({
  selector: 'app-user-edit-page',
  templateUrl: './user-edit-page.component.html',
  styleUrls: ['./user-edit-page.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class UserEditPageComponent implements OnInit {
  form: FormGroup;
  public firstName: FormControl;
  public lastName: FormControl;
  public email: FormControl;
  public phoneNumber: FormControl;
  public providerProfileUrl: FormControl;
  public currentUser;
  public user;
  public loadingDetails;
  public loadingImage;
  public updateImage = false;
  public watermarkText = '';
  public formSubmitted = false;
  modalRef: BsModalRef;
  userShowCropper;
  logoShowCropper;
  croppedImage: any = '';
  colleagueMailBody;
  colleagueMail;
  cancelMailBody;
  public prefixes;
  public prefix: FormControl;
  imageBase64String  = '';
  public logoImageName = '';
  public userLogoSrc = '';

  constructor(private _authService: AuthService, private _userService: UserService,
              private _toastr: ToastrService, private _modalService: BsModalService,
              private _caseService: CaseService, private _dataService: DataService,
              private constants: Constants, private _router: Router, private elementRef: ElementRef) {
    this.prefixes = this.constants.prefixes;
  }

  ngOnInit() {
    this.currentUser = this._authService.getCurrentUser();
    if (!this.currentUser) {
      this._router.navigate(['/signin/user/edit']);
      return;
    }
    this.user = new UserModel();
    this.getUser();
    this.createFormControls();
    this.createForm();
    this.loadingDetails = false;
    this.loadingImage = false;
    this.updateImage = false;
    this.userShowCropper = false;
    this.colleagueMailBody = this.constants.colleagueMailContent;
    this.cancelMailBody = this.constants.cancelMailBody;

  }

  createFormControls() {
    this.firstName = new FormControl('', [Validators.required, Validators.pattern(/^[a-z ,.'-]+$/i)]);
    this.lastName = new FormControl('', [Validators.required, Validators.pattern(/^[a-z ,.'-]+$/i)]);
    this.email = new FormControl('', [Validators.required, Validators.pattern('[^ @]*@[^ @]*')]);
    this.phoneNumber = new FormControl('', [Validators.pattern('^[0-9\\+\\-\\/]{0,50}$')]);
    this.prefix = new FormControl();
    this.providerProfileUrl = new FormControl('', [Validators.pattern('[0-9a-zA-Z_-]{5,30}$')]);
  }

  createForm() {
    this.form = new FormGroup({
      firstName: this.firstName,
      lastName: this.lastName,
      email: this.email,
      phoneNumber: this.phoneNumber,
      prefix: this.prefix,
      providerProfileUrl: this.providerProfileUrl
    });
  }

  getUser() {
    this._userService.getUserDetails(this.currentUser.id).subscribe(
      data => {
        this.user = data;
        this._dataService.changeName(this.user.first_name);
        this._dataService.changeUrl(this.user.provider_profile_url);
        this.userLogoSrc = this.user.watermark_image_url ? this.user.watermark_image_url : null;
        if (this.userLogoSrc) {
          this.logoImageName = this.userLogoSrc.substr(this.userLogoSrc.lastIndexOf('/') + 1, this.userLogoSrc.length);
        }
      },
      err => this._toastr.error('Error while loading user details.')
    );
  }

  validateForm(form) {
    this.formSubmitted = true;
    form.get('firstName').setValue(form.get('firstName').value.replace(/\s\s+/g, ' ').trim());
    form.get('lastName').setValue(form.get('lastName').value.replace(/\s\s+/g, ' ').trim());


    if (form.valid) {
      this.editUserDetails(this.user);
    }
  }

  isDeleteButtonEnabled() {
    return this.user.image_url !== null;
  }

  editUserDetails(user) {
    this.loadingDetails = true;
    this._userService.editUser(user)
      .subscribe(
        data => {
          this._authService.setCurrentUserName(this.firstName.value);
          this._authService.setProviderProfileUrl(this.providerProfileUrl.value);
          this.refreshUserDetailsData();
        },
        error => {
          this.loadingDetails = false;
          this._toastr.error('Could not update user details.');
          if (error.error.status === 'Provider url already used.') {
            this._toastr.error(error.error.status);
          }
        }
      );
  }

  deleteProfileImage(userId) {
    this.loadingImage = true;
    this._userService.deleteProfileImage(userId)
      .subscribe(
        data => {
          this._authService.setCurrentUserImage(null, 'userImg');
          this._dataService.changeProfileImage('../../../assets/images/profilePlaceholder.png');
          setTimeout(() => this.refreshData(), 3000);
        },
        error => {
          this.loadingImage = false;
          this._toastr.error('Image could not be deleted');
        }
      );
  }

  refreshData() {
    this.ngOnInit();
    this._toastr.success('Image successfully deleted');
  }

  refreshUserDetailsData() {
    this.ngOnInit();
    this._toastr.success('User details successfully updated.');
  }

  refreshUploadImage() {
    this.ngOnInit();
    this._toastr.success('Image successfully uploaded.');
  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this._modalService.show(template, {class: 'modal-sm'});
  }

  decline(): void {
    this.modalRef.hide();
  }

  confirm(userId) {
    this.deleteProfileImage(userId);
    this.modalRef.hide();
  }

  uploadImage(imgPrefix, type) {
    this.updateImage = true;
    this.userShowCropper = false;
    this.logoShowCropper = false;
    const image = this.croppedImage;
    const imageType = image.substring(image.lastIndexOf(':') + 1, image.lastIndexOf(';'));
    const hashedNameImg = imgPrefix + this._dataService.getUuid(imageType.split('/')[1]);
    const imageData = {
      name: hashedNameImg,
      type: imageType,
      buffer: this._dataService.dataURItoBlob(image)
    };

    this._caseService.getPreSignedUrl(imageData, this.currentUser.id).subscribe(
      data => {
        let imageUrl = data['url'].substr(0, data['url'].indexOf('?'));
        this._caseService.uploadBase64(data['url'], imageData).subscribe(
          s3data => {
            imageUrl = this._dataService.buildThumbUrl(imageUrl);
            this._userService.updateProfileImages(this.user.id, imageUrl, type).subscribe(
              userData => {
                this._authService.setCurrentUserImage(imageUrl, type);
                setTimeout(() => this.refreshUploadImage(), 3000);
                if (type === 'userImg') {
                  setTimeout(() => this._dataService.changeProfileImage(imageUrl), 3000);
                } else {
                  setTimeout(() => this._dataService.changeWtmkLogoImage(imageUrl), 3000);
                }
              },
              err => {
                this.updateImage = false;
                this.userShowCropper = false;
                this._toastr.error('An error has occurred.');
              }
            );
          }
        );
      },
      err => {
        this.updateImage = false;
        this.userShowCropper = false;
        this._toastr.error('An error has occurred.');
      }
    );
  }

  uploadProfileImage() {
    this.uploadImage('w3mk-', 'userImg');
  }

  uploadLogoImage() {
    this.uploadImage('w3mklogo-', 'logoImg');
  }

  resetImageLogo() {

    this.loadingImage = true;
    this._userService.resetUserLogo(this.user.id)
      .subscribe(
        data => {
          setTimeout(() => this.refreshData(), 3000);
        },
        error => {
          this.loadingImage = false;
          this._toastr.error('Image could not be deleted');
        }
      );
  }

  showImageLogo(template: TemplateRef<any>) {
    this.modalRef = this._modalService.show(template, {class: 'modal-w-auto '});
  }

  imageCropped(image: string) {
    this.croppedImage =  image;
  }

  setImageBase64(image: string) {
    this.imageBase64String = image;
    }

  loadImage(file) {
    const fileReader = new FileReader();
    fileReader.onload = (event: any) => {
      try {
        this.checkExifRotationAndSetImage(event.target.result);
      } catch (e) {
        this._toastr.error('Error on file upload process');
      }
    };
    fileReader.readAsDataURL(file);
  }

  fileChangeEvent(event: any, type): void {
    if (this._dataService.checkUploadedFileType(event.target.files[0].type)) {
         this.loadImage(event.target.files[0]);
      if (type === 'userImg') {
        this.userShowCropper = true;
      } else {
        this.logoShowCropper = true;
      }
    } else {
      this._toastr.error('Please upload allowed file types: .jpeg, .jpg or .png.');
    }
  }


  private checkExifRotationAndSetImage(imageBase64: string) {
    ImageUtils.checkExifRotation(imageBase64, (string) => this.setImageBase64(imageBase64));
  }

  cancelUpload() {
    this.userShowCropper = false;
    this.logoShowCropper = false;
    this.imageBase64String = '';
  }

  radioButtonClicked() {
    this.watermarkText = 'After save please allow us up to couple of minutes for all of your previous images to be watermarked';
  }

  closeModal() {
    this.modalRef.hide();
  }

}
