import {
    Component, ElementRef, EventEmitter, HostListener, Input, OnChanges, Output, SimpleChanges,
    ChangeDetectorRef, ChangeDetectionStrategy, ViewChild
} from '@angular/core';
import { DomSanitizer, SafeUrl, SafeStyle } from '@angular/platform-browser';
import { ImageUtils } from './../../helpers/image.utils';

interface MoveStart {
    active: boolean;
    type: string | null;
    position: string | null;
    x1: number;
    y1: number;
    x2: number;
    y2: number;
    clientX: number;
    clientY: number;
}

interface Dimensions {
    width: number;
    height: number;
}

export interface CropperPosition {
    x1: number;
    y1: number;
    x2: number;
    y2: number;
}

export interface ImageObjectEvent {
    base64?: string | null;
    file?: Blob | null;
    blackBars?: [{}] | any;
    cropObj: {};
    width: number;
    height: number;
    cropperPosition: CropperPosition;
    cropRatio: number;
    originalSizeWidth: any;
}

export interface BlackBar {
    startX?: number | null;
    startY?: number | null;
    width?: number | null;
    height?: number | null;
}

@Component({
    selector: 'edit-image',
    templateUrl: './edit-image.component.html',
    styleUrls: ['./edit-image.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditImageComponent implements OnChanges {
    private originalImage: any;
    private moveStart: MoveStart;
    private maxSize: Dimensions;
    private originalSize: Dimensions;

    safeImgDataUrl: SafeUrl | string;
    marginLeft: SafeStyle | string = '0px';
    imageVisible = false;
    cropperVisible = false;
    buttonSelected = false;
    loadFile: any;

    @Input()
    set imageFileChanged(file: File) {
        this.initCropper();
        if (file) {
            this.loadImageFile(file);
        }
    }

    @Input()
    set imageToEdit(file: any) {
        this.initCropper();
        if(file) {
            this.loadImageFile(file);
        }
    }

    @Input()
    set imageChangedEvent(event: any) {
        this.initCropper();
        if (event && event.target && event.target.files && event.target.files.length > 0) {
            this.loadImageFile(event.target.files[0]);
        }
    }

    @Input()
    set imageBase64(imageBase64: string) {
        this.initCropper();
        this.loadBase64Image(imageBase64);
    }

    @Input() format: 'png' | 'jpeg' | 'bmp' | 'webp' | 'ico' = 'png';
    @Input() outputType: 'base64' | 'file' | 'both' = 'both';
    @Input() maintainAspectRatio = true;
    @Input() aspectRatio = 1;
    @Input() resizeToWidth = 0;
    @Input() roundCropper = false;
    @Input() onlyScaleDown = false;
    @Input() imageQuality = 92;
    @Input() autoCrop = true;
    @Input() cropper: CropperPosition = {
        x1: -100,
        y1: -100,
        x2: 10000,
        y2: 10000
    };
    @Input() isUploadButtonDisabled;
    @Input() pageYOffset;

    @Output() imageCropped = new EventEmitter<ImageObjectEvent>(false);
    @Output() imageCroppedBase64 = new EventEmitter<string>();
    @Output() imageCroppedFile = new EventEmitter<Blob>();
    @Output() imageLoaded = new EventEmitter<void>();
    @Output() loadImageFailed = new EventEmitter<void>();
    @Output() imageOriginal = new EventEmitter<ImageObjectEvent>(false);
    @Output() disableUploadButton = new EventEmitter<boolean>();

    rect: BlackBar;
    drag;
    image = '';
    action;
    imageWidth;
    imageHeight;
    blackbars = [];
    cropObj = {
      left: String,
      top: String,
      width: String,
      height: String,
    };

    @ViewChild('canvas',{static: true}) canvas: ElementRef;
    public ctx: CanvasRenderingContext2D;
    @ViewChild('img',{static: true}) img: HTMLImageElement;

    cropCanvas;
    cropContext;
    cropRatio;
    cropLeft;
    cropTop;
    cropWidth;
    cropHeight;
    cropResizedWidth;
    cropResizedHeight;
    cropResizeRatio;

    originalImageCanvas;
    originalImageContext;

    cropButtonActive = false;
    blackbarButtonActive = false;
    undoButtonActive = false;
    saveButtonActive = false;


    constructor(private elementRef: ElementRef, private sanitizer: DomSanitizer, private cd: ChangeDetectorRef) {
        this.initCropper();
    }

    ngOnInit() {
        this.drag = false;
    }

    ngAfterViewInit(): void {
        this.ctx = (<HTMLCanvasElement>this.canvas.nativeElement).getContext('2d');
    }

    onRightMouseClick() {
        this.drag = false;
    }

    onMouseDown(event) {
        if(!this.cropperVisible) {
            this.rect = { startX: null, startY: null, width: null, height: null};
            this.rect.startX = this.getPageX(event) - this.canvas.nativeElement.getBoundingClientRect().left;
            this.rect.startY = this.getPageY(event) - this.pageYOffset - this.canvas.nativeElement.getBoundingClientRect().top;
            this.drag = true;
            this.setActive('blackbarButton');
        }
    }

    onMouseUp(event) {
        if (!this.cropperVisible && this.rect != null) {
            this.drag = false;
            this.ctx.clearRect(0, 0, this.canvas.nativeElement.width, this.canvas.nativeElement.height);
            this.blackbars.push(this.rect);
            this.blackbars.forEach(item => {
                this.drawRectangle(item)
            })
        }
    }
    onMouseMove(event) {
        if (!this.cropperVisible && this.drag) {
            this.rect.width = (this.getPageX(event) - this.canvas.nativeElement.getBoundingClientRect().left) - this.rect.startX;
            this.rect.height = (this.getPageY(event) - this.pageYOffset - this.canvas.nativeElement.getBoundingClientRect().top) - this.rect.startY;
            this.ctx.clearRect(0, 0, this.canvas.nativeElement.width, this.canvas.nativeElement.height);
            this.drawRectangleDashed();
        }
    }
    drawRectangle(rect) {
        if(!this.cropperVisible) {
            this.drag = false;
            this.ctx.rect(rect.startX, rect.startY, rect.width, rect.height);
            this.ctx.fill();
            this.disableUploadButton.emit(true);
        }
    }

    drawRectangleDashed() {
        if(!this.cropperVisible) {
            this.ctx.setLineDash([1]);
            this.ctx.strokeRect(this.rect.startX, this.rect.startY, this.rect.width, this.rect.height);
            this.ctx.fill();
        }
    }

    undo() {
        this.setActive('undoButton');
        this.blackbars = [];
        this.rect = null;
        this.ctx.clearRect(0, 0, this.canvas.nativeElement.width, this.canvas.nativeElement.height);
        this.ctx.beginPath();
        this.resetCropperPosition();

        if (this.originalImageContext) {
            this.originalImageContext.drawImage(this.originalImage, 0, 0, this.originalImage.width, this.originalImage.height);
            this.cropToOutputType(this.originalSize.width, this.originalSize.height, this.imageOriginal);
        }
        this.disableUploadButton.emit(false);
    }

    hideCropper(event) {
        this.cropperVisible = false;
        this.setActive('blackbarButton');
    }

    showCropper(event) {
        this.cropperVisible = true;
        this.setActive('cropButton')
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['cropper']) {
            setTimeout(() => {
                this.setMaxSize();
                this.checkCropperPosition(false);
                this.doAutoCrop();
                this.cd.markForCheck();
            });
        }
        if (changes['aspectRatio']) {
            this.resetCropperPosition();
        }
    }

    private initCropper() {
        this.imageVisible = false;
        this.cropperVisible = false;
        this.originalImage = null;
        this.safeImgDataUrl = 'data:image/png;base64,iVBORw0KGg'
            + 'oAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAAC0lEQVQYV2NgAAIAAAU'
            + 'AAarVyFEAAAAASUVORK5CYII=';
        this.moveStart = {
            active: false,
            type: null,
            position: null,
            x1: 0,
            y1: 0,
            x2: 0,
            y2: 0,
            clientX: 0,
            clientY: 0
        };
        this.maxSize = {
            width: 0,
            height: 0
        };
        this.originalSize = {
            width: 0,
            height: 0
        };
        this.cropper.x1 = -100;
        this.cropper.y1 = -100;
        this.cropper.x2 = 10000;
        this.cropper.y2 = 10000;
    }

    private loadImageFile(file: File) {
      this.loadFile = file;
        const fileReader = new FileReader();
        fileReader.onload = (event: any) => {
            const imageType = file.type;
            if (this.isValidImageType(imageType)) {
                try {
                  this.loadBase64Image(event.target.result);
                } catch (e) {
                    this.loadImageFailed.emit();
                }
            } else {
                this.loadImageFailed.emit();
            }
        };
        fileReader.readAsDataURL(file);
    }

    private isValidImageType(type: string) {
        return type === 'image/jpeg'
            || type === 'image/jpg'
            || type === 'image/png'
            || type === 'image/gif'
    }



    private loadBase64Image(imageBase64: string) {
        this.safeImgDataUrl = this.sanitizer.bypassSecurityTrustResourceUrl(imageBase64);
        this.originalImage = new Image();
        this.originalImage.onload = () => {
            this.originalSize.width = this.originalImage.width;
            this.originalSize.height = this.originalImage.height;
            this.cd.markForCheck();
        };
        this.originalImage.src = imageBase64;
    }

    imageLoadedInView(img): void {
        if (this.originalImage != null) {
            this.imageLoaded.emit();
            setTimeout(() => {
                this.setMaxSize();
                this.resetCropperPosition();
                this.cd.markForCheck();
            });
        }
        this.img = img;
        this.canvas.nativeElement.width = img.clientWidth;
        this.canvas.nativeElement.height = img.clientHeight;

    }

    @HostListener('window:resize', ['$event'])
    onResize(event: Event) {
        this.resizeCropperPosition();
        this.setMaxSize();
        this.canvas.nativeElement.width  = this.img.clientWidth;
        this.canvas.nativeElement.height = this.img.clientHeight;
    }

    private resizeCropperPosition() {
        const displayedImage = this.elementRef.nativeElement.querySelector('.source-image');
        if (this.maxSize.width !== displayedImage.offsetWidth || this.maxSize.height !== displayedImage.offsetHeight) {
            this.cropper.x1 = this.cropper.x1 * displayedImage.offsetWidth / this.maxSize.width;
            this.cropper.x2 = this.cropper.x2 * displayedImage.offsetWidth / this.maxSize.width;
            this.cropper.y1 = this.cropper.y1 * displayedImage.offsetHeight / this.maxSize.height;
            this.cropper.y2 = this.cropper.y2 * displayedImage.offsetHeight / this.maxSize.height;
        }
    }

    private resetCropperPosition() {
        const displayedImage = this.elementRef.nativeElement.querySelector('.source-image');
        if (displayedImage.offsetWidth / this.aspectRatio < displayedImage.offsetHeight) {
            this.cropper.x1 = 0;
            this.cropper.x2 = displayedImage.offsetWidth;
            const cropperHeight = displayedImage.offsetWidth / this.aspectRatio;
            this.cropper.y1 = (displayedImage.offsetHeight - cropperHeight) / 2;
            this.cropper.y2 = this.cropper.y1 + cropperHeight;
        } else {
            this.cropper.y1 = 0;
            this.cropper.y2 = displayedImage.offsetHeight;
            const cropperWidth = displayedImage.offsetHeight * this.aspectRatio;
            this.cropper.x1 = (displayedImage.offsetWidth - cropperWidth) / 2;
            this.cropper.x2 = this.cropper.x1 + cropperWidth;
        }
        this.doAutoCrop();
        this.imageVisible = true;
        this.cropperVisible = true;
    }

    startMove(event: any, moveType: string, position: string | null = null) {
        this.moveStart = Object.assign({
            active: true,
            type: moveType,
            position: position,
            clientX: this.getClientX(event),
            clientY: this.getClientY(event)
        }, this.cropper);
    }

    @HostListener('document:mousemove', ['$event'])
    @HostListener('document:touchmove', ['$event'])
    moveImg(event: any) {
        if (this.moveStart.active) {
            event.stopPropagation();
            event.preventDefault();
            this.setMaxSize();
            if (this.moveStart.type === 'move') {
                this.move(event);
                this.checkCropperPosition(true);
            } else if (this.moveStart.type === 'resize') {
                this.resize(event);
                this.checkCropperPosition(false);
            }
            this.cd.detectChanges();
        }
    }

    private setMaxSize() {
        const el = this.elementRef.nativeElement.querySelector('.source-image');
        this.maxSize.width = el.offsetWidth;
        this.maxSize.height = el.offsetHeight;
        this.marginLeft = this.sanitizer.bypassSecurityTrustStyle('calc(50% - ' + this.maxSize.width / 2 + 'px)');
    }

    private checkCropperPosition(maintainSize = false) {
        if (this.cropper.x1 < 0) {
            this.cropper.x2 -= maintainSize ? this.cropper.x1 : 0;
            this.cropper.x1 = 0;
        }
        if (this.cropper.y1 < 0) {
            this.cropper.y2 -= maintainSize ? this.cropper.y1 : 0;
            this.cropper.y1 = 0;
        }
        if (this.cropper.x2 > this.maxSize.width) {
            this.cropper.x1 -= maintainSize ? (this.cropper.x2 - this.maxSize.width) : 0;
            this.cropper.x2 = this.maxSize.width;
        }
        if (this.cropper.y2 > this.maxSize.height) {
            this.cropper.y1 -= maintainSize ? (this.cropper.y2 - this.maxSize.height) : 0;
            this.cropper.y2 = this.maxSize.height;
        }
    }

    @HostListener('document:mouseup', ['$event'])
    @HostListener('document:touchend', ['$event'])
    moveStop(event: any) {
        if (this.moveStart.active) {
            this.moveStart.active = false;
            this.doAutoCrop();
        }
    }

    private move(event: any) {
        const diffX = this.getClientX(event) - this.moveStart.clientX;
        const diffY = this.getClientY(event) - this.moveStart.clientY;

        this.cropper.x1 = this.moveStart.x1 + diffX;
        this.cropper.y1 = this.moveStart.y1 + diffY;
        this.cropper.x2 = this.moveStart.x2 + diffX;
        this.cropper.y2 = this.moveStart.y2 + diffY;
    }

    private resize(event: any) {
        const diffX = this.getClientX(event) - this.moveStart.clientX;
        const diffY = this.getClientY(event) - this.moveStart.clientY;
        switch (this.moveStart.position) {
            case 'left':
                this.cropper.x1 = Math.min(this.moveStart.x1 + diffX, this.cropper.x2 - 20);
                break;
            case 'topleft':
                this.cropper.x1 = Math.min(this.moveStart.x1 + diffX, this.cropper.x2 - 20);
                this.cropper.y1 = Math.min(this.moveStart.y1 + diffY, this.cropper.y2 - 20);
                break;
            case 'top':
                this.cropper.y1 = Math.min(this.moveStart.y1 + diffY, this.cropper.y2 - 20);
                break;
            case 'topright':
                this.cropper.x2 = Math.max(this.moveStart.x2 + diffX, this.cropper.x1 + 20);
                this.cropper.y1 = Math.min(this.moveStart.y1 + diffY, this.cropper.y2 - 20);
                break;
            case 'right':
                this.cropper.x2 = Math.max(this.moveStart.x2 + diffX, this.cropper.x1 + 20);
                break;
            case 'bottomright':
                this.cropper.x2 = Math.max(this.moveStart.x2 + diffX, this.cropper.x1 + 20);
                this.cropper.y2 = Math.max(this.moveStart.y2 + diffY, this.cropper.y1 + 20);
                break;
            case 'bottom':
                this.cropper.y2 = Math.max(this.moveStart.y2 + diffY, this.cropper.y1 + 20);
                break;
            case 'bottomleft':
                this.cropper.x1 = Math.min(this.moveStart.x1 + diffX, this.cropper.x2 - 20);
                this.cropper.y2 = Math.max(this.moveStart.y2 + diffY, this.cropper.y1 + 20);
                break;
        }

        if (this.maintainAspectRatio) {
            this.checkAspectRatio();
        }
    }

    private checkAspectRatio() {
        let overflowX = 0;
        let overflowY = 0;

        switch (this.moveStart.position) {
            case 'top':
                this.cropper.x2 = this.cropper.x1 + (this.cropper.y2 - this.cropper.y1) * this.aspectRatio;
                overflowX = Math.max(this.cropper.x2 - this.maxSize.width, 0);
                overflowY = Math.max(0 - this.cropper.y1, 0);
                if (overflowX > 0 || overflowY > 0) {
                    this.cropper.x2 -= (overflowY * this.aspectRatio) > overflowX ? (overflowY * this.aspectRatio) : overflowX;
                    this.cropper.y1 += (overflowY * this.aspectRatio) > overflowX ? overflowY : overflowX / this.aspectRatio;
                }
                break;
            case 'bottom':
                this.cropper.x2 = this.cropper.x1 + (this.cropper.y2 - this.cropper.y1) * this.aspectRatio;
                overflowX = Math.max(this.cropper.x2 - this.maxSize.width, 0);
                overflowY = Math.max(this.cropper.y2 - this.maxSize.height, 0);
                if (overflowX > 0 || overflowY > 0) {
                    this.cropper.x2 -= (overflowY * this.aspectRatio) > overflowX ? (overflowY * this.aspectRatio) : overflowX;
                    this.cropper.y2 -= (overflowY * this.aspectRatio) > overflowX ? overflowY : (overflowX / this.aspectRatio);
                }
                break;
            case 'topleft':
                this.cropper.y1 = this.cropper.y2 - (this.cropper.x2 - this.cropper.x1) / this.aspectRatio;
                overflowX = Math.max(0 - this.cropper.x1, 0);
                overflowY = Math.max(0 - this.cropper.y1, 0);
                if (overflowX > 0 || overflowY > 0) {
                    this.cropper.x1 += (overflowY * this.aspectRatio) > overflowX ? (overflowY * this.aspectRatio) : overflowX;
                    this.cropper.y1 += (overflowY * this.aspectRatio) > overflowX ? overflowY : overflowX / this.aspectRatio;
                }
                break;
            case 'topright':
                this.cropper.y1 = this.cropper.y2 - (this.cropper.x2 - this.cropper.x1) / this.aspectRatio;
                overflowX = Math.max(this.cropper.x2 - this.maxSize.width, 0);
                overflowY = Math.max(0 - this.cropper.y1, 0);
                if (overflowX > 0 || overflowY > 0) {
                    this.cropper.x2 -= (overflowY * this.aspectRatio) > overflowX ? (overflowY * this.aspectRatio) : overflowX;
                    this.cropper.y1 += (overflowY * this.aspectRatio) > overflowX ? overflowY : overflowX / this.aspectRatio;
                }
                break;
            case 'right':
            case 'bottomright':
                this.cropper.y2 = this.cropper.y1 + (this.cropper.x2 - this.cropper.x1) / this.aspectRatio;
                overflowX = Math.max(this.cropper.x2 - this.maxSize.width, 0);
                overflowY = Math.max(this.cropper.y2 - this.maxSize.height, 0);
                if (overflowX > 0 || overflowY > 0) {
                    this.cropper.x2 -= (overflowY * this.aspectRatio) > overflowX ? (overflowY * this.aspectRatio) : overflowX;
                    this.cropper.y2 -= (overflowY * this.aspectRatio) > overflowX ? overflowY : overflowX / this.aspectRatio;
                }
                break;
            case 'left':
            case 'bottomleft':
                this.cropper.y2 = this.cropper.y1 + (this.cropper.x2 - this.cropper.x1) / this.aspectRatio;
                overflowX = Math.max(0 - this.cropper.x1, 0);
                overflowY = Math.max(this.cropper.y2 - this.maxSize.height, 0);
                if (overflowX > 0 || overflowY > 0) {
                    this.cropper.x1 += (overflowY * this.aspectRatio) > overflowX ? (overflowY * this.aspectRatio) : overflowX;
                    this.cropper.y2 -= (overflowY * this.aspectRatio) > overflowX ? overflowY : overflowX / this.aspectRatio;
                }
                break;
        }
    }

    private doAutoCrop() {
        if (this.autoCrop) {
            this.crop();
        }
    }

    crop() {
        const displayedImage = this.elementRef.nativeElement.querySelector('.source-image');
        if (displayedImage && this.originalImage != null) {
          this.cropRatio = this.originalSize.width / displayedImage.offsetWidth;
          this.cropLeft = Math.round(this.cropper.x1 * this.cropRatio);
          this.cropTop = Math.round(this.cropper.y1 * this.cropRatio);
          this.cropWidth = Math.round((this.cropper.x2 - this.cropper.x1) * this.cropRatio);
          this.cropHeight = Math.round((this.cropper.y2 - this.cropper.y1) * this.cropRatio);
          this.cropResizeRatio = this.getResizeRatio(this.cropWidth);
          this.cropResizedWidth = Math.floor(this.cropWidth * this.cropResizeRatio);
          this.cropResizedHeight = Math.floor(this.cropHeight * this.cropResizeRatio);

          this.cropObj.left = this.cropLeft;
          this.cropObj.top = this.cropTop;
          this.cropObj.width = this.cropWidth;
          this.cropObj.height = this.cropHeight;


            this.cropCanvas = document.createElement('canvas') as HTMLCanvasElement;
            this.cropCanvas.width = this.cropResizedWidth;
            this.cropCanvas.height = this.cropResizedHeight;

            this.cropContext = this.cropCanvas.getContext('2d');
            if (this.cropContext) {
                this.cropContext.drawImage(this.originalImage, this.cropLeft, this.cropTop, this.cropWidth, this.cropHeight, 0, 0, this.cropWidth * this.cropResizeRatio, this.cropHeight * this.cropResizeRatio);
                this.cropToOutputType(this.cropResizedWidth, this.cropResizedHeight, this.imageOriginal);
            }
        }
    }

    saveChanges() {
        this.originalImageCanvas = document.createElement('canvas') as HTMLCanvasElement;
        this.originalImageCanvas.width = this.originalImage.width;
        this.originalImageCanvas.height = this.originalImage.height;
        this.originalImageContext = this.originalImageCanvas.getContext('2d');
        if (this.originalImageContext && this.cropContext) {
            this.originalImageContext.drawImage(this.originalImage, 0, 0, this.originalImage.width, this.originalImage.height);

            if (this.blackbars.length > 0) {
                this.setActive('saveButton');

                this.blackbars.forEach(rect => {
                    this.originalImageContext.rect(rect.startX *
                        this.cropRatio, rect.startY * this.cropRatio, rect.width * this.cropRatio, rect.height * this.cropRatio);
                    this.originalImageContext.fill();
                    this.cropContext.rect(rect.startX * this.cropRatio - this.cropLeft, rect.startY *
                        this.cropRatio - this.cropTop, rect.width * this.cropRatio, rect.height * this.cropRatio);
                    this.cropContext.fill();
                });
            }

            this.cropToOutputType(this.originalSize.width, this.originalSize.height, this.imageOriginal);
            this.disableUploadButton.emit(false);
        }
    }


    private cropToOutputType(resizedWidth: number, resizedHeight: number, eventEmitter: EventEmitter<any>) {
        const output: ImageObjectEvent = {
            width: resizedWidth,
            height: resizedHeight,
            cropperPosition: Object.assign({}, this.cropper),
            blackBars: this.blackbars,
            cropObj: this.cropObj,
            cropRatio: this.cropRatio,
            originalSizeWidth : this.originalSize.width
        };
        output.file =  this.loadFile;
        eventEmitter.emit(output);
    }

    private getResizeRatio(width: number): number {
        return this.resizeToWidth > 0 && (!this.onlyScaleDown || width > this.resizeToWidth)
            ? this.resizeToWidth / width
            : 1;
    }

    private getClientX(event: any) {
        return event.clientX != null ? event.clientX : event.touches[0].clientX;
    }

    private getClientY(event: any) {
        return event.clientY != null ? event.clientY : event.touches[0].clientY;
    }

    private getPageX(event: any) {
        return event.pageX != null ? event.pageX : event.touches[0].pageX;
    }

    private getPageY(event: any) {
        return event.pageY != null ? event.pageY : event.touches[0].pageY;
    }

    private setActive(button) {
        switch (button) {
            case 'cropButton':
                this.cropButtonActive = true;
                this.blackbarButtonActive = false;
                this.undoButtonActive = false;
                this.saveButtonActive = false;
                break;
            case 'blackbarButton':
                this.cropButtonActive = false;
                this.blackbarButtonActive = true;
                this.undoButtonActive = false;
                this.saveButtonActive = false;
                break;
            case 'undoButton':
                this.undoButtonActive = true;
                this.cropButtonActive = false;
                this.blackbarButtonActive = false;
                this.saveButtonActive = false;
                break;
            case 'saveButton':
                this.cropButtonActive = false;
                this.blackbarButtonActive = false;
                this.undoButtonActive = false;
                this.saveButtonActive = true;
                break;
        }


    }
}
