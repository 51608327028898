import { Component, OnInit } from '@angular/core';
import {NgForm} from '@angular/forms';
import {AuthService} from '../auth.service';
import {ActivatedRoute, Router} from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { LocalStorageService } from 'ngx-webstorage';

@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.scss']
})
export class SigninComponent implements OnInit {
  private token;
  private redirectTo = '/';
  constructor(private authService: AuthService, private activeRouter: ActivatedRoute,
              private router: Router, private toastr: ToastrService,
              private storage: LocalStorageService) {
    this.activeRouter.params.subscribe(params => {
      if (params['redirect'] && !params['param']) {
        this.redirectTo = '/' + params['redirect'];
      } else if (params['redirect'] && params['param']) {
        this.redirectTo = '/' + params['redirect'] + '/' + params['param'];
      }
    });
  }

  ngOnInit() {}

  onSignin(form: NgForm) {
    const email = form.value.email;
    const password = form.value.password;
    const user = {
        email: email,
        password: password
    };
    this.authService.signinUser(user).subscribe(
      data => {
        this.router.navigate([`/hm/${data['current_user'].id}`]);
        this.token = data['auth_token'];
        this.storage.store('token', this.token);
        this.storage.store('currentUser', JSON.stringify(data['current_user']));
        if (data['is_admin']) {
          this.storage.store('isAdmin', data['is_admin']);
        }
      },
      resp => {
        this.storage.clear();
        this.toastr.error(resp.error);
      }
    );
  }
}
