import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import { environment } from '../../environments/environment';

import { ToastrService } from 'ngx-toastr';
import {Router} from '@angular/router';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable()
export class UserService {
  constructor(private http: HttpClient, private toastr: ToastrService, private router: Router) {}

  getUserDetails(id) {
    return this.http.get(environment.apiBaseUrl + '/users/' + id);
  }
  editUser(user) {
    return this.http.put(environment.apiBaseUrl + '/users/' + user.id, user);
  }
  deleteProfileImage(id) {
    return this.http.get(environment.apiBaseUrl + '/users/delete_image?id=' + id);
  }

  resetUserLogo(id) {
    return this.http.get(environment.apiBaseUrl + '/users/reset_image_logo?id=' + id);
  }

  updateProfileImages(id, image_url, type) {
    return this.http.get(environment.apiBaseUrl + '/users/save_image?id=' + id + '&image_url=' + image_url + '&type=' + type);
  }
  updateProfileLogoImage(id, image_url) {
    return this.http.get(environment.apiBaseUrl + '/users/save_logo_image?id=' + id + '&image_url=' + image_url);
  }
  forgotPassword(email) {
    return this.http.post(environment.apiBaseUrl + '/users/forgot_password', { email });
  }
  checkToken(token) {
    return this.http.post(environment.apiBaseUrl + '/users/check_token', { token });
  }
  resetForgottenPassword(forgot_pass) {
    return this.http.post(environment.apiBaseUrl + '/users/reset_forgotten_password', { forgot_pass });
  }
  changePassword(change_password) {
    return this.http.post(environment.apiBaseUrl + '/users/change_password', { change_password });
  }
  changeCreditCard(id, card_tok) {
    return this.http.post(environment.apiBaseUrl + `/users/${id}/change_credit_card`, { card_tok });
  }
  getClinics() {
    return this.http.get(environment.apiBaseUrl + '/users/clinics');
  }
  getUsersNames() {
    return this.http.get(environment.apiBaseUrl + '/users/user_names');
  }
}
