import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {INewsModel, NewsModel} from './news/news-model';
import {Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';

const httpOptions = {
  headers: new HttpHeaders({'Content-Type': 'application/json'})
};

@Injectable()
export class AdminService {

  constructor(private http: HttpClient, private router: Router, private toastr: ToastrService) {
  }

  getNews(page) {
    return this.http.get<INewsModel>(environment.apiBaseUrl + '/news/show_all?page=' + page + '&per_page=20');
  }

  getLandingPageNews() {
    return this.http.get<INewsModel>(environment.apiBaseUrl + '/news');
  }

  deleteSingleNews(id: number) {
    return this.http.delete(environment.apiBaseUrl + '/news/' + id);
  }

  postNewNews(newNews: NewsModel) {
    this.http.post(environment.apiBaseUrl + '/news', newNews)
      .subscribe(
        data => {
          this.router.navigate(['/admin/news']);
        },
        error => {
          this.toastr.error('Unable to create news');
        }
      );
  }

  editNews(editNews: NewsModel) {
    this.http.put(environment.apiBaseUrl + '/news/' + editNews.id, editNews)
      .subscribe(
        data => {
          this.router.navigate(['/admin/news']);
        },
        error => {
          this.toastr.error('Unable to update news');
        }
      );
  }

  getUsers(isDisabled) {
    return this.http.get(environment.apiBaseUrl + '/users?is_disabled=' + isDisabled);
  }

  getUser(userId) {
    return this.http.get(environment.apiBaseUrl + '/users/' + userId);
  }

  updateUser(userId, user) {
    return this.http.put(environment.apiBaseUrl + '/users/' + userId, user);
  }

  getTreatments() {
    return this.http.get(environment.apiBaseUrl + '/treatments?all=true');
  }

  getTreatment(treatmentId) {
    return this.http.get(environment.apiBaseUrl + '/treatments/' + treatmentId);
  }

  updateTreatment(treatmentId, treatment) {
    return this.http.put(environment.apiBaseUrl + '/treatments/' + treatmentId, treatment);
  }

  getBrands(manufacturerId) {
    return this.http.get(environment.apiBaseUrl + '/brands/by_manufacturer?manufacturer_id=' + manufacturerId);
  }

  getBrand(brandId) {
    return this.http.get(environment.apiBaseUrl + '/brands/' + brandId);
  }

  createBrand(brand) {
    return this.http.post(environment.apiBaseUrl + '/brands', brand);
  }

  getManufacturers() {
    return this.http.get(environment.apiBaseUrl + '/manufacturers');
  }

  updateBrand(brand) {
    return this.http.put(environment.apiBaseUrl + '/brands/' + brand.id, brand);
  }

  updateManufacturer(manufacturer) {
    return this.http.put(environment.apiBaseUrl + '/manufacturers/' + manufacturer.id, manufacturer);
  }

  getManufacturer(id) {
    return this.http.get(environment.apiBaseUrl + '/manufacturers/' + id);
  }

  createManufacturer(manufacturer) {
    return this.http.post(environment.apiBaseUrl + '/manufacturers', manufacturer);
  }

  updateGender(gender) {
    return this.http.put(environment.apiBaseUrl + '/genders/' + gender.id, gender);
  }

  createGender(gender) {
    return this.http.post(environment.apiBaseUrl + '/genders', gender);
  }

  getGender(id) {
    return this.http.get(environment.apiBaseUrl + '/genders/' + id);
  }

  getGenders() {
    return this.http.get(environment.apiBaseUrl + '/genders?all=true');
  }

  updateSkinType(skinType) {
    return this.http.put(environment.apiBaseUrl + '/skin_types/' + skinType.id, skinType);
  }

  createSkinType(skinType) {
    return this.http.post(environment.apiBaseUrl + '/skin_types', skinType);
  }

  getSkinType(id) {
    return this.http.get(environment.apiBaseUrl + '/skin_types/' + id);
  }

  getSkinTypes() {
    return this.http.get(environment.apiBaseUrl + '/skin_types?all=true');
  }

  createTreatment(treatment) {
    return this.http.post(environment.apiBaseUrl + '/treatments', treatment);
  }

  getEthnicities() {
    return this.http.get(environment.apiBaseUrl + '/ethnicities?all=true');
  }

  getEthnicity(ethnicityId) {
    return this.http.get(environment.apiBaseUrl + '/ethnicities/' + ethnicityId);
  }

  createEthnicity(ethnicity) {
    return this.http.post(environment.apiBaseUrl + '/ethnicities', ethnicity);
  }

  updateEthnicity(ethnicity) {
    return this.http.put(environment.apiBaseUrl + '/ethnicities/' + ethnicity.id, ethnicity);
  }

  addBanner(banner) {
    return this.http.post(environment.apiBaseUrl + '/banners', banner);
  }

  getBanners() {
    return this.http.get(environment.apiBaseUrl + '/banners');
  }

  deleteBanner(bannerId) {
    return this.http.delete(environment.apiBaseUrl + '/banners/' + bannerId);
  }

  getOverallKpisFromDate(from, to) {
    return this.http.get(environment.apiBaseUrl + '/overall_kpis?from=' + from + '&to=' + to);
  }

  getUserOverallKips(userId) {
    return this.http.get(environment.apiBaseUrl + '/users/user_overall_kpis/' + userId);
  }

  exportOverallKpisToCsv(from, to) {
    return this.http.get(environment.apiBaseUrl + '/overall_kpis/export_csv.csv?from=' + from + '&to=' + to, {responseType: 'text'});
  }

  exportUserKpisToCsv(userId) {
    return this.http.get(environment.apiBaseUrl + '/users/export_csv.csv/' + userId, {responseType: 'text'});
  }

  exportAllUsersToCsv() {
    return this.http.get(environment.apiBaseUrl + '/users/export_all_users.csv', {responseType: 'text'});
  }

  createUser(user) {
    return this.http.post(environment.apiBaseUrl + '/users/create_by_admin', user);
  }
}
