<div class="container tags {{ tagsContainer }}">
  <div *ngFor="let brand of brandFilters" class="tag">
    {{ brand.name }}
    <a class="tag-delete" (click)="removeTag('brand', brand)">x</a>
  </div>
  <div *ngFor="let treatment of treatmentFilters" class="tag">
    {{ treatment.name }}
    <a class="tag-delete" (click)="removeTag('treatment', treatment)">x</a>
  </div>
  <div *ngIf="skinType" class="tag">
    skin: {{ skinType.name }}
    <a class="tag-delete" (click)="filterBy('skin type', null); expendFilter('skin type')">x</a>
  </div>
  <div *ngIf="gender" class="tag">
    {{ gender.name }}
    <a class="tag-delete" (click)="filterBy('gender', null); expendFilter('gender')">x</a>
  </div>
  <div *ngIf="age" class="tag">
    age: {{ age }}
    <a class="tag-delete" (click)="filterBy('age', null); expendFilter('age')">x</a>
  </div>
  <div *ngIf="
      brandFilters.length ||
      treatmentFilters.length ||
      skinType ||
      gender ||
      age
    " class="tag clear">
    Remove all
    <a class="tag-delete" (click)="clearFilters()">x</a>
  </div>
</div>

<div class="container">
  <div class="three columns {{ filterBox }}">
    <div class="filters">
      <div class="filter" (click)="expendFilter('treatment')">
        <span class="filter-name">TREATMENT</span>
        <i class="fa" [ngClass]="isTreatmentExpended ? 'fa-minus' : 'fa-plus'"></i>
      </div>
      <div *ngIf="isTreatmentExpended" class="filter-treatment">
        <app-select-treatments [selectedTreatments]="treatmentFilters" [userId]="userId" [loadFilterOnFirstClick]="loadFilterOnFirstClick"
          (countChanged)="countChangedHandler($event)">
        </app-select-treatments>
      </div>

      <div class="filter" (click)="expendFilter('skin type')">
        <span class="filter-name">SKIN TYPE</span>
        <i class="fa" [ngClass]="isSkinTypeExpended ? 'fa-minus' : 'fa-plus'"></i>
      </div>
      <div *ngIf="isSkinTypeExpended" class="filter-skin-type">
        <div *ngFor="let skin of skinTypes" class="skin-type-flex" (click)="filterBy('skin type', skin)">
          <div class="item-color skin-filter" [style.background-color]="skin.color"></div>
          <div class="skin-text">
            <p class="skin-name">{{ skin.name }}</p>
            <p>{{ skin.description }}</p>
          </div>
        </div>
      </div>

      <div class="filter" (click)="expendFilter('gender')">
        <span class="filter-name">GENDER</span>
        <i class="fa" [ngClass]="isGenderExpended ? 'fa-minus' : 'fa-plus'"></i>
      </div>
      <div *ngIf="isGenderExpended" class="filter-gender">
        <select class="gender-filter" id="gender" (change)="filterBy('gender', $event.target.value)">
          <option value="" disabled selected>Select gender</option>
          <option *ngFor="let gender of genders" [value]="gender.id">{{
            gender.name
          }}</option>
        </select>
      </div>

      <div class="filter" (click)="expendFilter('age')">
        <span class="filter-name">AGE</span>
        <i class="fa" [ngClass]="isAgeExpended ? 'fa-minus' : 'fa-plus'"></i>
      </div>
      <div *ngIf="isAgeExpended" class="filter-age">
        <select class="gender-filter" id="age" (change)="filterBy('age', $event.target.value)">
          <option value="" disabled selected>Select age</option>
          <option *ngFor="let age of ages" [value]="age">{{ age }}</option>
        </select>
      </div>

      <div class="filter" (click)="expendFilter('brand')">
        <span class="filter-name">BRAND</span>
        <i class="fa" [ngClass]="isBrandExpended ? 'fa-minus' : 'fa-plus'"></i>
      </div>
      <div *ngIf="isBrandExpended" class="filter-treatment">
        <app-select-brands class="select-brands" [userId]="userId" [selectedBrands]="brandFilters" [loadFilterOnFirstClick]="loadFilterOnFirstClick"
          (countChanged)="countChangedHandler($event)"></app-select-brands>
      </div>

      <div class="filter" (click)="expendFilter('case')" *ngIf="!allCases">
        <span class="filter-name">CASE #</span>
        <i class="fa" [ngClass]="isCaseExpended ? 'fa-minus' : 'fa-plus'"></i>
      </div>
      <div *ngIf="isCaseExpended" class="filtter-case">
        <input type="number" placeholder="Type..." (keyup)="onCaseChanged($event)" (change)="onCaseEnter($event)"
          (keydown.enter)="onCaseEnter($event)" [value]="caseId" min="0" />
        <div *ngIf="caseId" class="tag">
          <button class="tag-delete all-cases" (click)="caseId = ''; showAllcases()">Back to all cases</button>
        </div>
      </div>
    </div>
  </div>
  <div class="nine columns {{ caseBox }}">
    <div class="">
      <div class="search-results" infiniteScroll [infiniteScrollDistance]="0.1" [infiniteScrollThrottle]="3"
        (scrolled)="onScroll()">
        <div class="feed">
          <div *ngIf="cases.length == 0">
            <h4>No results matching your criteria</h4>
          </div>
          <div class="feed-item" *ngFor="let caseItem of cases; let caseIndex = index">
            <div class="item-header">
              <img src="{{
                  caseItem.user && caseItem.user.image_url
                    ? caseItem.user.image_url
                    : '../../../assets/images/profilePlaceholder.png'
                }}" />
              <div class="header-text">
                <p class="treatment-name">
                  {{ showTreatmentInfo(caseItem) }}
                </p>
                <p class="author">
                  by
                  <span class="author-name">{{ caseItem.user.prefix }} {{ caseItem.user.first_name }}
                    {{ caseItem.user.last_name }}</span>
                </p>
              </div>
              <p *ngIf="allCases && caseItem.is_deleted" class="deleted-info">
                Deleted
              </p>
            </div>

            <div *ngFor="let angle of caseItem.angles; let angleIndex = index">
              <div class="item-body" (click)="openModalWithComponent(caseItem)"
                *ngIf="angleIndex === caseItem.angleVisible"
                [ngClass]="{'fade-in': angleIndex === caseItem.angleVisible}">
                <div class="item-image">
                  <img class="before-after before-image" [src]="
                                    (angle.length > 0 &&
                                      angle[0].thumbnail_url) ||
                                    '../../../assets/images/profilePlaceholder.png'
                                  " />
                  <div class="transition">
                    <i class="fa fa-arrow-right"></i>
                  </div>
                  <img class="before-after ater-image" [src]="
                                    (angle.length > 1 &&
                                      angle[angle.length - 1]
                                        .thumbnail_url) ||
                                    '../../../assets/images/profilePlaceholder.png'
                                  " />
                </div>
              </div>
            </div>
            <div class="item-footer">
              <div class="left-footer">
                <div class="item-color" [style.background-color]="
                    (caseItem.skin_type && caseItem.skin_type.color) || 'white'
                  "></div>
                <div class="treatment-name">
                  <p class="item-text">
                    {{ showBrandInfo(caseItem) }}
                  </p>
                  <p class="item-sub-text">
                    {{ showTreatmentCount(caseItem) }}
                  </p>
                </div>
              </div>
              <div class="right-footer">
                <div class="item-age">
                  <p class="item-text-sex-age-bold">AGE</p>
                  <p *ngIf="caseItem.gender" [ngClass]="
                      caseItem.brands.length > 1 &&
                      caseItem.gender.name === 'Non-Binary'
                        ? 'item-text-sex-age-large-age'
                        : 'item-text-sex-age'
                    ">
                    {{ caseItem.patient_age || "N/A" }}
                  </p>
                </div>
                <div class="item-sex">
                  <p class="item-text-sex-age-bold">SEX</p>
                  <p *ngIf="caseItem.gender" [ngClass]="
                      caseItem.brands.length > 1 &&
                      caseItem.gender.name === 'Non-Binary'
                        ? 'item-text-sex-age-large-gender'
                        : 'item-text-sex-age'
                    ">
                    {{ (caseItem.gender && caseItem.gender.name) || "N/A" }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
