export class CaseModel {
    id: number;
    gender_id: number;
    skin_type_id: number;
    ethnicity_id: number;
    patient_age: string;
    treatment_ids: Array<number> = [];
    brand_ids: Array<number> = [];
    description: string;
    settings_notes: string;
    is_public: boolean;
    is_draft: boolean;
    is_deleted: boolean;
    case_images_attributes: Array<CaseImageModel> = [];
}

export class CaseImageModel {
    treatment_name: string;
    original_image_url: string;
    cropped_image_url: string;
    cropped_watermark_image_url: string;
    thumbnail_url: string;
}
