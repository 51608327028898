import { Component, OnInit } from '@angular/core';
import { AdminService } from '../../admin.service';
import { ActivationEnd, Router} from '@angular/router';
import { Location } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { BsModalService } from 'ngx-bootstrap/modal/bs-modal.service';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import {Constants} from '../../../constants';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-add-user',
  templateUrl: './add-user.component.html',
  styleUrls: ['../../admin.component.scss']
})
export class AddUserComponent implements OnInit {
  public user;
  public prefixes;
  form: FormGroup;
  public firstName: FormControl;
  public lastName: FormControl;
  public email: FormControl;
  public password: FormControl;
  public prefix: FormControl;

  constructor(private adminService: AdminService, private router: Router, private location: Location,
              private toastr: ToastrService, private _modalService: BsModalService, private constants: Constants) {
    this.prefixes = this.constants.prefixes;
  }

  ngOnInit() {
    this.createFormControls();
    this.createForm();
  }

  createForm() {
    this.form = new FormGroup({
      firstName: this.firstName,
      lastName: this.lastName,
      email: this.email,
      password: this.password,
      prefix: this.prefix
    });
  }

  createFormControls() {
    this.firstName = new FormControl('', Validators.required);
    this.lastName = new FormControl('', Validators.required);
    this.email = new FormControl('', [Validators.required]);
    this.password = new FormControl('', [
      Validators.required,
      Validators.minLength(6),
      Validators.pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d_$@!^%?*().|\\{}\[\]/#&+-=]{6,}$/)
    ]);
    this.prefix = new FormControl();
  }

  saveUser() {
    const user = {
      user: {
        email: this.form.value.email,
        password: this.form.value.password,
        first_name: this.form.value.firstName,
        last_name: this.form.value.lastName,
        prefix: this.form.value.prefix
      },
    };
    if (this.form.valid) {
      this.adminService.createUser(user).subscribe(
        data => {
          this.toastr.success('User successfully created');
          this.router.navigate(['/admin/users']);
        }, err => {
          this.toastr.error('Could not create user ', err.error.error);
        }
      );
    } else {
      this.toastr.error('Fill in all the required fields');
    }

  }


  goBack() {
    this.location.back();
  }
}
