<div class="container admin">
  <button class="button save-button" [routerLink]="['/admin/gender']">Add gender</button>
  <table class="table">
    <thead class="thead-light">
    <tr>
      <th>Name</th>
      <th>Is deleted</th>
      <th></th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let gender of genders" style="height: 30px">
      <td>
        {{gender.name}}
      </td>
      <td>
        {{gender.is_deleted ? 'YES' : 'NO'}}
      </td>
      <td>
        <i class="fa fa-edit" aria-hidden="true" [routerLink]="['/admin/gender/' + gender.id + '/edit']"></i>
        <i class="fa fa-trash" aria-hidden="true" (click)="deleteGender(gender.id)"></i>
      </td>
    </tr>
    </tbody>
  </table>
</div>
