<app-navbar></app-navbar>

<div class="container header">
  <div class="left-user"><a href="#" routerLink="/hm/{{currentUser.id}}" class="button cancel">Cancel</a></div>
  <h2 class="center">Edit Profile</h2>
  <div class="right-user"></div>
</div>

<div class="cropper-area" *ngIf="userShowCropper">
  <image-cropper class="cropper-area2"
                 [imageBase64]="imageBase64String"
                 [maintainAspectRatio]="true"
                 [aspectRatio]="1"
                 format="jpg"
                 (imageCropped)="imageCropped($event)"
  ></image-cropper>
  <div class="crop-buttons">
    <button (click)="uploadProfileImage()" class="upload-img-button">Upload</button>
    <button (click)="cancelUpload()" class="cancel-img-button">Cancel</button>
  </div>

</div>


<div class="cropper-area" *ngIf="logoShowCropper">
  <image-cropper class="cropper-area2"
                 [imageBase64]="imageBase64String"
                 [maintainAspectRatio]="false"
                 [aspectRatio]="2"
                 format="jpg"
                 (imageCropped)="imageCropped($event)"
  ></image-cropper>
  <div class="crop-buttons">
    <button (click)="uploadLogoImage()" class="upload-img-button">Upload</button>
    <button (click)="cancelUpload()" class="cancel-img-button">Cancel</button>
  </div>

</div>

<div class="container">
  <div class="seven columns">
    <div class="loader" [hidden]="!loadingDetails">
      <span>Updating profile...</span>
      <div class="animation"></div>
    </div>
    <div class="loader" [hidden]="!loadingImage">
      <span>Deleting image...</span>
      <div class="animation"></div>
    </div>
    <div class="loader" [hidden]="!updateImage">
      <span>Uploading image...</span>
      <div class="animation"></div>
    </div>
    <div class="row wrapper">
      <form novalidate (ngSubmit)="validateForm(form)" [formGroup]="form">
        <div class="profile-form">
          <p class="form-titles">Personal info</p>
          <div class="row">
            <div class="twelve columns">
              <div class="three columns">
                <img class="profile-img" src="{{user.image_url ? user.image_url : '../../../assets/images/profilePlaceholder.png'}}"/>
              </div>
              <div class="nine columns btn-margin">
                <div class="upload-button-user btn btn-primary">
                  <span>Upload Photo</span>
                  <input type="file" accept=".jpg,.jpeg,.png" class="upload" (change)="fileChangeEvent($event,'userImg')" (keydown.enter)="$event.preventDefault()" (keydown.space)="$event.preventDefault()"/>
                </div>
                <button type="button" class="button-colleague delete-image" [disabled]="!isDeleteButtonEnabled()" (click)="openModal(confirmPopup)">Delete Photo</button>
              </div>
              <div>
                <button class="twelve columns submit-button" type="submit">Save All Changes</button>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="user.custom_logo"  class="form-separator"></div>

        <div *ngIf="user.custom_logo" class="profile-form">
          <p class="form-titles">Upload your custom logo to place on your photos</p>
          <div class="row">
            <div class="twelve columns"></div>

            <div *ngIf="user.custom_logo">
              <div class="upload-button-logo btn btn-primary">
                <span>Upload Logo</span>
                <input type="file" accept=".jpg,.jpeg,.png" class="upload" (change)="fileChangeEvent($event,'logoImg')" (keydown.enter)="$event.preventDefault()" (keydown.space)="$event.preventDefault()"/>
              </div>

            </div>
            <div class="image-user-logo" *ngIf="user.custom_logo && userLogoSrc"><a [routerLink]="" (click)="showImageLogo(userLogo)" >{{logoImageName}}</a></div>
            <i *ngIf="user.custom_logo && userLogoSrc" class="fa fa-2x fa-remove delete-image-logo" (click)="resetImageLogo()"></i>
          </div>

          <div>
            <p class="provider-profile-logo-message"> We suggest using a logo image that is transparent and high resolution. The logo uploaded will be placed in the lower right corner of all new case photos.</p>
          </div>
        </div>

        <div class="form-separator"></div>
        <div class="profile-form">
          <div class="row">
            <div class="twelve columns">
              <label>Personalize the URL for your profile:</label>
              https://www.humazemd.com/hm/{{user.provider_profile_url}}<input type="text" placeholder="Your_Personalized_URL" formControlName="providerProfileUrl"
                     [(ngModel)]="user.provider_profile_url" value="{{user.provider_profile_url}}" minlength="5" maxlength="30">
              <div class="form-control-feedback">
                <p [ngClass]="form.hasError('pattern', ['providerProfileUrl']) && formSubmitted ? 'error' : 'provider-profile-url-message'">* Your custom URL must contain 5-30 letters or numbers. Please do not use spaces, symbols, or special characters</p>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="two columns">
              <label for="prefix">Prefix</label>
              <select class="u-full-width custom-placeholder" name="prefix" id="prefix" formControlName="prefix" [(ngModel)]="user.prefix" value="{{user.prefix}}">
                <option value="" selected> </option>
                <option *ngFor="let prefix of prefixes" [value]="prefix">{{prefix}}</option>
              </select>
            </div>
            <div class="five columns">
              <div class="input-field">
                <label>First name</label>
                <input type="text" placeholder="First name" formControlName="firstName" [(ngModel)]="user.first_name" value="{{user.first_name}}" maxlength="100">
                <div class="form-control-feedback">
                  <p *ngIf="form.hasError('required', ['firstName']) && formSubmitted" class="error">First name is required</p>
                  <p *ngIf="form.hasError('pattern', ['firstName']) && formSubmitted" class="error">Invalid first name</p>
                </div>
              </div>
            </div>
            <div class="five columns">
              <div class="input-field">
                <label>Last name</label>
                <input type="text" placeholder="Last name" formControlName="lastName" [(ngModel)]="user.last_name" value="{{user.last_name}}" maxlength="100">
                <div class="form-control-feedback">
                  <p *ngIf="form.hasError('required', ['lastName']) && formSubmitted" class="error">Last name is required</p>
                  <p *ngIf="form.hasError('pattern', ['lastName']) && formSubmitted" class="error">Invalid last name</p>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="six columns">
              <div class="input-field">
                <label>Email address</label>
                <input type="email" placeholder="Email" formControlName="email" [(ngModel)]="user.email" value="{{user.email}}" maxlength="100">
                <div class="form-control-feedback">
                  <p *ngIf="form.hasError('required', ['email'])" class="error">Email is required</p>
                  <p *ngIf="form.hasError('pattern', ['email'])" class="error">Email format is not valid</p>
                </div>
              </div>
            </div>
            <div class="six columns">
              <div class="input-field">
                <label>Password</label>
                <div class="change-password"><a href="#" routerLink="/change_password">Change password</a></div>
              </div>
            </div>
          </div>
          <div class="row" *ngIf="user.stripe_customer_id">
            <div class="six columns"></div>
            <div class="six columns">
              <div class="input-field">
                <label>Payment</label>
                <div class="change-password"><a href="#" routerLink="/change">Change credit card</a></div>
                <div class="change-password"><a href="mailto:support@humazemd.com?Subject=Modify My HumazeMD Subscription.&body={{cancelMailBody}}">Modify subscription</a></div>
              </div>
            </div>
          </div>
        </div>
        <div class="form-separator"></div>
          <div class="profile-form">
            <p class="form-titles">Company info</p>
            <div class="row">
              <div class="six columns">
                <div class="input-field">
                  <label>Company name</label>
                  <input type="text" placeholder="Company name" [ngModelOptions]="{standalone: true}" [required]="user.watermark_selection === 'company'" #isActive="ngModel" [(ngModel)]="user.company_name" value="{{user.company_name}}" maxlength="200">
                  <div class="form-control-feedback">
                    <p *ngIf="user.watermark_selection === 'company' && isActive.invalid"  class="error">Company name is required</p>
                    </div>
                </div>
              </div>
              <div class="six columns">
                <div class="input-field">
                  <label>Phone number</label>
                  <input type="tel" formControlName="phoneNumber" placeholder="XXX-XXX-XXXX" [(ngModel)]="user.company_phone" value="{{user.company_phone}}" maxlength="50">
                </div>
                <div class="form-control-feedback">
                  <p *ngIf="form.hasError('pattern', ['phoneNumber'])" class="error">Phone number format is not valid</p>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="six columns">
                <div class="input-field">
                  <label>Address</label>
                  <input type="text" placeholder="Address" [ngModelOptions]="{standalone: true}" [(ngModel)]="user.company_address" value="{{user.company_address}}" maxlength="200">
                </div>
              </div>
              <div class="six columns">
                <div class="input-field">
                  <label>City</label><br>
                  <input type="text" placeholder="City" [ngModelOptions]="{standalone: true}" [(ngModel)]="user.company_city" value="{{user.company_city}}" maxlength="200">
                </div>
              </div>
            </div>
            <div class="row">
              <div class="six columns">
                <div class="input-field">
                  <label>State</label><br>
                  <input type="text" placeholder="State" [ngModelOptions]="{standalone: true}" [(ngModel)]="user.state" value="{{user.state}}" maxlength="200">
                </div>
              </div>
              <div class="six columns">
                <div class="input-field">
                  <label>Postal Code</label><br>
                  <input type="text" placeholder="Postal Code" [ngModelOptions]="{standalone: true}" [(ngModel)]="user.zip" value="{{user.zip}}" maxlength="10">
                </div>
              </div>
            </div>
            <div class="row">
              <div class="twelve columns">
                <div class="input-field">
                  <label>Country</label><br>
                  <input type="text" placeholder="Country" [ngModelOptions]="{standalone: true}" [(ngModel)]="user.country" value="{{user.country}}" maxlength="200">
                </div>
              </div>
            </div>
            <div class="row">
              <button class="twelve columns submit-button" type="submit">Save All Changes</button>
            </div>
          </div>
      </form>
    </div>
  </div>

  <div class="five columns">
    <div class="right-wrapper">
      <div class="profile-form">
        <p class="form-titles">Social</p>
        <div class="row">
          <label>Website</label>
          <input type="text" placeholder="www.humazemd.com" [(ngModel)]="user.web" value="{{user.web}}" maxlength="500">
        </div>
        <div class="row">
          <label>Facebook</label>
          <input type="text" placeholder="Facebook page" [(ngModel)]="user.facebook" value="{{user.facebook}}" maxlength="500">
        </div>
        <div class="row">
          <label>LinkedIn</label>
          <input type="text" placeholder="LinkedIn page" [(ngModel)]="user.linkedin" value="{{user.linkedin}}" maxlength="500">
        </div>
        <div class="row">
          <label>Pinterest</label>
          <input type="text" placeholder="Pinterest page" [(ngModel)]="user.pinterest" value="{{user.pinterest}}" maxlength="500">
        </div>
        <div class="row">
          <label>Instagram</label>
          <input type="text" placeholder="Instagram page" [(ngModel)]="user.instagram" value="{{user.instagram}}" maxlength="500">
        </div>
        <div class="row">
          <label>Twitter</label>
          <input type="text" placeholder="Twitter page" [(ngModel)]="user.twitter" value="{{user.twitter}}" maxlength="500">
        </div>
      </div>
    </div>

    <div class="refer-colleague">
      <div class="row">
        <label>Refer a colleague!</label>
      </div>
      <div class="row colleague-row">
          <input type="text" placeholder="someone@example.com" [(ngModel)]="colleagueMail" maxlength="100">
          <a class="button refer-colleague-button" href="mailto:{{colleagueMail}}?cc=support@humazemd.com&Subject=Check out HumazeMD!&body={{colleagueMailBody}}">Send Mail</a>
      </div>
    </div>
  </div>
</div>

<ng-template #confirmPopup>
  <div class="modal-body text-center confirm-modal">
    <p>Are you sure you want to delete image?</p>
    <a class="button" (click)="confirm(user.id)" >Yes</a>
    <a class="button decline-button" (click)="decline()" >No</a>
  </div>
</ng-template>

<ng-template #userLogo>
 <div class="user-logo-modal">
    <img  *ngIf="user.custom_logo" class="logo-img" [src] ="userLogoSrc"/>
   <i class="fa fa-1x fa-remove" (click)="closeModal()"></i>
 </div>
</ng-template>

<app-footer></app-footer>
