<div class="container about">
  <div class="four columns">
    <div class="title">COMPANY INFO</div>
    <div class="subtitle">{{user.company_name}}</div>
  </div>
  <div class="two columns">
    <div class="title">CONTACT INFO</div>
    <div class="paragraph">
      <div class="details">
        <a href="tel:+{{user.company_phone}}">{{user.company_phone}}</a>
      </div>
    </div>
    <div class="paragraph">
      <div class="details">{{user.company_address}}</div>
      <div class="details">{{user.company_city}}, {{user.state}}</div>
      <div class="details">{{user.zip}}</div>
      <div class="details">{{user.country}}</div>

    </div>
  </div>
</div>
