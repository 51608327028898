<app-navbar></app-navbar>

<div class="container">
  <div class="row document-container">
    <h2>Terms of Use</h2>

    <div class="row">
      <div class="twelve columns" style="text-align: justify">
        <p>
          Welcome to the website of HumazeMD, LLC(the "Site"). By accessing this Site, you agree to be bound by the terms and conditions below (the "Terms"). If you do not agree to all of the Terms, please do not use the Site. HumazeMD, LLC(the “Company”) may from time to time modify or revise the Terms by updating this Web page. Your use of our Site following any such change constitutes your agreement to follow and be bound by the Terms as changed.
          If any change is unacceptable to you, your only recourse is to terminate your use of the Site.
        </p>
        <p class="document-titles">
          <b>Copyright and Trademark Notice</b>
        </p>
        <p style="margin-bottom: 1.5rem">
          This Site and its contents, including, but not limited to, reports, text, photographs, graphics, illustrations,
          video, sound, and other material (all such content collectively referred to as "Content") are protected under United States
          and international copyright laws and are the property of the Company or its third-party licensors. All rights reserved.
          All logos, splash screens, page headers, custom graphics, and button icons displayed on this Site are service marks,
          trademarks, and/or trade dress (collectively, "Marks") of the Company or its third-party licensors. Except as specifically
          permitted herein, copying, distributing, transmitting, displaying, modifying, selling, or participating in the sale of,
          or otherwise exploiting or using any Content or any Marks in any form or by any means without the express written
          permission of the Company is prohibited and may violate the copyright or trademark laws of the United States and/or
          other countries.
        </p>
        <p>
          The Site and its Contents are intended solely for personal use by the visitors and registered users of the Site.
          You may download or copy the Contents displayed on the Site for the sole purpose of using the Site as a personal resource,
          provided that you maintain any notices contained in the Content, such as all copyright notices, trademark legends, or other
          proprietary rights notices.
          No right, title, or interest in any downloaded materials is transferred to you as a result of any such downloading or copying.
        </p>
        <p class="document-titles">
          <b>Your Comments</b>
        </p>
        <p>
          We appreciate your comments, remarks, feedback, suggestions, ideas, and other submissions you disclose or
          transmit to us (collectively, "Comments"). You grant the Company and its affiliates a perpetual, irrevocable,
          worldwide, royalty-free right and license to use, reproduce, modify, adapt, publish, translate, create derivative works of,
          distribute, publicly perform, and publicly display (in whole or in part)
          your Comments, your name, and any related copyrights, moral rights, or other intellectual property rights.
        </p>

        <p class="document-titles">
          <b>Privacy</b>
        </p>
        <p>It is our policy to respect the privacy of individuals who visit the Site or provide Comments to us. Our Privacy Policy (the "Privacy Policy")
          is incorporated herein by reference. By accepting these Terms, you expressly consent to the use and disclosure of your
          personally identifiable and other information as described in the Privacy Policy.
        </p>

        <p class="document-titles">
          <b>Linked Sites</b>
        </p>
        <p>This Site may contain links to other websites ("Linked Sites"). The Company does not operate or control any information,
          products, or services on the Linked Sites and does not endorse or approve any products or information offered at Linked Sites.
          You acknowledge and agree that your access or use of any Linked Site is at your own risk.
        </p>

        <p class="document-titles">
          <b>Disclaimer</b>
        </p>
        <p>This Site, its contents, and all information, products, and services contained in or offered through this Site are
          provided on an "as is" and "as available" basis without representations or warranties of any kind. The
          Company expressly disclaims all such representations and warranties, either express or implied, including, without
          limitation, warranties of title, merchantability, fitness for a particular purpose, or non-infringement and any implied
          warranties arising from course of dealing or course of performance. The Company does not warrant that this Site or its
          contents will be complete, accurate, uninterrupted, secure, or error free or that the Site or the server that makes it
          available are free of viruses or other harmful components. All information on the Site is subject to change without notice.
        </p>

        <p class="document-titles">
          <b>Indemnification</b>
        </p>
        <p>You agree to defend, indemnify, and hold the Company harmless from and against any and all claims, losses, damages,
          liabilities, costs, and expenses, including attorneys' fees, arising from or related to your (i) use of the Site or (ii)
          violation of any of these Terms.
        </p>

        <p class="document-titles">
          <b>Limitation of Liability</b>
        </p>
        <p>In no event shall the Company be liable for any direct, indirect, consequential, special, or incidental damages
          arising out of or related to your use of or inability to use this site or goods or services purchased or obtained
          through this Site, whether in an action under contract, negligence, or any other theory, even if the Company has been
          advised of the possibility of such damages. The Company's total liability for any claim arising from or related to
          your use of this Site shall not exceed one hundred dollars (US$100).
        </p>

        <p class="document-titles">
          <b>Acceptable Use Policy</b>
        </p>
        <p>You agree to abide by, and utilize the Site only in accordance with these Terms.  In addition, you agree to
          (i) not use the Site for any illegal purpose, (ii) not use the Site in connection with any tortious act or any
          act that violates the legal rights of a third party, (iii) not use the Site to disseminate unsolicited commercial
          email, and (iv) not use the Site for any purpose that, in the Company’s reasonable discretion, is likely to reflect
          badly on the Company.
        </p>

        <p class="document-titles">
          <b>Passwords and Security</b>
        </p>
        <p>Some functions on this Site are limited to registered users who have obtained a user name and password.
          Registered users with user names and passwords may not share their user names and passwords with other persons
          and are entirely responsible for maintaining their confidentiality and integrity.  Each registered user agrees to
          notify the Company if their user name or password ceases to be secure or secret.
        </p>

        <p class="document-titles">
          <b>Miscellaneous</b>
        </p>
        <p>These Terms are governed by and shall be construed in accordance with the laws of the State of Georgia without
          giving effect to any principles of conflicts of law. If any provision of these Terms shall be unlawful, void,
          or for any reason unenforceable, then that provision shall be deemed severable from these Terms and shall not affect
          the validity and enforceability of any remaining provisions. These Terms are effective unless and until terminated
          by the Company.
        </p>

        <br>
        <br>
        <br>
        <p style="font-style: italic">Last revised: April 17, 2018</p>
      </div>
    </div>
  </div>
</div>
<app-footer></app-footer>

