<app-navbar></app-navbar>

<div class="container">
  <div class="row document-container">
    <h2>Legal Notices</h2>

    <div class="row">
      <div class="twelve columns" style="text-align: justify">
        <p class="document-titles">
          <b>Copyright Notice</b>
        </p>
        <p>
          The contents of this Web site are copyright © 2018, HumazeMD, LLC.  All rights reserved.
        </p>
        <p class="document-titles">
          <b>Trademark Notices</b>
        </p>
        <p>
          HumazeMD, the HumazeMD logo and certain other marks in this Web site are the trademarks,
          trade names, and service marks of HumazeMD, LLC (the “Company”).
          Any use of such marks without the prior written consent of the Company is prohibited.
          Other trademarks and logos are the property of the parties to whom they are attributed.
        </p>

        <p class="document-titles">
          <b>Policy on Third Party Legal Process</b>
        </p>
        <p>
          It is the Company's policy to respond with reasonable promptness to subpoenas and other legal
          process served on the Company or its subsidiaries that seek information, documents or other business records.
          Third parties wishing to serve such process on the Company may do so in writing to Taylor English Duma LLP, ATTN:
          Jonathan B. Wilson, Esq., 1600 Parkwood Circle, Suite 200, Atlanta, Georgia 30339.  The Company will evaluate each
          such request based upon the applicable law and facts. An administrative fee of $150 will be charged for each request
          to cover the Company’s fees and expenses in responding to the request. The Company may require the requesting party
          to pay the applicable hourly rate for requests that require significant engineering or technical support. The Company
          may also charge additional amounts for requests that require substantial photocopying, third party charges or other
          expenses.
          The Company expressly reserves any rights under applicable law in connection with its receipt, evaluation and any response to,
          any third party requests or legal process.
        </p>

        <p class="document-titles">
          <b>Legal Agreements</b>
        </p>
        <p>Any use, display or reproduction of this website is subject to the Website Terms of Use.  By visiting this website
          you agree to the Website Terms of Use.
          This website may collect information from visitors to this website.  All such information is subject to our
          Website Privacy Policy.  By visiting this website or providing any information to this website you accept our Privacy Policy.
          Website users who create an account, either for themselves or on behalf of a company they represent,
          are required to agree to Terms of Service.</p>

        <br>
        <br>
        <br>
        <p style="font-style: italic">Last revised: April 17, 2018</p>
      </div>
    </div>
  </div>
</div>
<app-footer></app-footer>
