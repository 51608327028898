import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { LocalStorageService } from 'ngx-webstorage';

@Injectable()
export class AuthService {
  token: string;
  currentUser;

  constructor(private http: HttpClient,
    private router: Router,
    private toastr: ToastrService,
    private storage: LocalStorageService) { }
  signupUser(body) {
    return new Promise((resolve, reject) => {
      this.http.post(environment.apiBaseUrl + '/users', body)
        .subscribe(
          data => {
            this.toastr.success('Registration was successfull');
            this.router.navigate(['/signin']);
            return resolve(data);
          },
          error => {
            this.toastr.error(error.error.error);
            return resolve(error);
          }
        );
    });
  }

  signinUser(body) {
    return this.http.post(environment.apiBaseUrl + '/users/login', body);
  }

  logout() {
    this.http.get(environment.apiBaseUrl + '/users/logout').subscribe(
      data => {
        this.storage.clear();
        this.router.navigate(['/signin']);
      },
      error => {
        this.storage.clear();
      }
    );
  }
  isAuthenticated() {
    return this.storage.retrieve('token') !== null;
  }

  getCurrentUser() {
    this.currentUser = this.storage.retrieve('currentUser');
    if (this.currentUser) {
      this.currentUser = JSON.parse(this.currentUser);
      return this.currentUser;
    }
  }

  setCurrentUserImage(imageUrl, type) {
    this.currentUser = this.storage.retrieve('currentUser');
    if (this.currentUser) {
      this.currentUser = JSON.parse(this.currentUser);
      if (type === 'userImg') {
        this.currentUser.image_url = imageUrl;
      } else {
        this.currentUser.watermark_image_url = imageUrl;
      }
      this.storeUpdatedCurrentUser(this.currentUser);
    }
  }

  setCurrentUserName(firstName) {
    this.currentUser = this.storage.retrieve('currentUser');
    if (this.currentUser) {
      this.currentUser = JSON.parse(this.currentUser);
      this.currentUser.first_name = firstName;
      this.storeUpdatedCurrentUser(this.currentUser);
    }
  }

  setProviderProfileUrl(url) {
    this.currentUser = this.storage.retrieve('currentUser');
    if (this.currentUser) {
      this.currentUser = JSON.parse(this.currentUser);
      this.currentUser.provider_profile_url = url;
      this.storeUpdatedCurrentUser(this.currentUser);
    }
  }

  storeUpdatedCurrentUser(user) {
    this.storage.store('currentUser', JSON.stringify(user));
  }

  getSubsAmount() {
    return this.http.get(environment.apiBaseUrl + '/users/subscription_amount');
  }

  getCouponCode(code) {
    return this.http.get(environment.apiBaseUrl + '/users/coupon/' + code);
  }
}
