import {Component, OnInit, Input, Output, EventEmitter, SimpleChanges, OnChanges} from '@angular/core';
import { CaseService } from '../../cases/cases.service';

@Component({
  selector: 'app-select-brands',
  templateUrl: './select-brands.component.html',
  styleUrls: ['../select-items.scss']
})
export class SelectBrandsComponent implements OnInit, OnChanges  {
  @Input() selectedBrands;
  @Input() userId;
  @Input() setOtherOnTop;
  @Output() countChanged: EventEmitter<string> =   new EventEmitter();
  @Input() loadFilterOnFirstClick;
  @Input() brandRemoved;

  brands;
  filteredList = [];
  brand = '';
  constructor(private _caseService: CaseService) { }

  ngOnInit() {
    const userID = this.userId || false;
    this._caseService.getBrands(userID).subscribe(
      data => {
        this.brands = data;
        this.brands.forEach((item) => {
          item.brands.forEach(brand => {
            brand.is_visible = true;
          });
        });
        if (this.selectedBrands) {
          this.hideSelectedBrands();
        }
        this.orderBrands(this.setOtherOnTop);

        if (this.loadFilterOnFirstClick) {
          this.filteredList = this.brands;
        }
      },
      err => {},
      () => {}
    );


  }

  getDevices() {
    this.filteredList = this.brands;
  }

  hideSelectedBrands() {
    this.selectedBrands.forEach(selectedItem => {
      this.brands.some(item => {
          const selectedBrand = item.brands.find(x => x.id === selectedItem.id);
          if (selectedBrand) {
            selectedBrand.is_visible = false;
            return selectedBrand;
          }
      });
    });
  }


  showBrand(item) {
    this.brands.some(itemBrand => {
      const unSelectedBrand = itemBrand.brands.find(x => x.id === item.id);
      if (unSelectedBrand) {
        unSelectedBrand.is_visible = true;
        return unSelectedBrand;
      }
    });
  }

  remove(item) {
    this.selectedBrands.splice(this.selectedBrands.indexOf(item), 1);
    this.countChanged.emit('brand');
    this.showBrand(item);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes ['selectedBrands'] && changes['selectedBrands'].currentValue && changes['selectedBrands'].currentValue.length === 0 &&
      changes['selectedBrands'].previousValue && changes ['selectedBrands'].previousValue.length > 0) {

      changes['selectedBrands'].previousValue.forEach(item => {
        this.showBrand(item);
      });
    }

    if (changes['brandRemoved'] && !changes['brandRemoved'].firstChange  && changes['brandRemoved'].currentValue) {
      this.showBrand(changes['brandRemoved'].currentValue);
    }
  }

  select(item) {
    this.selectedBrands.push(item);
    this.brand = '';
    this.filteredList = [];
    this.countChanged.emit('brand');
    item.is_visible = false;
  }

  filter() {
    this.filteredList = this.brands.map((i) => {
      return {
        name: i.brands.filter((x) => x.name.toLowerCase().indexOf(this.brand.toLowerCase()) > -1).length > 0 ? i.name : '',
        brands: i.brands.filter((x) => x.name.toLowerCase().indexOf(this.brand.toLowerCase()) > -1)
      };
    });
  }

  getBrandClass() {
    if (this.selectedBrands.length === 0) {
      return 'input-field';
    } else {
      return 'input-field-selected';
    }
  }

  onClickedOutside(e: Event) {
    if ((e.target as HTMLInputElement).id !== 'brand') {
      this.filteredList = [];
    }
  }

  orderBrands(otherOnTop) {
    const otherBrand = this.brands.find(brand => brand.name === 'Other');
    if (otherOnTop && otherBrand) {
      this.brands.splice(this.brands.indexOf(otherBrand), 1);
      this.brands.splice(0, 0, otherBrand);
    }
  }

}
