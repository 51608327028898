import { Component, OnInit, ViewEncapsulation, HostListener, ChangeDetectorRef } from '@angular/core';
import {CaseService} from '../cases.service';
import {ToastrService} from 'ngx-toastr';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Constants} from '../../constants';
import {NavigationEnd, Router} from '@angular/router';
import {AuthService} from '../../auth/auth.service';
import {DataService} from '../../helpers/data-service';

declare let ga: any;

@Component({
  selector: 'app-edit-case',
  templateUrl: './edit-case.component.html',
  styleUrls: ['../create-case/create-case.component.scss', './edit-case.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class EditCaseComponent implements OnInit {
  public caseItem;
  public genders;
  form: FormGroup;
  public formGender: FormControl;
  public formEthnicity: FormControl;
  public formAge: FormControl;
  public formDescription: FormControl;
  public angleNumber = 1;
  public treatmentItems = [{
    originalImageUrl: '',
    croppedImageUrl: '',
    watermarkedImageUrl: '',
    thumbnailUrl: '',
    buffer: null,
    treatmentName: '',
    angleNumber: this.angleNumber++,
    id: 0,
    index: 0,
    newAngle: false
  }];
  public newAngles = [
    this.treatmentItems
  ];
  public caseImagesToDelete = [];
  public imageUrlsToDelete;
  public caseImagesToUpdate = [];
  public finalCaseImagesToUpdate = [];
  public newCaseImages = [];
  public finalNewCaseImages = [];
  public skinTypes;
  public listOfSkinTypes = [];
  public ethnicities;
  public selectedEthnicity;
  public ageList;
  public selectedTreatments = [];
  public existingTreatments = [];
  public angles = [];
  public newTreatments = [];
  public deletedTreatments = [];
  public selectedBrands = [];
  public existingBrands = [];
  public newBrands = [];
  public deletedBrands = [];
  public age;
  imageChangedEvent: any = '';
  showCropper = false;
  index = null;
  angleIndex = 0;
  croppedImage: any = '';
  loading = false;
  public isImageUpdated = false;
  public editCaseDetails;
  id;
  updatingCase = false;
  currentUser;
  public validationError = false;
  public errorMessage = '';
  public skinTypeError = false;
  public treatmentListError = false;
  public brandListError = false;
  public formGenderError = false;
  public formAgeError = false;
  public formDescriptionError = false;
  public agreedTerms = false;
  userId;
  public treatmentNumberDropdown;
  public usedNumbers = [100];
  public usedTreatmentNumbers;
  public oldNumbers;
  public selectedChosenNumber;
  public selectedUpdateNumber;
  public lowestAvailableDropdownNumb;

  originalImage: any = '';
  imageToEdit: any = '';
  public isUploadButtonDisabled = false;
  public loaderMessage: any = '';

  public pageYOffset = 0;
  tabSelected = 0;
  anglesFromServer = [];
  public imagesUploadedCount = 0;


  constructor(private ref: ChangeDetectorRef,
              private _caseService: CaseService,
              private toastr: ToastrService, private constants: Constants,
              private router: Router, private _authService: AuthService,
              private _dataService: DataService) {
    this.age = constants.age;
    this.treatmentNumberDropdown = constants.treatmentNumber.map(function(item) {
      return {
        number: item.number,
      };
    });
    this.router.events.subscribe(event => {
      if (this.router.routerState.root.firstChild && !this.caseItem) {
        this.id = this.router.routerState.root.firstChild.snapshot.params.id;
      }
      if (event instanceof NavigationEnd) {
        ga('set', 'page', event['urlAfterRedirects']);
        ga('send', 'pageview');
      }
    });
  }

  getCase() {
    this._caseService.getCase(this.id).subscribe(
      data => {
        this.caseItem = data;
        this.onInitialize();
        this.userId = this.caseItem.user_id;
      },
      err => this.toastr.error('Error loading case.')
    );
  }

  ngOnInit() {
    this.getCase();
    this.createFormControls();
    this.createForm();
    this.usedNumbers = [];
  }

  onInitialize() {
    this.currentUser = this._authService.getCurrentUser();
    // user can generally view this data (so get from API can pass through
    // but we need to hide edit option from him
    if (!this.currentUser) {
      this.router.navigate(['/signin/edit/' + this.id]);
      return;
    }
    if (this.currentUser.id === this.caseItem.user_id || this.currentUser.is_admin) {
      this.loadData();
    } else {
      this.router.navigate(['/unauthorized']);
    }
  }

  chosenNumber(numb, angleIndex, i) {
    numb = numb.toString();
    this.selectedChosenNumber = this.treatmentNumberDropdown.find(x => x.number.toString() === numb);
    const treatment = this.angles[angleIndex].find(x => x.index === i);
    treatment.treatmentName = 'Treatment ' + numb;
    this.updateDropdown();
    this.updateExistingTreatmentNumber(treatment);
  }

  updateDropdown() {
    this.usedTreatmentNumbers = [];
    this.treatmentItems.forEach(item => {
      if (item.treatmentName.split(' ')[1] !== '0') {
        this.usedTreatmentNumbers.push(+item.treatmentName.split(' ')[1]);
      }
    });
    this.oldNumbers = this.usedNumbers.filter(item => this.usedTreatmentNumbers.indexOf(item) < 0);
    if (this.oldNumbers.length > 0) {
      this.oldNumbers.forEach(item => {
        this.selectedUpdateNumber = this.treatmentNumberDropdown.find(x => x.number === item);
        this.selectedUpdateNumber.isDisabled = false;
      });
    }
  }
  updateExistingTreatmentNumber(treatment) {
    if (treatment.id !== 0) {
      if (!this.caseImagesToUpdate.find(x => x.id === treatment.id)) {
        this.caseImagesToUpdate.push(treatment);
      }
    }
  }
  loadData() {
    this.angles = [];
    this.caseItem.angles.forEach((angle) => {
      const angleItem = angle.map((e, i) => {
        if (!this.anglesFromServer.includes(e.angle_number)) {
          this.anglesFromServer[e.angle_number] = [];
        }
        return {
          treatmentName: e.treatment_name,
          originalImageUrl: e.original_image_url,
          croppedImageUrl: e.cropped_image_url,
          watermarkedImageUrl: e.cropped_watermark_image_url,
          thumbnailUrl: e.thumbnail_url,
          buffer: e.thumbnail_url,
          id: e.id,
          index: i,
          angleNumber: e.angle_number,
        };

      });
      this.angles.push(angleItem);
      for (let i = 0; i < angleItem.length; i ++) {
        for (const key in this.anglesFromServer) {
          if (key === angleItem[i].angleNumber.toString()) {
            this.anglesFromServer[angleItem[i].angleNumber].push(angleItem[i].id);
          }
        }
      }

    });
    this.angles.sort((a, b) => a[0].angleNumber - b[0].angleNumber);
    this._caseService.getGenders(false).subscribe(
      data => { this.genders = data; },
      err => this.toastr.error('Error loading genders.')
    );
    this._caseService.getSkinTypes(false).subscribe(
      data => { this.skinTypes = data; },
      err => this.toastr.error('Error loading skin types.')
    );
    this._caseService.getEthnicities().subscribe(
      data => {
        this.ethnicities = data;
        this.selectedEthnicity = this.caseItem.ethnicity_id !== null ? this.ethnicities.find
                                (x => x.id === this.caseItem.ethnicity_id).name : null;
        this.checkAndSetFormValues();
      },
      err => this.toastr.error('Error loading ethnicities.')
    );
    this.selectedTreatments = this.caseItem.treatments;
    this.existingTreatments = this.selectedTreatments.map(treatment => treatment);
    this.selectedBrands = this.caseItem.brands;
    this.existingBrands = this.selectedBrands.map(brand => brand);
    this.ageList = this.age;
  }
  createFormControls() {
    this.formGender = new FormControl('', Validators.required);
    this.formEthnicity = new FormControl('');
    this.formAge = new FormControl('', Validators.required);
    this.formDescription = new FormControl('', Validators.required);
  }
  createForm() {
    this.form = new FormGroup({
      formGender: this.formGender,
      formEthnicity: this.formEthnicity,
      formAge: this.formAge,
      formDescription: this.formDescription
    });
  }
  checkAndSetFormValues() {
    if (this.caseItem && this.caseItem.gender) {
      this.formGender.setValue(this.caseItem.gender.name);
    }
    if (this.selectedEthnicity) {
      this.formEthnicity.setValue(this.selectedEthnicity);
    }
    if (this.caseItem.patient_age) {
      this.formAge.setValue(this.caseItem.patient_age);
    }
  }
  onClickedOutside(e: Event) {
    const target = e.target as HTMLInputElement;
    if (target.id !== 'treatment' && target.id !== 'brand' &&
      target.id !== 'skinType' && target.id !== 'skin-type-span' &&
      target.id !== 'skin-type-span2' && target.id !== 'select-skin' &&
      target.id !== 'select-skin-color') {
      this.listOfSkinTypes = [];
    }
  }
  validateForm(form) {
    this.angles.forEach(angle => {
      angle.length < 2 || (angle.length === 2 && angle[1].originalImageUrl === '') ?
        (this.validationError = true, this.errorMessage = 'Create at least two treatments') : this.validationError = false;
    });
    this.selectedTreatments.length < 1 ? this.treatmentListError = true : this.treatmentListError = false;
    this.selectedBrands.length < 1 ? this.brandListError = true : this.brandListError = false;
    this.caseItem.skin_type ? this.skinTypeError = false : this.skinTypeError = true;
  }
  draftValidation() {
    (this.angles[0].length < 1 || this.angles[0][0].originalImageUrl === '') ?
      (this.validationError = true, this.errorMessage = 'Create at least one treatment') : this.validationError = false;
  }
  getOlClass() {
    if (this.angles[0].length > 0) {
      return 'display';
    } else {
      return 'display-none';
    }
  }
  setImageIconClass(index, angleIndex) {
    if (this.angles[angleIndex][index].croppedImageUrl === '') {
      return 'uploaded-icon-hidden-edit';
    } else {
      return 'uploaded-icon-visible-edit';
    }
  }

  selectSkinType(skinType) {
    this.caseItem.skin_type = skinType;
    this.caseItem.skin_type.id = skinType.id;
    this.skinTypeError = false;
  }
  onGenderSelect(genderName) {
    this.caseItem.gender_id = this.genders.find(x => x.name === genderName).id;
  }

  onEthnicitySelect(ethnicityName) {
    this.caseItem.ethnicity_id = this.ethnicities.find(x => x.name === ethnicityName).id;
  }
  mapLists() {
    this.newTreatments = (this.selectedTreatments.filter(item => this.existingTreatments.indexOf(item) < 0)).map(item => item.id);
    this.deletedTreatments = (this.existingTreatments.filter(item => this.selectedTreatments.indexOf(item) < 0)).map(item => item.id);
    this.newBrands = (this.selectedBrands.filter(item => this.existingBrands.indexOf(item) < 0)).map(item => item.id);
    this.deletedBrands = (this.existingBrands.filter(item => this.selectedBrands.indexOf(item) < 0)).map(item => item.id);
    this.caseItem.patient_age = this.form.value.formAge !== '' ? this.form.value.formAge : this.caseItem.patient_age;
  }
  getEmptyItem(index) {
    return {
      originalImageUrl: '',
      croppedImageUrl: '',
      watermarkedImageUrl: '',
      thumbnailUrl: '',
      buffer: null,
      treatmentName: 'Treatment 0',
      id: 0,
      case_id: this.caseItem.id,
      index: index,
      isNew: true,
      angleNumber: this.angleIndex + 1
    };
  }

  resetCurrentImages() {
    this.imageToEdit = null;
    this.originalImage = null;
    if (this.imageChangedEvent) {
      this.imageChangedEvent.target.value = null;
    }
  }

  firefoxFormat(image) {
    const newName = image.name.split(':::');
    let urlEnd = newName[1].replace(/:/g, '/');
    urlEnd = urlEnd.substr(0, urlEnd.lastIndexOf('/'));
    return `${newName[0]}://${urlEnd}/`;
  }

  checkFireFoxFormat(image) {
    return navigator.userAgent.indexOf('Firefox') > 0 && image.name.includes(':::');
  }

  async uploadCroppedImage() {
    this.loaderMessage = 'Uploading...';
    this.showCropper = false;
    const newFile = this.imageChangedEvent ? this.imageChangedEvent.target.files[0] : null;
    if (newFile) {
      console.log('[' + new Date().toUTCString() + '] ' + ' uploading Image on edit');
      const fileToUpLoadType = newFile.type;
      const hashedFileName = this._dataService.getUuid(newFile.type.split('/')[1]);
      const secureUrlData = {name: hashedFileName, type: fileToUpLoadType};
      const urlData = await this.getSecureUrl(secureUrlData);
      await this.uploadImage(urlData, newFile);
      console.log('[' + new Date().toUTCString() + '] ' + ' Finish uploading Image');
      const params = this.getProccessParams(this.originalImage, hashedFileName);
      const urlPath = urlData['url'].substr(0, urlData['url'].lastIndexOf('/')) + '/';
      this.imageTreatment(params, urlPath, newFile.base64);
    } else {
      if (this.originalImage.originalSizeWidth !== this.originalImage.cropObj.width ||
        (this.originalImage.blackBars && this.originalImage.blackBars.length)) {
          this.originalImage.blackBars.startX = 46;
          this.originalImage.blackBars.startY = 30;
        const originalFile = this.originalImage.file;
        const fileName = this.checkFireFoxFormat(originalFile)  ?
          originalFile.name.substr(originalFile.name.lastIndexOf(':') + 1, originalFile.name.length) :
          originalFile.name.substr(originalFile.name.lastIndexOf('/') + 1, originalFile.name.length);
        const params = this.getProccessParams(this.originalImage, fileName);
        const urlPath = this.checkFireFoxFormat(originalFile) ? this.firefoxFormat(originalFile) :
                        originalFile.name.substr(0, originalFile.name.lastIndexOf('/')) + '/';
        this.imageTreatment(params, urlPath, originalFile.base64);
      }
    }
    this.resetCurrentImages();
  }


  async uploadImage(data, fileToUpLoad) {
    return this._caseService.uploadFile(data['url'], fileToUpLoad).toPromise();
  }

  async getSecureUrl(secureUrlData) {
    this.loading = true;
    this.imagesUploadedCount++;
    const data = await this._caseService.getPreSignedUrl(secureUrlData, this.currentUser.id).toPromise();
    const dataURL = data['url'];
    const originalImageUrl = dataURL.substr(0, data['url'].indexOf('?'));
    this.angles[this.angleIndex][this.index].originalImageUrl = originalImageUrl;
    return data;

  }

  private async imageTreatment(params, urlPathValue, imageB64) {
    this._caseService.resizerImage(this.currentUser.id, params).subscribe(
      (response: any) => {

        const urlPath = urlPathValue;
        const croppedImageUrl = urlPath + response.watermarkFilename;
        const watermarkedImageUrl = urlPath + response.watermarkFilename;
        const thumbImageUrl = urlPath + response.thumbFolderPath;
        const image = imageB64;

        const imageUploadPosition = this.imagesUploadedCount;

        this.angles[this.angleIndex][this.index].buffer = image;
        const angleIndex = this.angleIndex;
        const index = this.index;
        const angleNumberStored = this.angleIndex + 1;

        this.angles[this.angleIndex][this.index].croppedImageUrl = croppedImageUrl;
        this.angles[angleIndex][index].watermarkedImageUrl = watermarkedImageUrl;
        this.angles[angleIndex][index].thumbnailUrl = thumbImageUrl;

        if (this.angles[angleIndex][index].newAngle === true) {
          this.angles[angleIndex][index].angleNumber = angleNumberStored;
          if (this.angles[angleIndex][index].originalImageUrl) {
            this.newAngles.push(this.angles[angleIndex][index]);
          }
        } else {
          if (this.isImageUpdated) {
            const treatmentToUpdate = this.caseImagesToUpdate.find(x => x.id === this.angles[angleIndex][index].id);
            if (treatmentToUpdate !== undefined) {
              treatmentToUpdate.originalImageUrl = this.angles[angleIndex][index].originalImageUrl;
              treatmentToUpdate.croppedImageUrl = this.angles[angleIndex][index].croppedImageUrl;
              treatmentToUpdate.watermarkedImageUrl = this.angles[angleIndex][index].watermarkedImageUrl;
              treatmentToUpdate.thumbnailUrl = this.angles[angleIndex][index].thumbnailUrl;
              treatmentToUpdate.angleNumber = angleNumberStored;
            } else {
              this.caseImagesToUpdate.push(this.angles[angleIndex][index]);
            }
          } else {
            const newTreatment = this.newCaseImages.find(x => x.treatmentName ===
              this.angles[angleIndex][index].treatmentName);
            newTreatment.originalImageUrl = this.angles[angleIndex][index].originalImageUrl;
            newTreatment.croppedImageUrl = this.angles[angleIndex][index].croppedImageUrl;
            newTreatment.watermarkedImageUrl = this.angles[angleIndex][index].watermarkedImageUrl;
            newTreatment.thumbnailUrl = this.angles[angleIndex][index].thumbnailUrl;
            newTreatment.angleNumber = angleNumberStored;
          }
        }

        if (imageUploadPosition === this.imagesUploadedCount) {
          this.angles.forEach((e) => {
            const angleNum = e[0].angleNumber;
            e.forEach((angle) => angle.angleNumber = angleNum);
          });

          this.loading = false;
        }

        if (this.angles[angleIndex][index].index !== 0 && this.angles[angleIndex][index].treatmentName === 'Treatment 0') {
          this.angles[angleIndex][index].treatmentName = 'Treatment 1';
        }
        this.ref.detectChanges();

        if (imageUploadPosition === this.imagesUploadedCount) {
          this.loading = false;
        }
        if (this.angles[angleIndex][index].index !== 0 && this.angles[angleIndex][index].treatmentName === 'Treatment 0') {
          this.angles[angleIndex][index].treatmentName = 'Treatment 1';
        }
        console.log('[' + new Date().toUTCString() + '] ' + 'finish image process ');
      }
    );
  }

   private getProccessParams(imgProcessData, hashedNameOriginal) {
    const params = {
      x: imgProcessData.cropObj.left.toString(),
      y: imgProcessData.cropObj.top.toString(),
      w: imgProcessData.cropObj.width.toString(),
      h: imgProcessData.cropObj.height.toString(),
      filename: hashedNameOriginal,
      caseAction: 'edit',
      blackBars: []
    };

     if (imgProcessData.blackBars && imgProcessData.blackBars.length) {
       for (let i = 0; i < imgProcessData.blackBars.length; i++) {
         let blackBarsValues = {};
         const origWithValue = imgProcessData.blackBars[i].width * imgProcessData.cropRatio;
         const origCoordXValue = (imgProcessData.blackBars[i].startX * imgProcessData.cropRatio);
         const coordXValue = (origWithValue < 0) ? origCoordXValue - Math.abs(origWithValue) : origCoordXValue;
         const origHeightValue = imgProcessData.blackBars[i].height * imgProcessData.cropRatio;
         const origCoordYValue = (imgProcessData.blackBars[i].startY * imgProcessData.cropRatio);
         const coordYValue = (origHeightValue < 0) ? origCoordYValue - Math.abs(origHeightValue) : origCoordYValue;
         blackBarsValues = {
           bbWidth: Math.abs(origWithValue).toString(),
           bbHeight: Math.abs(origHeightValue).toString(),
           bbX: coordXValue.toString(),
           bbY: coordYValue.toString()
         };
         params.blackBars.push(blackBarsValues);
       }
     }
     console.log('params', params);
     return params;
   }

  addItem(angleIndex) {
    const treatmentItemsLastIndex = this.angles[angleIndex].length - 1;
    if (this.angles[angleIndex][treatmentItemsLastIndex].croppedImageUrl === '' ||
      this.angles[angleIndex][treatmentItemsLastIndex].treatmentName.split(' ')[1] === '') {
      const message = treatmentItemsLastIndex === 0 ?
        'You need to upload the image for \'Before\' treatment first' :
        'You need to upload the image and set the treatment number for previous treatment first!';
      this.toastr.error(message);
    } else {
      const item = this.getEmptyItem(this.angles[angleIndex].length);
      this.angles[angleIndex].push(item);
      this.newCaseImages.push(item);
    }
    this.angles[angleIndex].length < 2 ? (this.validationError = true, this.errorMessage = 'Create at least two treatments')
      : this.validationError = false;
  }
  deleteItem(index, angleIndex) {
    if (index === 0 && this.angles.length === 1) {
      this.toastr.error('You can not delete \'Before\' treatment');
    } else {
      const item = this.angles[angleIndex][index];
      const treatmentNumber = this.treatmentNumberDropdown.find(x => x.number.toString() === item.treatmentName.split(' ')[1]);
      if (!this.angles[angleIndex][index].isNew) {
        this.caseImagesToDelete.push(item.id);
        this.imageUrlsToDelete = [
          item.originalImageUrl = item.originalImageUrl.split('/')[4],
          item.thumbnailUrl = 'thumb/' + item.thumbnailUrl.split('/')[5],
          item.watermarkedImageUrl = item.watermarkedImageUrl.split('/')[4],
          item.croppedImageUrl = item.croppedImageUrl.split('/')[4]
        ];
        this.angles[angleIndex].splice(index, 1);
        if (this.caseImagesToUpdate.find(x => x === this.angles[angleIndex][index])) {
          this.caseImagesToUpdate = this.caseImagesToUpdate.filter(updatedItem => updatedItem.id !== this.angles[angleIndex][index]);
        }
      } else {
        this.newCaseImages = this.newCaseImages.filter(deletedItem => deletedItem.case_id !== this.angles[angleIndex][index].case_id);
        this.angles[angleIndex].splice(index, 1);

      }
      if (treatmentNumber !== undefined) {
        treatmentNumber.isDisabled = false;
      }
      this.angles[angleIndex].forEach(treatment => {
        if (treatment.index > index) {
          treatment.index--;
        }
      });
      this.angles[angleIndex].length < 2 ? (this.validationError = true, this.errorMessage = 'Create at least two treatments')
        : this.validationError = false;
      if (this.imageChangedEvent) {
        this.imageChangedEvent.target.value = null;
      }
      this.isImageUpdated = false;
    }
  }

  editTreatmentImage(index, item) {
    this.resetCurrentImages();
    this.loaderMessage = 'Loading...';
    this.loading = true;
    this.index = index;
    const img = new Image();
    img.crossOrigin = 'Anonymous';
    this.isImageUpdated = true;
    if (item.isNew === true) {
      this.isImageUpdated = false;
    }
    const rand = '?' + Math.random(); //  used due issue HM-546
    img.onload = () => {
      let canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      canvas.height = img.height;
      canvas.width = img.width;
      ctx.drawImage(img, 0, 0);
      const dataURL = canvas.toDataURL();
      canvas = null;

      this.imageToEdit = this._dataService.dataURLtoFile(dataURL, item.watermarkedImageUrl);
      this.showCropper = true;
      this.loading = false;
    };
    img.src = item.watermarkedImageUrl + rand;
  }

  fileChangeEvent(event: any, index): void {
    const file = event.target.files[0];
    this.validationError = false;
    if (file.size <= 15000000) {
      if (this._dataService.checkUploadedFileType(file.type)) {
        this.imageToEdit = null;
        this.originalImage = null;

        this.showCropper = true;
        this.imageChangedEvent = event;
        this.index = index;
      } else {
        this.toastr.error('Please upload allowed file types: .jpeg, .jpg or .png.');
      }
    } else {
      this.validationError = true;
      this.errorMessage = 'File is too large.  Please choose a file under 15MB';
    }
  }
  imageCropped(image: string) {
    this.croppedImage = image;
  }

  imageOriginal(image: string) {
    this.originalImage = image;
    this.isUploadButtonDisabled = false;
  }

  disableUploadButton(disabled) {
    this.isUploadButtonDisabled = disabled;
  }

  mapAndAddTreatment(addTreatmentList) {
    this.finalNewCaseImages = addTreatmentList.map((newCaseItem) => {
      if (newCaseItem.originalImageUrl) {
        return {
          case_id: this.caseItem.id,
          cropped_image_url: newCaseItem.croppedImageUrl,
          original_image_url: newCaseItem.originalImageUrl,
          thumbnail_url: newCaseItem.thumbnailUrl,
          treatment_name: newCaseItem.treatmentName,
          cropped_watermark_image_url: newCaseItem.watermarkedImageUrl,
          angle_number: newCaseItem.angleNumber
        };
      }
    });
    this._caseService.addNewTreatments(this.finalNewCaseImages).toPromise();
  }

  deleteTreatment(deleteTreatmentList) {
    this._caseService.deleteTreatment(this.caseItem.id, deleteTreatmentList).subscribe(
      data => {},
      error => {}
    );
  }
  mapAndUpdateTreatment(updateTreatmentList) {
    this.finalCaseImagesToUpdate = updateTreatmentList.map((item) => {
      return {
        id: item.id,
        cropped_image_url: item.croppedImageUrl,
        original_image_url: item.originalImageUrl,
        thumbnail_url: item.thumbnailUrl,
        treatment_name: item.treatmentName,
        cropped_watermark_image_url: item.watermarkedImageUrl,
        case_id: this.caseItem.id,
        angle_number: item.angleNumber
      };
    });
    this._caseService.updateTreatment(this.caseItem.id, this.finalCaseImagesToUpdate).subscribe(
      data => {},
      error => {}
    );
  }
  deleteCaseBrand(brandList) {
    this._caseService.deleteCaseBrand(this.caseItem.id, brandList).subscribe(
      data => {},
      error2 => {}
    );
  }
  addCaseBrand(brandList) {
    this._caseService.addCaseBrand(this.caseItem.id, brandList).subscribe(
      data => {},
      error2 => {}
    );
  }
  addCaseTreatment(treatmentList) {
    this._caseService.addCaseTreatment(this.caseItem.id, treatmentList).subscribe(
      data => {},
      error2 => {}
    );
  }
  deleteCaseTreatment(treatmentList) {
    this._caseService.deleteCaseTreatment(this.caseItem.id, treatmentList).subscribe(
      data => {},
      error2 => {}
    );
  }

  cancelUpload() {
    this.showCropper = false;
    if (this.imageChangedEvent) {
      this.imageChangedEvent.target.value = null;
    }
    this.originalImage = null;
    this.imageToEdit = null;
  }

  mapAndUpdateCaseDetails(caseUpdate) {
    this.editCaseDetails = {
        id: caseUpdate.id,
        angles: caseUpdate.angles,
        description: caseUpdate.description,
        ethnicity_id: caseUpdate.ethnicity_id,
        gender_id: caseUpdate.gender_id,
        is_draft: caseUpdate.is_draft,
        is_public: caseUpdate.is_public,
        patient_age: caseUpdate.patient_age,
        settings_notes: caseUpdate.settings_notes,
        skin_type_id: caseUpdate.skin_type && caseUpdate.skin_type.id || null
      };
  }
  async executeActions() {
    this.updatingCase = true;
    this.mapLists();
    if (this.newAngles.length > 0) {
      await this.mapAndAddTreatment(this.newAngles);
    }
    if (this.caseImagesToDelete.length > 0) {
      this.deleteTreatment(this.caseImagesToDelete);
    }
    if (this.caseImagesToUpdate.length > 0) {
      this.mapAndUpdateTreatment(this.caseImagesToUpdate);
    }
    if (this.newCaseImages.length > 0) {
      this.mapAndAddTreatment(this.newCaseImages);
    }
    if (this.deletedBrands.length > 0) {
      this.deleteCaseBrand(this.deletedBrands);
    }
    if (this.newBrands.length > 0) {
      this.addCaseBrand(this.newBrands);
    }
    if (this.newTreatments.length > 0) {
      this.addCaseTreatment(this.newTreatments);
    }

    if (this.deletedTreatments.length > 0) {
      this.deleteCaseTreatment(this.deletedTreatments);
    }
    this.mapAndUpdateCaseDetails(this.caseItem);
    this._caseService.updateCaseDetails(this.editCaseDetails).subscribe(
      data => {
        this.updatingCase = false;
        if (this.caseImagesToDelete.length > 0) {
          window.location.href = `${window.location.origin}/hm/${this.caseItem.user_id}`;
        } else {
          this.router.navigate(['/hm/', this.caseItem.user_id]);
        }
      },
      error2 => {}
    );
  }
  onSubmit(isDraft: boolean, publicForAll: boolean) {
    this.caseItem.is_draft = isDraft;
    this.caseItem.is_public = publicForAll;
    if (isDraft) {
      this.validationError = false;
      this.draftValidation();
      if (!this.validationError) {
        this.executeActions();
      } else {
        this.toastr.error('Please check all required fields');
      }
    } else {
      this.validationError = false;
      this.validateForm(this.form);
      if (!this.agreedTerms) {
        this.toastr.error('Please agree to the Terms and Conditions.');
      } else if (this.form.valid && !this.brandListError && !this.validationError &&
        !this.skinTypeError && !this.treatmentListError && this.agreedTerms) {
        this.executeActions();
      } else {
        this.formGenderError = this.formGender.value === '';
        this.formAgeError = this.formAge.value === '';
        this.formDescriptionError = !this.formDescription.value;
        this.toastr.error('Please check all required fields.');
      }
    }
  }
  updatingImage() {
    this.isImageUpdated = true;
  }
  firstImage() {
    this.isImageUpdated = false;
  }
  checkTreatmentsLength() {
    this.treatmentListError = this.selectedTreatments.length < 1;
  }
  checkBrandsLength() {
    this.brandListError = this.selectedBrands.length < 1;
  }
  getSelectedGender(gender) {
    return gender ? gender.name : 'Select gender...';
  }
  getSelectedEthnicity(ethnicity) {
    return ethnicity ? ethnicity : 'Select ethnicity...';
  }
  getSelectedAge(patientAge) {
    return patientAge ? patientAge : 'Select patient age...';
  }
  agree(agreed) {
    this.agreedTerms = !agreed;
  }
  @HostListener('window:scroll', ['$event'])
    onScroll(event) {
      this.pageYOffset = window.pageYOffset;
    }

  // TODO: Create a new tabs component

  selectTab(tab) {
    this.tabSelected = tab;
    this.angleIndex = tab;
  }

  addAngle() {
    const newAngle = [{
      originalImageUrl: '',
      croppedImageUrl: '',
      watermarkedImageUrl: '',
      thumbnailUrl: '',
      buffer: null,
      treatmentName: 'Treatment 0',
      index: 0,
      angleNumber: 0,
      newAngle: true
    }];

    if (this.angles.length < 5) {
      this.angles.push(newAngle);
      this.tabSelected = this.angles.length - 1;
      this.angleIndex = this.tabSelected;
    }
  }
  removeAngle(angleIndex, angleNumber) {
    if ( angleNumber !== 0 ) {
      this.anglesFromServer[angleNumber].forEach( imgID => {
        this.caseImagesToDelete.push(imgID);
      });
    }

    this.angles.splice(angleIndex, 1);
    this.tabSelected = angleIndex < this.angles.length ? angleIndex : angleIndex - 1;
    this.angleIndex = angleIndex < this.angles.length ? angleIndex : angleIndex - 1;
  }
}

