import { Component, OnInit } from '@angular/core';
import { CaseService } from './cases.service';
import {ToastrService} from 'ngx-toastr';


@Component({
  selector: 'app-cases',
  templateUrl: './cases.component.html',
  styleUrls: ['./cases.component.scss']
})
export class CasesComponent implements OnInit {
  public cases;
  public case;
  constructor(private _casesService: CaseService, private toastr: ToastrService) { }

  ngOnInit() {
  }


}
