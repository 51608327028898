import {Component, EventEmitter, Output} from '@angular/core';

@Component({
  selector: 'app-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss']
})

export class DatepickerComponent {

  @Output() dateFromPicked: EventEmitter<Date> = new EventEmitter();
  @Output() dateToPicked: EventEmitter<Date> = new EventEmitter();

  onFromValueChange(value: Date): void {
    this.dateFromPicked.emit(value);
  }

  onToValueChange(value: Date): void {
    this.dateToPicked.emit(value);
  }
}
