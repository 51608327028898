<div class="container admin">
  <div class="eleven columns">
    <div class="four columns">
    <app-date-picker (dateFromPicked)="dateFromPickedHandler($event)" (dateToPicked)="dateToPickedHandler($event)"></app-date-picker>
      <div class="form-control-feedback">
        <p *ngIf="isInvalid" class="error">{{errorMsg}}</p>
      </div>
    </div>
    <div class="six columns">
    <button class="btn submit-button" (click)="searchData()">Search</button>
    </div>
  </div>

    <table class="table">
      <thead class="thead-light">
      <tr>
        <th>Description</th>
        <th>Count</th>
      </tr>
      </thead>
      <tbody>
      <tr #activeUser style="height: 30px">
        <td>
          Active Users
        </td>
        <td>
          {{overallKpis?.numb_of_active_users}}
        </td>
      </tr>
      <tr #disabledUser style="height: 30px">
        <td>
          Disabled Users
        </td>
        <td>
      {{overallKpis?.numb_of_disabled_users}}
        </td>
      </tr>
      <tr style="height: 30px">
        <td>
          Cases
        </td>
        <td>
          {{overallKpis?.numb_of_cases}}
        </td>
      </tr>
      <tr style="height: 30px">
        <td>
          Public Cases
        </td>
        <td>
          {{overallKpis?.numb_of_public_cases}}
        </td>
      </tr>
      <tr style="height: 30px">
        <td>
          Private Cases
        </td>
        <td>
          {{overallKpis?.numb_of_private_cases}}
        </td>
      </tr>
      <tr style="height: 30px">
        <td>
          Photos
        </td>
        <td>
          {{overallKpis?.numb_of_photos}}
        </td>
      </tr>
      <tr style="height: 30px">
        <td>
          Deleted Cases
        </td>
        <td>
          {{overallKpis?.numb_of_deleted_cases}}
        </td>
      </tr>
      <tr style="height: 30px">
        <td>
          Drafts
        </td>
        <td>
          {{overallKpis?.numb_of_drafts}}
        </td>
      </tr>
      </tbody>
    </table>
    <button class="btn save-button" (click)="exportToCsv()">Export to CSV</button>
</div>
