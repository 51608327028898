<app-navbar></app-navbar>

<div class="loader" *ngIf="changingPassword">
  <span>Updating password...</span>
  <div class="animation"></div>
</div>

<div class="container">
  <div class="row">
    <div class="login-wrapper">
      <form novalidate [formGroup]="form">
        <div class="row">
          <div class="twelve columns">
            <label for="currentPassword" class="info-text">To change your password, please enter your current and new password twice.</label>
          </div>
        </div>

        <div class="row">
          <div class="twelve columns">
            <input class="u-full-width" formControlName="formCurrentPassword" placeholder="Current Password" id="currentPassword" type="password" name="password">
          </div>
        </div>

        <div class="row">
          <div class="twelve columns">
            <input class="u-full-width" formControlName="formNewPassword" placeholder="New Password" id="newPassword" type="password" name="newPassword">
            <div class="form-control-feedback"
                 *ngIf="(formNewPassword.errors) && (formNewPassword.dirty || formNewPassword.touched)">
              <p *ngIf="formNewPassword.errors.minlength" class="error">New Password must be at least 6 characters long</p>
              <p *ngIf="form.hasError('required', 'formNewPassword')" class="error">New Password is required</p>
              <p *ngIf="!formNewPassword.errors.minlength && formNewPassword.errors.pattern" class="error">New Password needs to have at least one uppercase one lowercase character and number</p>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="twelve columns">
            <input class="u-full-width" formControlName="formNewPasswordConfirm" placeholder="Confirm New Password" id="newPasswordConfirm" type="password" name="newPasswordConfirm">
            <div class="form-control-feedback"
                 *ngIf="(formNewPasswordConfirm.errors) && (formNewPasswordConfirm.dirty || formNewPasswordConfirm.touched)">
              <p *ngIf="form.hasError('required', 'formNewPasswordConfirm')" class="error">Password fields must match</p>
            </div>
            <div class="form-control-feedback">
              <p *ngIf="validatorError" class="error">New Password fields do not match!</p>
            </div>
            <div class="form-control-feedback">
              <p *ngIf="currentPasswordError" class="error">Current password is incorrect!</p>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="twelve columns">
            <input class="button-primary u-full-width" value="Submit" type="submit" (click)="changePassword()">
          </div>
        </div>
      </form>

    </div>
  </div>
</div>

<app-footer></app-footer>
