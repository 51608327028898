import {Component, Input, OnInit, Output, EventEmitter, OnChanges, SimpleChanges} from '@angular/core';
import {UserService} from '../../user/user.service';

@Component({
  selector: 'app-select-names',
  templateUrl: './select-names.component.html',
  styleUrls: ['../select-items.scss']
})
export class SelectNamesComponent implements OnInit, OnChanges {

  @Input() selectedNames;
  @Input() home;
  @Output() countChanged: EventEmitter<string> = new EventEmitter();
  @Input() loadFilterOnFirstClick;
  @Input() nameRemoved;

  names;
  filteredList = [];
  name = '';
  placeholder;
  constructor(private _userService: UserService) { }

  ngOnInit() {
    this._userService.getUsersNames().subscribe(
      data => {
        this.names = data;
        this.names.forEach((item) => {
          item.is_visible = true;
        });
        if (this.selectedNames) {
          this.hideSelectedNames();
        }
        if (this.loadFilterOnFirstClick) {
          this.filteredList = this.names;
        }
        },
      err => {},
      () => {}
    );
    this.placeholder = this.home ? 'Any Name' : 'Type...';
  }

  getWrapClass() {
    return this.home ? 'wrap-landing' : 'wrap';
  }

  getFilteredList() {
    this.filteredList = this.names;
  }

  public remove(item) {
    this.selectedNames.splice(this.selectedNames.indexOf(item), 1);
    this.countChanged.emit('name');
    const unSelectedName = this.names.find(x => x .id === item.id );
    unSelectedName.is_visible = true;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['selectedNames'] && changes['selectedNames'].currentValue && changes['selectedNames'].currentValue.length === 0 &&
      changes['selectedNames'].previousValue && changes['selectedNames'].previousValue.length > 0) {

      changes['selectedNames'].previousValue.forEach(item => {
        this.names.find(x => x.id === item.id).is_visible = true;
      });
    }
    if (changes['nameRemoved'] && !changes['nameRemoved'].firstChange && changes['nameRemoved'].currentValue) {
      this.names.find(x => x.id  === changes['nameRemoved'].currentValue.id ).is_visible = true;
    }
  }

  select(item) {
    this.selectedNames.push(item);
    this.countChanged.emit('name');
    this.name = '';
    this.filteredList = [];
    item.is_visible = false;
  }

  hideSelectedNames() {
    this.selectedNames.forEach(item => {
      if (!item.is_deleted) {
        const selectedName = this.names.find(x => x.id  === item.id );
        selectedName.is_visible = false;
      }
    });
  }

  filter() {
    if (this.name !== '') {
      this.filteredList = this.names.filter(function(el) {
        return (el.first_name + el.last_name).toLowerCase().indexOf(this.name.toLowerCase()) > -1;
      }.bind(this));
    } else {
      this.filteredList = [];
    }
  }
  getClass() {
    if (this.selectedNames.length === 0) {
      return 'input-field';
    } else {
      return 'input-field-selected';
    }
  }

  onClickedOutside(e: Event) {
    if ((e.target as HTMLInputElement).id   !== 'name') {
      this.filteredList = [];
    }
  }
}
