import { Component, OnInit } from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {UserService} from '../../user/user.service';
import {ToastrService} from 'ngx-toastr';
import {AuthService} from '../../auth/auth.service';

@Component({
  selector: 'app-change-password-page',
  templateUrl: './change-password-page.component.html',
  styleUrls: ['./change-password-page.component.scss']
})
export class ChangePasswordPageComponent implements OnInit {
  form: FormGroup;
  public formCurrentPassword: FormControl;
  public formNewPassword: FormControl;
  public formNewPasswordConfirm: FormControl;
  public validatorError = false;
  public changingPassword = false;
  public changePasswordBody;
  public currentPasswordError = false;
  currentUser;

  constructor(private router: Router, private _userService: UserService, private toastr: ToastrService,
              private _authService: AuthService) { }

  ngOnInit() {
    this.currentUser = this._authService.getCurrentUser();
    if (this.currentUser) {
      this.formCurrentPassword = new FormControl('', Validators.required);
      this.formNewPassword = new FormControl('', [
        Validators.required,
        Validators.minLength(6),
        Validators.pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d_$@!^%?*().|\\{}\[\]/#&+-=]{6,}$/)
      ]);
      this.formNewPasswordConfirm = new FormControl('', [
        Validators.required,
        Validators.minLength(6),
        Validators.pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d_$@!^%?*().|\\{}\[\]/#&+-=]{6,}$/),
      ]);
      this.form = new FormGroup({
        formCurrentPassword: this.formCurrentPassword,
        formNewPassword: this.formNewPassword,
        formNewPasswordConfirm: this.formNewPasswordConfirm
      });
    } else {
      this.router.navigate(['/signin/change_password']);
    }
  }
  passwordMatchValidator(form: FormGroup) {
    return form.get('formNewPassword').value === form.get('formNewPasswordConfirm').value;
  }
  changePassword() {
    if (this.passwordMatchValidator(this.form)) {
      if (this.form.valid) {
        this.changingPassword = true;
        this.changePasswordBody = {
          current_password: this.formCurrentPassword.value,
          password: this.formNewPassword.value
        }
        this._userService.changePassword(this.changePasswordBody).subscribe(
          data => {
            this.currentPasswordError = false;
            this.changingPassword = false;
            this._authService.logout();
            this.toastr.success('Your password has been successfully changed.');
          },
          error => {
            this.changingPassword = false;
            this.currentPasswordError = true;
          }
        )
        this.validatorError = false;
      }
    } else {
      this.currentPasswordError = false;
      this.validatorError = true;
    }
  }
}
