import { Injectable } from '@angular/core';

@Injectable()
export class Constants {
  age: string[];
  casePrivacyOptions: string[];
  colleagueMailContent: string;
  cancelMailBody: string;
  treatmentNumber;
  prefixes: string[];
  MAX_IMG = 4;
  MIN_IMG = 0;
  constructor() {
    this.age = ['6-10', '11-20', '21-30', '31-40', '41-50', '51-60', '61-70', '71+'];
    this.casePrivacyOptions = ['Everyone', 'Private'];
    this.colleagueMailContent = 'Build and manage an entire portfolio of your before and ' +
      'after pictures all in one place. Easily and quickly filter through your repository ' +
      'in order to show only your most relevant outcomes. Speed up your consultations and increase your ' +
      'close rate. Your before and after photos play a vital role in prospective patients\' decision-making. ' +
      'They can instill confidence in your patients, and some results may even inspire them to ' +
      'consider additional treatments. ' +
      ' %0D%0A %0D%0A Check it out at http://' + window.location.host +
      ' and sign up here: http://' + window.location.host + '/signup';
    this.cancelMailBody = 'I would like to modify my HumazeMD subscription. The account email address is: , and I would like: .';
    this.treatmentNumber = [
      { number: 1, isDisabled: false },
      { number: 2, isDisabled: false },
      { number: 3, isDisabled: false },
      { number: 4, isDisabled: false },
      { number: 5, isDisabled: false },
      { number: 6, isDisabled: false },
      { number: 7, isDisabled: false },
      { number: 8, isDisabled: false },
      { number: 9, isDisabled: false },
      { number: 10, isDisabled: false },
      { number: 11, isDisabled: false },
      { number: 12, isDisabled: false },
      { number: 13, isDisabled: false },
      { number: 14, isDisabled: false },
      { number: 15, isDisabled: false },
      { number: 16, isDisabled: false },
      { number: 17, isDisabled: false },
      { number: 18, isDisabled: false },
      { number: 19, isDisabled: false },
      { number: 20, isDisabled: false },
      { number: 21, isDisabled: false },
      { number: 22, isDisabled: false },
      { number: 23, isDisabled: false },
      { number: 24, isDisabled: false },
      { number: 25, isDisabled: false },
      { number: 26, isDisabled: false },
      { number: 27, isDisabled: false },
      { number: 28, isDisabled: false },
      { number: 29, isDisabled: false },
      { number: 30, isDisabled: false },
      { number: 31, isDisabled: false },
      { number: 32, isDisabled: false },
      { number: 33, isDisabled: false },
      { number: 34, isDisabled: false },
      { number: 35, isDisabled: false },
      { number: 36, isDisabled: false },
      { number: 37, isDisabled: false },
      { number: 38, isDisabled: false },
      { number: 39, isDisabled: false },
      { number: 40, isDisabled: false },
      { number: 41, isDisabled: false },
      { number: 42, isDisabled: false },
      { number: 43, isDisabled: false },
      { number: 44, isDisabled: false },
      { number: 45, isDisabled: false },
      { number: 46, isDisabled: false },
      { number: 47, isDisabled: false },
      { number: 48, isDisabled: false },
      { number: 49, isDisabled: false },
      { number: 50, isDisabled: false }
      ];
    this.prefixes = ['Dr.'];
  }
}
