import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { NewsModel } from './news-model';

@Injectable()
export class NewsDataService {

    private news = new BehaviorSubject<NewsModel>(new NewsModel());
    editDataNews = this.news.asObservable();

    constructor() {}

    changeNews(transferNews: NewsModel){
        this.news.next(transferNews);
    }
}
