<div class="container admin">
  <button class="button save-button" [routerLink]="['/admin/skin-type']">Add skin type</button>
  <table class="table">
    <thead class="thead-light">
    <tr>
      <th>Name</th>
      <th>Is deleted</th>
      <th>Description</th>
      <th>Color</th>
      <th></th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let type of skinTypes" style="height: 30px">
      <td>
        {{type.name}}
      </td>
      <td>
        {{type.is_deleted ? 'YES' : 'NO'}}
      </td>
      <td>
        {{type.description}}
      </td>
      <td>
        {{type.color}}
        <div class="color" [style.background-color]="type.color"></div>
      </td>
      <td>
        <i class="fa fa-edit" aria-hidden="true" [routerLink]="['/admin/skin-type/' + type.id + '/edit']"></i>
        <i class="fa fa-trash" aria-hidden="true" (click)="deleteSkinType(type.id)"></i>
      </td>
    </tr>
    </tbody>
  </table>
</div>
