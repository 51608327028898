<div class="treatment-multi-selector">
  <div class="input-field">
    <div class="wrap">
      <ul>
        <li *ngFor="let item of selectedBrands" class="selected">
          <span>{{item.name}}</span>
          <a (click)="remove(item)">x</a>
        </li>
        <li class="search-field">
          <input id="brand" type="text" [ngClass]="getBrandClass()"
                 [(ngModel)]=brand (click)=getDevices() (keyup)="filter()" placeholder="Type..." autocomplete="off">
        </li>
      </ul>
    </div>
  </div>
  <div class="suggestions" *ngIf="filteredList.length > 0" (clickOutside)="onClickedOutside($event)">
    <ul>
      <li *ngFor="let manufacturer of filteredList">
        {{manufacturer.name}}
        <ul>
          <li *ngFor="let device of manufacturer.brands">
            <a (click)="select(device)" *ngIf="device.is_visible">{{device.name}}</a>
          </li>
        </ul>
    </ul>
  </div>
</div>
