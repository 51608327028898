<div class="container admin">
  <button class="button save-button" [routerLink]="['/admin/ethnicity']">Add ethnicity</button>
  <table class="table">
    <thead class="thead-light">
    <tr>
      <th>Ethnicity name</th>
      <th>Is deleted</th>
      <th></th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let ethnicity of ethnicities" style="height: 30px">
      <td>{{ethnicity.name}}</td>
      <td>{{ethnicity.is_deleted ? 'YES' : 'NO'}}</td>
      <td>
        <i class="fa fa-edit" aria-hidden="true" [routerLink]="['/admin/ethnicity/' + ethnicity.id]"></i>
        <i class="fa fa-trash" aria-hidden="true" (click)="deleteEthnicity(ethnicity.id)"></i>
      </td>
    </tr>
    </tbody>
  </table>
</div>
