<div class="container tags {{ tagsContainer }}">
  <div *ngFor="let brand of brandFilters" class="tag">
    {{ brand.name }}
    <a class="tag-delete" (click)="removeTag('brand', brand)">x</a>
  </div>
  <div *ngFor="let treatment of treatmentFilters" class="tag">
    {{ treatment.name }}
    <a class="tag-delete" (click)="removeTag('treatment', treatment)">x</a>
  </div>
  <div *ngIf="skinType" class="tag">
    skin: {{ skinType.name }}
    <a class="tag-delete" (click)="makeFilterBy('skin type', null); expandFilter('skin type','isSkinTypeExpended')">x</a>
  </div>
  <div *ngIf="gender" class="tag">
    {{ gender.name }}
    <a class="tag-delete" (click)="makeFilterBy('gender', null); expandFilter('gender','isGenderExpended')">x</a>
  </div>
  <div *ngIf="age" class="tag">
    age: {{ age }}
    <a class="tag-delete" (click)="makeFilterBy('age', null); expandFilter('age','isAgeExpended')">x</a>
  </div>
  <div *ngIf="privacy" class="tag">
    privacy: {{ privacy }}
    <a class="tag-delete" (click)="makeFilterBy('privacy', null); expandFilter('privacy','isPrivacyExpended')">x</a>
  </div>
<!--  <div *ngFor="let clinic of clinicFilters"  class="tag">-->
<!--    clinic: {{ clinic.company_name }}-->
<!--    <a class="tag-delete"  (click)="removeTag('clinic', clinic)">x</a>-->
<!--  </div>-->

  <div *ngFor="let name of nameFilters"  class="tag">
    name: {{ name.first_name + ' ' + name.last_name + ' ( id = ' + name.id +' )' }}
    <a class="tag-delete"  (click)="removeTag('name', name)">x</a>
  </div>

  <div *ngIf="
      brandFilters.length ||
      treatmentFilters.length ||
      skinType ||
      gender ||
      age  ||
      privacy ||
      nameFilters.length
    " class="tag clear">
    Remove all
    <a class="tag-delete" (click)="clearFilters()">x</a>
  </div>
</div>

<div class="container">
  <div class="three columns {{ filterBox }}">
    <div class="filters">
      <div class="filter"   (click)="expandFilter('privacy','isPrivacyExpended')">
        <span class="filter-name">PRIVACY</span>
        <i class="fa" [ngClass]="expandedVars.isPrivacyExpended ? 'fa-minus' : 'fa-plus'"></i>
      </div>
      <div *ngIf="expandedVars.isPrivacyExpended" id="privacyContainer">
        <select class="gender-filter" id="privacy" (change)="makeFilterBy('privacy', $event.target.value)">
          <option value="all" selected>All</option>
          <option value="public">Only public</option>
          <option value="private">Only private</option>
          <option *ngIf="onAdminPage" value="draft">Draft</option>
        </select>
      </div>

      <div class="filter" (click)="expandFilter('treatment', 'isTreatmentExpended')">
        <span class="filter-name">TREATMENT</span>
        <i class="fa" [ngClass]="expandedVars.isTreatmentExpended ? 'fa-minus' : 'fa-plus'"></i>
      </div>
      <div *ngIf="expandedVars.isTreatmentExpended" class="filter-treatment">
        <app-select-treatments  [selectedTreatments]="treatmentFilters" [userId]="userId" [loadFilterOnFirstClick]="loadFilterOnFirstClick"
                               (countChanged)="countChangedHandler($event)" [treatmentRemoved]="treatmentRemoved" >
        </app-select-treatments>
      </div>

      <div class="filter" (click)="expandFilter('skin type', 'isSkinTypeExpended' )">
        <span class="filter-name">SKIN TYPE</span>
        <i class="fa" [ngClass]="expandedVars.isSkinTypeExpended ? 'fa-minus' : 'fa-plus'"></i>
      </div>
      <div *ngIf="expandedVars.isSkinTypeExpended" class="filter-skin-type">
        <div *ngFor="let skin of skinTypes" class="skin-type-flex" (click)="makeFilterBy('skin type', skin)">
          <div class="item-color skin-filter" [style.background-color]="skin.color"></div>
          <div class="skin-text">
            <p class="skin-name">{{ skin.name }}</p>
            <p>{{ skin.description }}</p>
          </div>
        </div>
      </div>

      <div class="filter" (click)="expandFilter('gender','isGenderExpended')">
        <span class="filter-name">GENDER</span>
        <i class="fa" [ngClass]="expandedVars.isGenderExpended ? 'fa-minus' : 'fa-plus'"></i>
      </div>
      <div *ngIf="expandedVars.isGenderExpended" class="filter-gender">
        <select class="gender-filter" id="gender" (change)="makeFilterBy('gender', $event.target.value)">
          <option value="" disabled selected>Select gender</option>
          <option *ngFor="let gender of genders" [value]="gender.id">{{
            gender.name
          }}</option>
        </select>
      </div>

      <div class="filter" (click)="expandFilter('age', 'isAgeExpended')">
        <span class="filter-name">AGE</span>
        <i class="fa" [ngClass]="expandedVars.isAgeExpended ? 'fa-minus' : 'fa-plus'"></i>
      </div>
      <div *ngIf="expandedVars.isAgeExpended" class="filter-age">
        <select class="gender-filter" id="age" (change)="makeFilterBy('age', $event.target.value)">
          <option value="" disabled selected>Select age</option>
          <option *ngFor="let age of ages" [value]="age">{{ age }}</option>
        </select>
      </div>

      <div class="filter" (click)="expandFilter('brand','isBrandExpended')">
        <span class="filter-name">BRAND</span>
        <i class="fa" [ngClass]="expandedVars.isBrandExpended ? 'fa-minus' : 'fa-plus'"></i>
      </div>
      <div *ngIf="expandedVars.isBrandExpended" class="filter-treatment">
        <app-select-brands  class="select-brands" [userId]="userId" [selectedBrands]="brandFilters"  [loadFilterOnFirstClick]="loadFilterOnFirstClick"
          (countChanged)="countChangedHandler($event)" [brandRemoved]="brandRemoved" ></app-select-brands>
      </div>

      <div class="filter" (click)="expandFilter('case', 'isCaseExpended')" >
        <span class="filter-name">CASE #</span>
        <i class="fa" [ngClass]="expandedVars.isCaseExpended ? 'fa-minus' : 'fa-plus'"></i>
      </div>
      <div *ngIf="expandedVars.isCaseExpended" class="filter-case">
        <input type="number" placeholder="Type..." (keyup)="onCaseChanged($event)" (change)="onCaseEnter($event)"
          (keydown.enter)="onCaseEnter($event)" [value]="caseId" min="0" />
        <div *ngIf="caseId" class="tag">
          <button class="tag-delete all-cases" (click)="caseId = ''; showAllCases()">Back to all cases</button>
        </div>
      </div>

<!--      <div class="filter" (click)="expandFilter('clinic','isClinicExpended')" *ngIf="onAdminPage">-->
<!--        <span class="filter-name">CLINIC</span>-->
<!--        <i class="fa" [ngClass]="expandedVars.isClinicExpended ? 'fa-minus' : 'fa-plus'"></i>-->
<!--      </div>-->
<!--      <div *ngIf="expandedVars.isClinicExpended" class="filter-treatment">-->
<!--        <app-select-clinics  [selectedClinics]="clinicFilters"  [loadFilterOnFirstClick]="loadFilterOnFirstClick"-->
<!--                                (countChanged)="countChangedHandler($event)" [clinicRemoved]="clinicRemoved" >-->
<!--        </app-select-clinics>-->
<!--      </div>-->

      <div class="filter" (click)="expandFilter('name','isNameExpended')" *ngIf="onAdminPage">
        <span class="filter-name">USER NAMES</span>
        <i class="fa" [ngClass]="expandedVars.isNameExpended ? 'fa-minus' : 'fa-plus'"></i>
      </div>
      <div *ngIf="expandedVars.isNameExpended" class="filter-treatment">
        <app-select-names  [selectedNames]="nameFilters"  [loadFilterOnFirstClick]="loadFilterOnFirstClick"
                             (countChanged)="countChangedHandler($event)" [nameRemoved]="nameRemoved" >
        </app-select-names>
      </div>
    </div>
  </div>
  <div class="nine columns {{ caseBox }}">
    <div class="">
      <div class="search-results" infiniteScroll [infiniteScrollDistance]="0.1" [infiniteScrollThrottle]="3"
        (scrolled)="onScroll()">
        <div class="feed">
          <div *ngIf="cases.length == 0">
            <h4>No results matching your criteria</h4>
          </div>
          <div class="feed-item" *ngFor="let caseItem of cases; let caseIndex = index">
            <div class="item-header">
              <img  [alt]="getAltValues(caseItem)"  src="{{
                  caseItem.user && caseItem.user.image_url
                    ? caseItem.user.image_url
                    : '../../../assets/images/profilePlaceholder.png'
                }}"/>
              <div class="header-text">
                <p class="treatment-name">
                  {{ showTreatmentInfo(caseItem) }}
                </p>
                <p class="author" [routerLink]="[
                    '/hm/',
                    caseItem.user.provider_profile_url || caseItem.user.id
                  ]">
                  by
                  <span class="author-name">{{ caseItem.user.prefix }} {{ caseItem.user.first_name }}
                    {{ caseItem.user.last_name }}</span>
                </p>
              </div>
              <p *ngIf="allCases && caseItem.is_deleted" class="deleted-info">
                Deleted
              </p>
              <div class="dropdown" *ngIf="isItemOwner(caseItem) || allCases">
                <i class="fa fa-ellipsis-v dot-menu" (click)="openMenu(caseItem.id)"></i>
                <ul *ngIf="opened && openedMenu[caseItem.id]" class="opened-dot-menu">
                  <li class="bottom-zero" (click)="editCase(caseItem, false)">
                    Edit
                  </li>
                  <li class="bottom-zero" (click)="openModal(confirmPopup, caseItem.id)">
                    {{ caseItem.is_deleted ? "Restore" : "Delete" }}
                  </li>
                  <li class="bottom-zero" (click)="downloadCase(caseItem.id)">
                    Download
                  </li>
                </ul>
                <div *ngIf="!caseItem.is_public" class="privacy">
                  <i class="fa fa-eye-slash eye-red"></i>
                </div>

                <ng-template #confirmPopup>
                  <div class="modal-body text-center confirm-modal">
                    <div class="header">
                      <p>
                        Are you sure you want to
                        {{ caseItem.is_deleted ? "restore" : "delete" }} this
                        case?
                      </p>
                      <div class="separator"></div>
                    </div>

                    <a class="button button-delete" (click)="confirmDelete(caseItem.id)">Yes</a>
                    <a class="button decline-button" (click)="decline()">No</a>
                  </div>
                </ng-template>
              </div>
            </div>

              <div *ngFor="let angle of caseItem.angles; let angleIndex = index" >
                <div class="item-body" (click)="openModalWithComponent(caseItem)"
                *ngIf="angleIndex === caseItem.angleVisible"
                [ngClass]="{'fade-in': angleIndex === caseItem.angleVisible}" >
                  <div class="item-image">
                    <img [alt]="getAltValues(caseItem)" class="before-after before-image" [src]="
                                    (angle.length > 0 &&
                                      angle[0].thumbnail_url) ||
                                    '../../../assets/images/profilePlaceholder.png'
                                  " />
                    <div class="transition">
                      <i class="fa fa-arrow-right"></i>
                    </div>
                    <img [alt]="getAltValues(caseItem)"  class="before-after ater-image" [src]="
                                    (angle.length > 1 &&
                                      angle[angle.length - 1]
                                        .thumbnail_url) ||
                                    '../../../assets/images/profilePlaceholder.png'
                                  " />
                  </div>
                </div>
              </div>
            <div class="item-footer">
              <div class="left-footer">
                <div class="item-color" [style.background-color]="
                    (caseItem.skin_type && caseItem.skin_type.color) || 'white'
                  "></div>
                <div class="treatment-name">
                  <p class="item-text">
                    {{ showBrandInfo(caseItem) }}
                  </p>
                  <p class="item-sub-text">
                    {{ showTreatmentCount(caseItem) }}
                  </p>
                </div>
              </div>
              <div class="right-footer">
                <div class="item-age">
                  <p class="item-text-sex-age-bold">AGE</p>
                  <p *ngIf="caseItem.gender" [ngClass]="
                      caseItem.brands.length > 1 &&
                      caseItem.gender.name === 'Non-Binary'
                        ? 'item-text-sex-age-large-age'
                        : 'item-text-sex-age'
                    ">
                    {{ caseItem.patient_age || "N/A" }}
                  </p>
                </div>
                <div class="item-sex">
                  <p class="item-text-sex-age-bold">SEX</p>
                  <p *ngIf="caseItem.gender" [ngClass]="
                      caseItem.brands.length > 1 &&
                      caseItem.gender.name === 'Non-Binary'
                        ? 'item-text-sex-age-large-gender'
                        : 'item-text-sex-age'
                    ">
                    {{ (caseItem.gender && caseItem.gender.name) || "N/A" }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
