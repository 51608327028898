<div class="login-container">
  <div class="row">
    <div class="login-wrapper">
      <div class="row">
        <div class="logo">
          <img src="../../../assets/images/logo.png" routerLink="/"/>
        </div>
      </div>
      <form (ngSubmit)="onSignin(f)" #f="ngForm" class="login-form">
        <div class="row">
          <div class="twelve columns">
            <!-- <label for="email">Email</label> -->
            <input class="u-full-width" placeholder="Email" id="email" ngModel type="email" name="email">
          </div>
        </div>

        <div class="row">
          <div class="twelve columns">
            <!-- <label for="password">Password</label> -->
            <input class="u-full-width" placeholder="Password" id="password" ngModel type="password" name="password">
          </div>
        </div>

        <div class="row">
          <div class="twelve columns">
            <input class="button-primary u-full-width" value="Sign In" type="submit">
          </div>
        </div>

        <div class="row">
          <div class="twelve columns">
            <a href="#" routerLink="/forgot">Forgot password?</a>
          </div>
        </div>

      </form>
    </div>
  </div>
</div>
