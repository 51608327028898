
import {throwError as observableThrowError, Observable} from 'rxjs';

import {catchError} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {HttpEvent, HttpInterceptor, HttpHandler, HttpRequest} from '@angular/common/http';

import { LocalStorageService } from 'ngx-webstorage';
import {Router} from '@angular/router';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private storage: LocalStorageService, private router: Router) {

  }
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let authReq;
    if (!req.url.startsWith('https://s3.amazonaws.com')) {
      // remove auth header if req going to amazon s3
       authReq = req.clone(
        {
          headers: req.headers.set('Authorization', `Bearer ${this.storage.retrieve('token')}`)
        }
      );
    } else {
       authReq = req.clone({});
    }

    // send the newly created request
    return next.handle(authReq).pipe(
      catchError((error, caught) => {
        // intercept the respons error and displace it to the console
        if (error.status === 401) {
          this.router.navigate(['/signin']);
        }
        // return the error to the method that called it

        return observableThrowError(error);
      })) as any;

    }
}
