import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { AdminService } from '../admin.service';

@Component({
  selector: 'app-skin-types',
  templateUrl: './skin-types.component.html',
  styleUrls: ['../admin.component.scss']
})
export class SkinTypesComponent implements OnInit {

  skinTypes;
  constructor(private adminService: AdminService, private toastr: ToastrService) { }

  ngOnInit() {
    this.getSkinTypes();
  }

  getSkinTypes() {
    this.adminService.getSkinTypes().subscribe(
      data => {
        this.skinTypes = data;
      }
    );
  }

  deleteSkinType(skinTypeId) {
    const skinType = {
      id: skinTypeId,
      is_deleted: true
    };
    this.adminService.updateSkinType(skinType).subscribe(
      data => {
        this.toastr.success('Successfully deleted');
        this.getSkinTypes();
      }, err => {
        this.toastr.error('Could not delete skin type');
      }
    );
  }

}
