import { Component, OnInit } from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {HomePageService} from '../home-page.service';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'app-contact-page',
  templateUrl: './contact-page.component.html',
  styleUrls: ['./contact-page.component.scss']
})
export class ContactPageComponent implements OnInit {
  form: FormGroup;
  public formName: FormControl;
  public formPhone: FormControl;
  public formEmail: FormControl;
  public formMessage: FormControl;
  public formSubmit;
  public contactForm;

  constructor(private _homeService: HomePageService, private toastr: ToastrService) { }

  ngOnInit() {
    this.createFormControls();
    this.createForm();
    this.formSubmit = false;
  }
  createFormControls() {
    this.formName = new FormControl('', Validators.required);
    this.formPhone = new FormControl('');
    this.formEmail = new FormControl('', [Validators.required, Validators.pattern('[^ @]*@[^ @]*')]);
    this.formMessage = new FormControl('', Validators.required);
  }
  createForm() {
    this.form = new FormGroup({
      formName: this.formName,
      formPhone: this.formPhone,
      formEmail: this.formEmail,
      formMessage: this.formMessage
    });
  }
  sendMessage() {
    this.formSubmit = true;
    if (this.form.valid) {
      this.contactForm = {
        name: this.formName.value,
        phone: this.formPhone.value,
        email: this.formEmail.value,
        message: this.formMessage.value
      }
      this._homeService.postContactForm(this.contactForm).subscribe(
        data => {
          this.ngOnInit();
          this.toastr.success('Your message has been successfully sent.');
        },
        error => {
          this.toastr.error('Something went wrong');
        }
      );
    }
  }
}
