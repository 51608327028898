import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class FeedDataService {
  private modalService = new BehaviorSubject<any>({});
  currentModalService = this.modalService.asObservable();

  constructor() {}

  transferService(mdlService) {
    this.modalService.next(mdlService);
  }
}
