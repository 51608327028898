<app-navbar></app-navbar>

<div class="loader" [hidden]="!updatingCard">
  <span>Updating card...</span>
  <div class="animation"></div>
</div>

<div class="container">
  <div class="row wrap register">
    <div class="change-wrapper">
      <div class="row">
        <div class="twelve columns">
          <label for="cardNo">Credit card number</label>
          <label class="cards">
            <img class="card-icons" src="../../../assets/images/visa.png"/>
            <img class="card-icons" src="../../../assets/images/mastercard.png"/>
            <img class="card-icons" src="../../../assets/images/amex.png"/>
            <img class="card-icons" src="../../../assets/images/discover.png"/>
          </label>
          <div class="stripe-bottom">
            <div type="tel" id="cardNo" name="cardNo" class="form-control"></div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="six columns">
          <label for="cvcCode">Security code</label>
          <div class="stripe-bottom">
            <div type="tel" id="cvcCode" name="code" class="form-control"></div>
          </div>
        </div>
        <div class="six columns">
          <label for="expDate">Expiration date</label>
          <div class="stripe-bottom">
            <div type="tel" id="expDate" name="date" class="form-control"></div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="four columns">
          <label for="postalCode">Postal code</label>
          <div class="stripe-bottom">
            <div type="tel" id="postalCode" name="postalCode" class="form-control"></div>
          </div>
        </div>
        <div class="eight columns">
          <label for="country">Country</label>
          <input type="text" id="country" name="country" class="form-control" placeholder="US"/>
        </div>
      </div>

      <button class="button-primary" type="button" (click)="onChange()" [disabled]="!isButtonEnabled()">Change</button>
    </div>
  </div>
</div>

<app-footer></app-footer>

