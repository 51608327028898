import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class CaseService {
  constructor(private http: HttpClient,
              private router: Router,
              private toastr: ToastrService) {}

  buildFilterString(filters) {
    let filterString = '';
    filterString += filters.user_id ? '&user_id=' + filters.user_id : '';
    filterString += filters.skin_type ? '&skin_type=' + filters.skin_type.id : '';
    filterString += filters.gender ? '&gender=' + filters.gender.id : '';
    filterString += filters.age ? '&age=' + filters.age : '';
    filterString += filters.treatments.length > 0 ? '&treatments=' + filters.treatments : '';
    filterString += filters.brands.length > 0 ? '&brands=' + filters.brands : '';
    filterString += filters.clinics.length > 0 ? '&clinics=' + filters.clinics : '';
    filterString += filters.filter_user_ids.length > 0 ? '&user_ids=' + filters.filter_user_ids : '';
    filterString += filters.case_id ? '&case_id=' + filters.case_id : '';
    filterString += filters.is_draft != null ? '&is_draft=' + filters.is_draft : '';
    filterString += filters.public && filters.private ? '' :
      filters.public ? '&is_public=true' : filters.private ? '&is_public=false' : '';
    filterString += filters.manufacturer_name ? '&manufacturer_name=' + filters.manufacturer_name : '';
    filterString += filters.brand_name ? '&brand_name=' + filters.brand_name : '';
    filterString += filters.treatment_name ? '&treatment_name=' + filters.treatment_name : '';

    return filterString;
  }
  downloadCaseImages(id: number) {
    return this.http.get(`${environment.apiBaseUrl}/cases/${id}/case_images/download`, {
      responseType: 'arraybuffer'
    });
  }

  getCases(offset, limit, filters, allCases) {
    const filterString = this.buildFilterString(filters);
    return this.http.get<any[]>(environment.apiBaseUrl + '/cases?limit=' + limit + '&offset=' + offset + filterString +
      '&all=' + allCases);
  }

  getCase(id) {
    return this.http.get( environment.apiBaseUrl + '/cases/' + id);
  }

  getTreatments(ID) {
    return ID ? this.http.get(environment.apiBaseUrl + '/treatments/user/' + ID) : this.http.get(environment.apiBaseUrl + '/treatments');

  }

  getAges(ID) {
    return this.http.get(environment.apiBaseUrl + '/ages/user/' + ID);
  }

  getBrands(ID) {
    return ID ? this.http.get(environment.apiBaseUrl + '/brands/user/' + ID) : this.http.get(environment.apiBaseUrl + '/brands');
  }

  getGenders(ID) {
    return ID ? this.http.get(environment.apiBaseUrl + '/genders/user/' + ID) : this.http.get(environment.apiBaseUrl + '/genders' );
  }

  getEthnicities() {
    return this.http.get(environment.apiBaseUrl + '/ethnicities');
  }

  getSkinTypes(ID) {
    return ID ? this.http.get(environment.apiBaseUrl + '/skin_types/user/' + ID) : this.http.get(environment.apiBaseUrl + '/skin_types/');
  }

  addNewCase(newCase) {
    return this.http.post(environment.apiBaseUrl + '/cases', newCase);
  }


  getPreSignedUrl(file, userId) {
    const query = '?fileName=' + file.name;
    return this.http.get(environment.apiBaseUrl + '/users/' + userId + '/s3' + query);
  }

  watermarkImage(croppedKey, watermarkKey, userId) {
    const params = {
      cropped_key: croppedKey,
      watermark_key: watermarkKey,
    };
    return this.http.post(environment.apiBaseUrl + '/users/' + userId + '/s3/watermark', params);
  }

  uploadFile(url, file) {
    const httpOptions = {
      headers: new HttpHeaders({
         'Content-Type':  file.type,
      })
    };
    return this.http.put(url, file, httpOptions);
  }

  resizerImage(userId, params) {
    return this.http.post(environment.apiBaseUrl + '/users/' + userId + '/s3/transform_images',  params);
  }

  uploadBase64(url, file) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  file.type,
      })
    };
    return this.http.put(url, file.buffer, httpOptions);
  }

  deleteImages(images, userId) {
    return this.http.post(environment.apiBaseUrl + '/users/' + userId + '/s3/delete_images', { images });
  }
  updatePrivacy(caseId, is_public) {
    return this.http.put(environment.apiBaseUrl + '/cases/update_privacy?id=' + caseId, {is_public});
  }
  addNewTreatments(case_image) {
    return  this.http.post(environment.apiBaseUrl + '/case_images', { case_image });
  }
  deleteTreatment(caseId, case_images_to_delete) {
    return this.http.put(environment.apiBaseUrl + '/case_images/delete_images/' + caseId, { case_images_to_delete });
  }
  updateTreatment(caseId, case_image) {
    return this.http.patch(environment.apiBaseUrl + '/case_images/' + caseId, { case_image });
  }
  addCaseBrand(case_id, case_brands) {
    return this.http.put(environment.apiBaseUrl + '/case_brands/add_new_brand?case_id=' + case_id, { case_brands });
  }
  deleteCaseBrand(case_id, case_brands) {
    return this.http.put(environment.apiBaseUrl + '/case_brands/remove_brand?case_id=' + case_id, { case_brands });
  }
  addCaseTreatment(case_id, case_treatments) {
    return this.http.put(environment.apiBaseUrl + '/case_treatments/add_new_treatment?case_id=' + case_id, {case_treatments});
  }
  deleteCaseTreatment(case_id, case_treatments) {
    return this.http.put(environment.apiBaseUrl + '/case_treatments/remove_treatment?case_id=' + case_id, {case_treatments});
  }
  updateCaseDetails(edit_case) {
    return this.http.put(environment.apiBaseUrl + '/cases/' + edit_case.id, edit_case);
  }
  deleteCase(caseId) {
    return this.http.delete(environment.apiBaseUrl + '/cases/' + caseId);
  }
  updateProfileVisists(userId) {
    return this.http.get(environment.apiBaseUrl + '/users/update_profile_visits?id=' + userId);
  }
}
