<div class="container admin">
  <button class="button save-button" [routerLink]="['/admin/manufacturer']">Add manufacturer</button>
  <table class="table">
    <thead class="thead-light">
    <tr>
      <th>Name</th>
      <th>Is deleted</th>
      <th></th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let manufacturer of manufacturers" style="height: 30px">
      <td>
        {{manufacturer.name}}
      </td>
      <td>
        {{manufacturer.is_deleted ? 'YES' : 'NO'}}
      </td>
      <td>
        <i class="fa fa-edit" aria-hidden="true" [routerLink]="['/admin/manufacturer/' + manufacturer.id + '/edit']"></i>
        <i class="fa fa-trash" aria-hidden="true" (click)="deleteManufacturer(manufacturer.id)"></i>
      </td>
    </tr>
    </tbody>
  </table>
</div>
