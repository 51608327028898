export interface INewsModel {
    id: number;
    title: string;
    body: string;
    image: string;
    author: string;
    news_type: string;
    is_showed: boolean;
    news_url: string;
}

export class NewsModel implements INewsModel {
    id: number;
    title: string;
    body: string;
    image: string;
    author: string;
    news_type: string;
    is_showed: boolean;
    news_url: string;
}