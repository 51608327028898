import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import { AdminService } from '../../admin.service';

@Component({
  selector: 'app-create-edit-manufacturer',
  templateUrl: './create-edit-manufacturer.component.html',
  styleUrls: ['../../admin.component.scss']
})
export class CreateEditManufacturerComponent implements OnInit {

  private manufacturerId;
  public manufacturer;

  constructor(private router: Router, private adminService: AdminService,
              private toastr: ToastrService, private location: Location,
              private activeRouter: ActivatedRoute) {
    this.activeRouter.params.subscribe(params => {
      this.manufacturerId = params['id'];
    });
  }

  ngOnInit() {
    if (this.manufacturerId) {
      this.getManufacturer();
    } else {
      this.manufacturer = {
        name: '',
        is_deleted: false
      };
    }
  }

  getManufacturer() {
    this.adminService.getManufacturer(this.manufacturerId).subscribe(
      data => {
        this.manufacturer = data;
      }, err => {
        this.toastr.error('Oh... something went wrong');
      }
    );
  }

  saveManufacturer() {
    this.adminService.createManufacturer(this.manufacturer).subscribe(
      data => {
        this.toastr.success('Successfully created');
        this.router.navigate(['/admin/manufacturers']);
      }, err => {
        this.toastr.error('Could not create manufacturer');
      }
    );
  }

  updateManufacturer() {
    this.adminService.updateManufacturer(this.manufacturer).subscribe(
      data => {
        this.toastr.success('Successfully updated');
        this.router.navigate(['/admin/manufacturers']);
      }, err => {
        this.toastr.error('Could not update manufacturer');
      }
    );
  }

  editManufacturer() {
    if (this.manufacturerId) {
      this.updateManufacturer();
    } else {
      this.saveManufacturer();
    }
  }

  goBack() {
    this.location.back();
  }
}
