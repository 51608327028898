import { Component, OnInit } from '@angular/core';
import {Router, ActivatedRoute, NavigationEnd} from '@angular/router';
declare let ga: any;

@Component({
  selector: 'app-feed',
  templateUrl: './feed.component.html',
  styleUrls: []
})

export class FeedComponent implements OnInit {
  id = null;
  constructor(private route: ActivatedRoute, private router: Router) {
    this.router.events.subscribe(event => {
      if (this.router.routerState.root.firstChild
          && this.router.routerState.root.firstChild.firstChild) {
        this.id = this.router.routerState.root.firstChild.firstChild.snapshot.params.id;
      }
      if (event instanceof NavigationEnd) {
        ga('set', 'page', event['urlAfterRedirects']);
        ga('send', 'pageview');
      }
    });

  }

  ngOnInit() {

  }

}
