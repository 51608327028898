<div class="container add-news-box">
  <div class="seven columns">
    <div class="form-group">
      <label>Banner image url</label>
      <input type="text" class="u-full-width" placeholder="Banner image url" [(ngModel)]="bannerImageUrl">
    </div>
    <div class="form-group">
      <label>Banner link</label>
      <input type="text" class="u-full-width" placeholder="Banner link" [(ngModel)]="bannerLink">
    </div>
    <button type="submit" class="button save-button" (click)="saveBanner()">Save</button>
  </div>

      <table class="table">
        <thead class="thead-light">
        <tr>
          <th>Banner image url</th>
          <th>Banner link</th>
          <th>Delete</th>
          <th></th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let banner of banners" style="height: 100px">
          <td class="custom-table-row">{{banner.image_url}}</td>
          <td class="custom-table-row">{{banner.link}}</td>
          <td>
            <i class="fa fa-trash custom-buttons" aria-hidden="true" mwlConfirmationPopover popoverTitle="<h6>Delete banner</h6>" placement="top"
               (confirm)="deleteBanner(banner.id)"></i>
          </td>
        </tr>
        </tbody>
      </table>
</div>
