<app-navbar></app-navbar>

<div class="container">
  <div class="row hero">
    <div class="five columns">
      <h2>Contact us</h2>
      <p class="lead">We would like to hear form you.
      </p>
      <form novalidate [formGroup]="form" (ngSubmit)="sendMessage()">
        <div class="row">
          <div class="six columns">
            <label for="name">Name</label>
            <input class="u-full-width" placeholder="Your name" id="name" type="text" formControlName="formName">
            <div class="form-control-feedback">
              <p *ngIf="form.hasError('required', ['formName']) && formSubmit" class="error">Name is required</p>
            </div>
          </div>
          <div class="six columns">
            <label for="phone">Phone</label>
            <input class="u-full-width" placeholder="Phone number" id="phone" type="tel" formControlName="formPhone">
          </div>
        </div>
        <div class="row">
          <div class="twelve columns">
            <label for="email">Email Address</label>
            <input class="u-full-width" placeholder="Email" id="email" type="email" formControlName="formEmail">
            <div class="form-control-feedback">
              <p *ngIf="form.hasError('required', ['formEmail']) && formSubmit" class="error">Email is required</p>
              <p *ngIf="form.hasError('pattern', ['formEmail']) && formSubmit" class="error">Email format is not valid</p>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="twelve columns">
            <label for="text">Message</label>
            <textarea class="u-full-width" placeholder="Write something.." id="text" formControlName="formMessage"></textarea>
            <div class="form-control-feedback">
              <p *ngIf="form.hasError('required', ['formMessage']) && formSubmit" class="error">Message is required</p>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="twelve columns">
            <input class="button-primary" value="Send" type="submit">
          </div>
        </div>
      </form>
    </div>
    <div class="seven columns">
        <img src="../../../assets/images/map.png" alt="Map of our location" class="u-pull-right u-max-full-width" />
    </div>
  </div>
</div>
<app-footer></app-footer>
