import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {AdminService} from '../../admin.service';
import {ToastrService} from 'ngx-toastr';
import {Location} from '@angular/common';

@Component({
  selector: 'app-edit-treatment',
  templateUrl: './edit-treatment.component.html',
  styleUrls: ['../../admin.component.scss']
})
export class EditTreatmentComponent implements OnInit {
  private treatmentId;
  public treatment;

  constructor(private activeRouter: ActivatedRoute, private _adminService: AdminService,
              private toastr: ToastrService, private location: Location,
              private router: Router) {
    this.activeRouter.params.subscribe(params => {
      this.treatmentId = params['id'];
    });
  }

  ngOnInit() {
    if (this.treatmentId) {
      this.getTreatment();
    } else {
      this.treatment = {
        name: '',
        is_deleted: false,
      };
    }
  }

  getTreatment() {
    this._adminService.getTreatment(this.treatmentId).subscribe(
      data => {
        this.treatment = data;
      },
      error => {
        this.toastr.error('Unable to load treatment.');
      }
    );
  }

  updateTreatment() {
    if (!this.treatment.is_deleted) {
      this.treatment.is_deleted = false;
    }
    this._adminService.updateTreatment(this.treatmentId, this.treatment).subscribe(
      data => {
        this.router.navigate(['admin/treatments']);
        this.toastr.success('Treatment updated');
      },
      error => {
        this.toastr.error('Error while updating treatment');
      }
    );
  }

  creteTreatment() {
    this._adminService.createTreatment(this.treatment).subscribe(
      data => {
        this.router.navigate(['admin/treatments']);
        this.toastr.success('Treatment successfully created.');
      },
      error => {
        this.toastr.error('Error while creating treatment.');
      }
    );
  }

  editTreatment() {
    if (this.treatmentId) {
      this.updateTreatment();
    } else {
      this.creteTreatment();
    }
  }
  goBack() {
    this.location.back();
  }

}
