<i class="fa fa-chevron-left fa-2x back-button" aria-hidden="true" title="Go Back" (click)="goBack()"></i>

<div class="container" *ngIf="ethnicity">
  <div class="twelve columns">

    <form (ngSubmit)="editEthnicity()" #form="ngForm">
      <div class="form-group">
        <label for="ethnicity-name">Ethnicity name</label>
        <input id="ethnicity-name" name="ethnicity" class="u-full-width" [(ngModel)]="ethnicity.name"/>
      </div>
      <div class="form-group">
        <label for="is-deleted">Is Deleted</label>
        <input id="is-deleted" name="is_deleted" class="u-full-width" [(ngModel)]="ethnicity.is_deleted" type="checkbox"/>
      </div>
      <button type="submit" class="button save-button">Save</button>
    </form>
  </div>
</div>
