import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
import {UserService} from '../../user/user.service';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'app-forgot-password-form',
  templateUrl: './forgot-password-form.component.html',
  styleUrls: ['./forgot-password-form.component.scss']
})
export class ForgotPasswordFormComponent implements OnInit {
  public currentUrl;
  public resetPassToken;
  form: FormGroup;
  public formPassword: FormControl;
  public formConfirmPassword: FormControl;
  public showError = false;
  public validatorError = false;
  public forgotPasswordBody;
  public updating = false;
  public isTokenValid = true;

  constructor(private router: Router, private _userService: UserService, private toastr: ToastrService) {}

  ngOnInit() {
    this.currentUrl = this.router.url;
    this.validateUrl(this.currentUrl);
    this.formPassword = new FormControl('', [
      Validators.required,
      Validators.minLength(6),
      Validators.pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d_$@!^%?*().|\\{}\[\]/#&+-=]{6,}$/)
    ]);
    this.formConfirmPassword = new FormControl('', [
      Validators.required,
      Validators.minLength(6),
      Validators.pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d_$@!^%?*().|\\{}\[\]/#&+-=]{6,}$/),
    ]);
    this.form = new FormGroup({
      formPassword: this.formPassword,
      formConfirmPassword: this.formConfirmPassword
    });
  }

  validateUrl(url) {
    this.resetPassToken = url.split('forgot_password_form/')[1];
    if (this.resetPassToken === undefined) {
      this.router.navigate(['/unauthorized']);
    } else {
      this._userService.checkToken(this.resetPassToken).subscribe(
        data => {
        },
        error => {
          if (error.status === 403) {
            this.isTokenValid = false;
          } else {
            this.router.navigate(['/unauthorized']);
          }
        }
      );
    }
  }
  validateForm() {
    this.showError = this.form.valid;
    return this.form.valid;
  }
  passwordMatchValidator(form: FormGroup) {
    return form.get('formPassword').value === form.get('formConfirmPassword').value;
  }
  resetPassword() {
    if (this.passwordMatchValidator(this.form)) {
      if (this.validateForm()) {
        this.updating = true;
        this.forgotPasswordBody = {
          token: this.resetPassToken,
          password: this.formPassword.value
        }
        this._userService.resetForgottenPassword(this.forgotPasswordBody).subscribe(
          data => {
            this.updating = false;
            this.router.navigate(['/signin']);
            this.toastr.success('Your password has been successfully changed.');
          },
          error => {
            this.updating = false;
            this.toastr.error('Something went wrong');
          }
        )
        this.validatorError = false;
      }
    } else {
      this.validatorError = true;
    }
  }
}
