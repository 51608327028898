import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { AdminService } from '../admin.service';
import { INewsModel, NewsModel } from './news-model';
import { NewsDataService } from './news-data.service';
import { Location } from '@angular/common';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./news.component.scss', '../admin.component.scss'],
})
export class NewsComponent implements OnInit {
  public news;
  public singleNews;
  currentPage = 1;

  constructor(private _newsService: AdminService, private _newsDataService: NewsDataService,
              private toastr: ToastrService) {}


  ngOnInit() {
    this.news = [];
    this.getAllNews();
    this._newsDataService.editDataNews.subscribe(singleNews => this.singleNews = singleNews);
  }

  editButton(newsToEdit) {
    this._newsDataService.changeNews(newsToEdit);
  }

  getAllNews() {
    this._newsService.getNews(this.currentPage).subscribe(
      data => {
        this.news = this.news.concat(data);
        },
      err => {
        this.toastr.error('Error while retrieving news');
      }
    );
  }

  deleteSingleNews(singleNews) {
    this._newsService.deleteSingleNews(singleNews.id).subscribe(
      data => {
        this.currentPage = 1;
        this.getAllNews();
        this.toastr.success('News successfully deleted.');
        },
      err => {
        this.toastr.error('Unable to delete news');
      }
    );
  }

  onCheckboxChange(singleNews) {
    this._newsService.editNews(singleNews);
  }

  onScroll() {
    this.currentPage++;
    this.getAllNews();
  }
}

@Component({
  selector: 'app-add-news',
  templateUrl: './add-news.component.html',
  styleUrls: ['./news.component.scss']
})
export class AddNewsComponent implements OnInit {

  public newNews: INewsModel;

  constructor(private _newsService: AdminService, private _location: Location,
              private toastr: ToastrService) {}

  ngOnInit() {
    this.newNews = new NewsModel();
  }

  onSelectionChange(isShowed: boolean) {
    this.newNews.is_showed = isShowed;
  }

  saveNews() {
    this._newsService.postNewNews(this.newNews);
    this.toastr.success('News successfully deleted');
  }

  goBack() {
    this._location.back();
  }

  validateForm(form) {
    if (form.valid) {
      this.saveNews();
    }
  }
}

@Component({
  selector: 'app-edit-news',
  templateUrl: './edit-news.component.html',
  styleUrls: ['../admin.component.scss']
})
export class EditNewsComponent implements OnInit {
   public singleNews;

  constructor(private _newsService: AdminService, private _newsDataService: NewsDataService,
              private _location: Location, private toastr: ToastrService) {}

  ngOnInit() {
    this._newsDataService.editDataNews.subscribe(singleNews => this.singleNews = singleNews);
  }

  onSelectionChange(isShowed: boolean) {
    this.singleNews.is_showed = isShowed;
  }

  saveEditedNews(editedNews) {
    this._newsService.editNews(editedNews);
    this.toastr.success('News successfully updated');
  }

  goBack() {
    this._location.back();
  }

  validateForm(form) {
    if (form.valid) {
      this.saveEditedNews(this.singleNews);
    }
  }
}

@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['../admin.component.scss']
})
export class BannerComponent implements OnInit {
  public bannerImageUrl;
  public bannerLink;
  public banners;

  constructor(private _adminService: AdminService, private toastr: ToastrService) {}

  ngOnInit() {
    this.getAllBanners();
  }

  getAllBanners() {
    this._adminService.getBanners().subscribe(
      data => {
        this.banners = data;
      },
      error => {
        this.toastr.error('Unable to receive banners');
      }
    );
  }
  saveBanner() {
    const banner = {
      image_url: this.bannerImageUrl,
      link: this.bannerLink,
      is_deleted: false
    };
    this._adminService.addBanner(banner).subscribe(
      data => {
        this.getAllBanners();
        this.toastr.success('Banner added');
      },
      error => {
        this.toastr.error('Something went wrong')
      }
    );
  }

  deleteBanner(id) {
    this._adminService.deleteBanner(id).subscribe(
      data => {
        this.getAllBanners();
        this.toastr.success('Successfully deleted');
      },
      error => {
        this.toastr.error('Unable to delete');
      }
    );
  }
}
