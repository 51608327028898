import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';

@Injectable()
export class HomePageService {

  constructor(private http: HttpClient) { }

  postContactForm(form) {
    return this.http.post(environment.apiBaseUrl + '/home/contact_form', { form });
  }

}
