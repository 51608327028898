<i class="fa fa-chevron-left fa-2x back-button" aria-hidden="true" title="Go Back" (click)="goBack()"></i>

<div class="container" *ngIf="skinType">
  <div class="twelve columns">

    <form (ngSubmit)="editSkinType()" #form="ngForm">
      <div class="form-group">
        <label for="first-name">Name</label>
        <input id="first-name" name="first_name" class="u-full-width" [(ngModel)]="skinType.name"/>
      </div>
      <div class="form-group">
        <label for="description">Description</label>
        <input id="description" name="description" class="u-full-width" [(ngModel)]="skinType.description"/>
      </div>
      <div class="form-group">
        <label for="color">Color</label>
        <input id="color" name="color" class="u-full-width" [(ngModel)]="skinType.color"/>
      </div>
      <div class="form-group">
        <label for="is-disabled">Is deleted</label>
        <input id="is-disabled" name="is_disabled" class="u-full-width" [(ngModel)]="skinType.is_deleted" type="checkbox"/>
      </div>
      <button type="submit" class="button save-button">Save</button>

    </form>
  </div>

</div>
