<i class="fa fa-chevron-left fa-2x back-button" aria-hidden="true" title="Go Back" (click)="goBack()"></i>

<div class="container" *ngIf="user">
  <div class="twelve columns">

    <form (ngSubmit)="saveUser()">
      <div class="form-group">
        <label for="prefix">Prefix</label>
        <select class="u-full-width custom-placeholder prefix" name="prefix" id="prefix" [(ngModel)]="user.prefix">
          <option value="" selected> </option>
          <option *ngFor="let prefix of prefixes" [value]="prefix">{{prefix}}</option>
        </select>
      </div>
      <div class="form-group">
          <label for="first-name">First name</label>
          <input id="first-name" name="first_name" class="u-full-width" [(ngModel)]="user.first_name"/>
      </div>
        <div class="form-group">
          <label for="last-name">Last name</label>
          <input id="last-name" name="last_name" class="u-full-width" [(ngModel)]="user.last_name"/>
        </div>

      <div class="form-group">
        <label for="company-name">Company name</label>
        <input id="company-name" name="company_name" class="u-full-width" [(ngModel)]="user.company_name"/>
      </div>
      <div class="form-group">
        <label for="address">Address</label>
        <input id="address" name="company_address" class="u-full-width" [(ngModel)]="user.company_address"/>
      </div>

      <div class="form-group">
        <label for="phone">Phone</label>
        <input id="phone" name="company_phone" class="u-full-width" [(ngModel)]="user.company_phone"/>
      </div>
      <div class="form-group">
        <label for="city">City</label>
        <input id="city" name="company_city" class="u-full-width" [(ngModel)]="user.company_city"/>
      </div>
      <div class="form-group">
        <label for="city">Website</label>
        <input id="website" name="website" class="u-full-width" [(ngModel)]="user.web"/>
      </div>
      <div class="form-group">
        <label for="city">Facebook</label>
        <input id="facebook" name="facebook" class="u-full-width" [(ngModel)]="user.facebook"/>
      </div>
      <div class="form-group">
        <label for="city">LinkedIn</label>
        <input id="linkedin" name="linkedin" class="u-full-width" [(ngModel)]="user.linkedin"/>
      </div>
      <div class="form-group">
        <label for="city">Pinterest</label>
        <input id="pinterest" name="pinterest" class="u-full-width" [(ngModel)]="user.pinterest"/>
      </div>
      <div class="form-group">
        <label for="city">Instagram</label>
        <input id="instagram" name="instagram" class="u-full-width" [(ngModel)]="user.instagram"/>
      </div>
      <div class="form-group">
        <label for="city">Twitter</label>
        <input id="twitter" name="twitter" class="u-full-width" [(ngModel)]="user.twitter"/>
      </div>

      <div class="form-group">
        <label for="stripe_customer_id">Stripe ID</label>
        <input id="stripe_customer_id" name="stripe_customer_id" class="u-full-width" [(ngModel)]="user.stripe_customer_id"/>
      </div>

      <div class="form-group">
        <label for="first-name">Email</label>
        <input id="email" name="email" class="u-full-width" [(ngModel)]="user.email"/>
      </div>

      <div class="form-group" [formGroup]="form">
        <label for="first-name">New Password</label>
        <input id="new_password"  formControlName="formNewPassword" placeholder="New Password" name="new_password"  id="new_password" class="u-full-width"/>
        <div class="form-control-feedback"
             *ngIf="(formNewPassword.errors) && (formNewPassword.dirty || formNewPassword.touched)">
          <p *ngIf="formNewPassword.errors.minlength" class="error">New Password must be at least 6 characters long</p>
          <p *ngIf="!formNewPassword.errors.minlength && formNewPassword.errors.pattern" class="error">New Password needs to have at least one uppercase one lowercase character and number</p>
        </div>
      </div>

      <div class="form-group">
        <label for="has-custom-logo">Custom image logo?</label>
        <input id="has-custom-logo" name="has_custom_logo" class="u-full-width" [(ngModel)]="user.custom_logo" type="checkbox"
               (change)="openModal(logo)"/>
      </div>

      <div class="form-group">
        <label for="is-admin">Is Admin</label>
        <input id="is-admin" name="is_admin" class="u-full-width" [(ngModel)]="user.is_admin" type="checkbox"
               (change)="openModal(admin)"/>
      </div>

      <div class="form-group">
        <label for="is-disabled">Is Disabled</label>
        <input id="is-disabled" name="is_disabled" class="u-full-width" [(ngModel)]="user.is_disabled" type="checkbox"
               (change)="openModal(disable)"/>
      </div>
      <button type="submit" class="button save-button">Save</button>

    </form>
  </div>

</div>

<ng-template #admin>
  <div class="modal-body text-center confirm-modal">
    <p>Are you sure you want to change admin status?</p>
    <a class="button button-delete" (click)="closeModal()" >Yes</a>
    <a class="button decline-button" (click)="revertOption('is_admin')" >No</a>
  </div>
</ng-template>

<ng-template #disable>
  <div class="modal-body text-center confirm-modal">
    <p>Are you sure you want to disable/enable this user?</p>
    <a class="button button-delete" (click)="closeModal()" >Yes</a>
    <a class="button decline-button" (click)="revertOption('is_disabled')" >No</a>
  </div>
</ng-template>

<ng-template #logo>
  <div class="modal-body text-center confirm-modal">
    <p>Are you sure you want to disable/enable the custom image logo?</p>
    <a class="button button-delete" (click)="closeModal()" >Yes</a>
    <a class="button decline-button" (click)="revertOption('custom_logo')" >No</a>
  </div>
</ng-template>
