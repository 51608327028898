<div class="container admin">

    <label> <input id="is_disabled" name="user_disabled"   style="margin-right: 10px" type="checkbox" (change)="showDisabled($event)"/>Users disabled</label>

  <button type="button" class="button save-button" [routerLink]="['/admin/user/add']">ADD USER</button>
  <table class="table">
    <thead class="thead-light">
    <tr>
      <th>Email</th>
      <th>First name</th>
      <th>Last name</th>
      <th>Company name</th>
      <th>Address</th>
      <th>Phone</th>
      <th>City</th>
      <th>Is Admin</th>
      <th>Is disabled</th>
      <th></th>
      <th></th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let user of users" style="height: 30px">
      <td>{{user.email}}</td>
      <td>{{user.first_name}}</td>
      <td>{{user.last_name}}</td>
      <td>{{user.company_name}}</td>
      <td>{{user.company_address}}</td>
      <td>{{user.company_phone}}</td>
      <td>{{user.company_city}}</td>
      <td>{{user.is_admin ? 'YES' : 'NO'}}</td>
      <td [ngClass]="user.is_disabled ? 'disabled' : ''">{{user.is_disabled ? 'YES' : 'NO'}}</td>
      <td>
        <i class="fa fa-edit" aria-hidden="true" [routerLink]="['/admin/user/' + user.id + '/edit']"></i>
      </td>
      <td><a [routerLink]="['/admin/user_overall_kpis/', user.provider_profile_url || user.id]">More</a></td>
    </tr>
    </tbody>
  </table>
  <button class="btn save-button" (click)="exportAllUsersToCsv()">Export all users to CSV</button>

</div>
