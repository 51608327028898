import { Component, OnInit } from '@angular/core';
import {UserService} from '../../user/user.service';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {ToastrService} from 'ngx-toastr';
import {Router} from '@angular/router';

@Component({
  selector: 'app-forgot-password-page',
  templateUrl: './forgot-password-page.component.html',
  styleUrls: ['./forgot-password-page.component.scss']
})
export class ForgotPasswordPageComponent implements OnInit {
  public errorContent = 'Invalid email address';
  public showError = false;
  form: FormGroup;
  public formEmail: FormControl;
  public processingRequest = false;

  constructor(private _usersService: UserService, private toastr: ToastrService, private router: Router) { }

  ngOnInit() {
    this.formEmail = new FormControl('', [Validators.required, Validators.pattern('[^ @]*@[^ @]*')]);
    this.form = new FormGroup({
      formEmail: this.formEmail
    });
  }
  validateForm() {
    this.showError = this.form.invalid;
    this.errorContent = this.form.invalid ? 'Invalid email address' : null;
    return this.form.valid;
  }
  sendEmail() {
    if (this.validateForm()) {
      this.processingRequest = true;
      this._usersService.forgotPassword(this.formEmail.value).subscribe(
        data => {
          this.processingRequest = false;
          this.router.navigate(['']);
          this.toastr.success('We have sent you an email with the reset password link.');
        },
        error => {
          this.processingRequest = false;
          this.showError = true;
          this.errorContent = error.error.status;
        }
      );
    }
  }
}
