<app-navbar></app-navbar>
<div>
  <div class="learn-how light">
  <div class="container">
    <div class="separator"></div>
    <div class="v-center">
      <h6>Welcome to HumazeMD!<br/>Join us now for as low as <span class="bold">$149.00 per month. (With one year subscription)</span></h6> 
    </div>
    <div class="separator"></div>
  </div>
</div>


<div class="container">
  <div class="row register">
      <div class="seven columns">
        <div class="loader" [hidden]="!loading">
          <span>Processing payment...</span>
          <div class="animation"></div>
        </div>
        <div class="registration-wrapper">
        <form novalidate (ngSubmit)="isButtonEnabled() && onSignup()" [formGroup]="form">
          <div class="registration-form">
          <p class="create-acc">Create your account</p>
            <div class="row">
            <div class="two columns">
              <label for="prefix">Prefix</label>
              <select class="u-full-width custom-placeholder" name="prefix" id="prefix" formControlName="prefix">
                <option value="" selected> </option>
                <option *ngFor="let prefix of prefixes" [value]="prefix">{{prefix}}</option>
              </select>
            </div>

            <div class="five columns">
              <label for="firstName">First name</label>
              <input type="text" formControlName="firstName" id="firstName" name="firstName" class="form-control" placeholder="John" required>
              <div class="form-control-feedback"
                   *ngIf="firstName.errors && (firstName.dirty || firstName.touched)">
                <p *ngIf="form.hasError('required', 'firstName')" class="error">First name is required</p>
              </div>
            </div>
            <div class="five columns">
              <label for="lastName">Last name</label>
              <input type="text" formControlName="lastName" id="lastName" name="lastName" class="form-control" placeholder="Doe" required>
              <div class="form-control-feedback"
                   *ngIf="lastName.errors && (lastName.dirty || lastName.touched)">
                <p *ngIf="form.hasError('required', 'lastName')" class="error">Last name is required</p>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="six columns">
              <label for="email">Email</label>
              <input type="email" formControlName="email" id="email" name="email" class="form-control" placeholder="example@email.com" required>
              <div class="form-control-feedback"
                   *ngIf="email.errors && (email.dirty || email.touched)">
                <p *ngIf="form.hasError('required', 'email')" class="error">Email is required</p>
              </div>
            </div>
            <div class="six columns">
              <label for="password">Password</label>
              <input type="password" formControlName="password" id="password" name="password" placeholder="*********" class="form-control" required>
              <div class="form-control-feedback"
                   *ngIf="password.errors && (password.dirty || password.touched)">
                <p *ngIf="password.errors.minlength" class="error">Password must be at least 6 characters long</p>
                <p *ngIf="form.hasError('required', 'password')" class="error">Password is required</p>
                <p *ngIf="!password.errors.minlength && password.errors.pattern" class="error">Password needs to have at least one uppercase one lowercase character and number</p>
              </div>
            </div>
          </div>
          </div>
        <!--</form>-->
          <div class="form-separator"></div>
          <!--<form>-->

          <div class="registration-form">
            <p class="create-acc">Payment details</p>

            <div class="row">
              <div class="twelve columns">
                <label for="cardNo">Credit card number</label>
                <label class="cards">
                  <img class="card-icons" src="../../../assets/images/visa.png"/>
                  <img class="card-icons" src="../../../assets/images/mastercard.png"/>
                  <img class="card-icons" src="../../../assets/images/amex.png"/>
                  <img class="card-icons" src="../../../assets/images/discover.png"/>
                </label>
                <div class="stripe-bottom">
                  <div type="tel" id="cardNo" name="cardNo" class="form-control"></div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="six columns">
               <label for="cvcCode">Security code</label>
                <div class="stripe-bottom">
                  <div type="tel" id="cvcCode" name="code" class="form-control"></div>
                </div>
              </div>
              <div class="six columns">
                <label for="expDate">Expiration date</label>
                <div class="stripe-bottom">
                  <div type="tel" id="expDate" name="date" class="form-control"></div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="four columns">
                <label for="postalCode">Postal code</label>
                <div class="stripe-bottom">
                  <div type="tel" id="postalCode" name="postalCode" class="form-control"></div>
                </div>
              </div>
              <div class="eight columns">
                <label for="country">Country</label>
                <input type="text" id="country" name="country" class="form-control" placeholder="US" formControlName="country"/>
              </div>
            </div>
            <div class="terms">
              <input type="checkbox" name="terms" value="terms" [ngModelOptions]="{standalone: true}" [(ngModel)]="agreedTerms"/>
              <label class="terms-label" (click)="agree(agreedTerms)">&nbsp;I agree to the</label>
              <span class="terms-and-conditions"><a href="#" routerLink="/terms-of-service">Terms and Conditions</a> & <a href="#" routerLink="/terms-of-use">Terms of Use.</a></span>
            </div>
            <button class="button-primary" type="submit" [disabled]="!isButtonEnabled()">Purchase</button>
          </div>
          </form>
      </div>
    </div>

    <div class="five columns">
      <div class="left-wrapper">
        <div class="row summary-form">
          <div class="twelve columns">
            <span class="bold">Select your subscription plan:</span>
          </div>
        </div>

        <div class="row registration-form">
          <div class="six columns" style="display:flex; align-items:baseline">
            <input type="checkbox" name="monthlySubscription" value="monthlySubscription" [(ngModel)]="monthlySubscription" 
            (click)="checkMontlySubscription($event)" />
            <label class="terms-label" (click)="onMonthlyLabelClick()">&nbsp;Month to month</label>
          </div>
          <div class="six columns">
            <span class="bold">${{monthlyPlan}} per month</span>
          </div>
          
          <div class="ten columns" style="text-align:center">
            <label class="bold">-OR-</label>
          </div>

          <div class="twelve colums" style="width:100%">
              <div class="six columns" style="display:flex; flex-direction: column">
                <div class="annual-subscription-div"> 
                    <input type="checkbox" style="margin:0"  name="annualSubscription" value="annualSubscription"
                    [(ngModel)]="annualSubscription"  (click)="checkAnnualSubscription($event)" />
                    <label class="terms-label" (click)="onAnnualLabelClick()">&nbsp;Annual (12 months)</label>
                </div>
                <div class="subscription-msg">${{savingsText}} annual savings</div>
              </div>
              <div class="six columns">
                <div>
                    <div class="bold"><label>${{annualPlanMonthlyRate}} per month</label></div>
                </div>
                <div class="subscription-msg" style="text-align:left">(${{annualPlan}} billed annually)</div>
              </div>
          </div>
        </div>
        <div class="form-separator"></div>
        <div class="row registration-form">
          <div class="six columns">
            <span class="bold">Total</span>
          </div>
          <div class="six columns">
            <span class="bold">${{total}} {{annualSubscription ? 'annually' : 'per month'}}</span>
          </div>

          <div class="form-group row twelve columns coupon-code">
            <label for="couponCode" class="six columns col-form-label">Promo code</label>
            <div class="six columns">
              <div class="nine columns">
                <input type="text" id="couponCode" (change)="setCouponCode($event)" [value]="couponCode" name="couponCode" class="form-control"
                  placeholder="Coupon code" (keydown)="onEnter()">
              </div>
              <div class="two columns">
                <button type="button" (click)="onCouponSubmit($event)" class="btn btn-coupon-submit">Apply</button>
              </div>
              <div class="twelve columns">
                <p *ngIf="couponCodeMsg" [style.color]="couponCodeMsg === 'Promo code applied' ? '#03c9a9' : '#f42b4f'" class="coupon-msg">
                  {{couponCodeMsg}}
                </p>
              </div>
            </div>
          </div>

        </div>
      </div>

    </div>
  </div>
</div>
</div>
<app-footer></app-footer>