import {Component, HostListener, Inject, Input, OnDestroy, OnInit} from '@angular/core';
import {BsModalService} from 'ngx-bootstrap/modal/bs-modal.service';
import {BsModalRef} from 'ngx-bootstrap/modal/bs-modal-ref.service';
import {SingleFeedModalComponent} from '../cases/feed/single-feed-modal/single-feed-modal.component';
import {CaseService} from '../cases/cases.service';
import {Constants} from '../constants';
import {FilterModel} from '../cases/feed/filter-model';
import {DataService} from '../helpers/data-service';
import {FeedDataService} from '../cases/feed/feed-data.service';
import {AuthService} from '../auth/auth.service';
import {ToastrService} from 'ngx-toastr';
import {ActivatedRoute, NavigationStart, Router} from '@angular/router';
import {DOCUMENT} from '@angular/common';
import {LocalStorageService} from 'ngx-webstorage';
import {CarouselConfig} from 'ngx-bootstrap/carousel';


@Component({
  selector: 'app-embed-page',
  templateUrl: './embed-page.component.html',
  styleUrls: ['./embed-page.component.scss'],
  providers: [
    { provide: CarouselConfig, useValue: { interval: 3000, noPause: true, showIndicators: true } }
  ]
})

export class EmbedPageComponent implements OnInit, OnDestroy {
  private limit = 6;
  cases = [];
  offset = 0;
  sub;
  isTreatmentExpended = false;
  isSkinTypeExpended = false;
  isGenderExpended = false;
  isAgeExpended = false;
  isBrandExpended = false;
  isPrivacyExpended = false;
  isCaseExpended = false;
  skinTypes;
  genders;
  ages;
  brandFilters = [];
  skinType;
  gender;
  age;
  caseId;
  privacy;
  filterModel = new FilterModel();
  isFiltering = false;
  bsModalRef: BsModalRef;
  filterData;
  @Input() treatmentFilters: Array<any> = [];
  @Input() userId;
  @Input() drafts = false;
  allCases = false;
  currentUser;
  public = true;
  private = true;
  openedMenu = [];
  opened = false;
  public filterBox = 'filters-wrapper';
  public caseBox = 'case-box-initial';
  typingTimer;
  doneTypingIntervalMiliseconds = 800;
  embedFilterUserId = '';
  loadFilterOnFirstClick = false;
  public tagsContainer = '';

  constructor(private _caseService: CaseService, private constants: Constants,
    private _modalService: BsModalService, private data: DataService,
    private _feedDataService: FeedDataService, private _authService: AuthService,
    private toastr: ToastrService, private _router: Router,
    @Inject(DOCUMENT) private doc: Document, private localStorage: LocalStorageService,
    private _activatedRoute: ActivatedRoute) {
    this.ages = constants.age;

    this._router.events.subscribe((evt) => {
      if (evt instanceof NavigationStart) {
        if (evt['url'].includes('/edit/') || evt['url'].includes('/create')) {
          this.localStorage.store('firstLoad', true);
        } else if (!evt['url'].includes('/feed/')) {
          this.localStorage.clear('firstLoad');
        }
      }
    });
  }

  @HostListener('window:beforeunload', [])
  beforeUnloadHander() {
    window.scrollTo(0, 0);
  }

  @HostListener('window:scroll', [])
  onWindowScroll() {
    this.filterBox = 'filters-wrapper-fixed-embed';
    this.caseBox = 'case-box';
  }

  openModalWithComponent(caseItem) {
    caseItem.embed = true;
    const initialState = { caseItem };
    this.bsModalRef = this._modalService.show(SingleFeedModalComponent, { initialState });
    this._feedDataService.transferService(this.bsModalRef);
  }
  ngOnInit() {
    this.currentUser = this._authService.getCurrentUser();
    const pathArray = window.location.pathname.split('/');
    if (pathArray.length > 4) {
      this.embedFilterUserId = pathArray[3].replace(/%20/g, '');
      const urlFilterArray = ['manufacturer_name', 'brand_name', 'treatment_name'];
      for (let i = 4; i < pathArray.length; i++) {
        this.filterModel[urlFilterArray[i - 4]] = pathArray[i];
      }
    }

    this._activatedRoute.params.subscribe(params => {
      if (params['id'] != null && params['id'] !== 'filter') {
        this.userId = params['id'];
      } else {
        if (this.embedFilterUserId != null) {
          this.userId = this.embedFilterUserId;
        }
      }

      this.loadData();
    });

    setInterval(
      () => {
        this.cases.forEach((caseItem) => {
          if (caseItem.angleVisible < caseItem.angles.length - 1) {
            caseItem.angleVisible++;
          } else {
            caseItem.angleVisible = 0;
          }
        }

        );
      }
      , 7000);
  }


  activeTabChanged(draftsActive) {
    this.drafts = draftsActive;
    this.offset = 0;
    this.loadData();
  }

  loadData() {
    if (this.userId) {
    const userID = this.userId || false;
    this._caseService.getSkinTypes(userID).subscribe(
      data => { this.skinTypes = data; },
      err => { },
      () => { }
    );
    this._caseService.getGenders(userID).subscribe(
      data => { this.genders = data; },
      err => { },
      () => { }
    );

      this._caseService.getAges(userID).subscribe(
        data => { this.ages = data; },
        err => { },
        () => { }
      );
    }
    this.setHomeFilters();

    this.getCases(this.offset, this.limit, this.allCases);
  }

  setHomeFilters() {
    this.sub = this.data.currentFilters.subscribe(message => this.filterData = message);
    if (this.filterData) {
      if (this.filterData.gender) {
        this.filterModel.gender = this.filterData.gender;
        this.gender = this.filterData.gender;
      }
      if (this.filterData.skinType) {
        this.filterModel.skin_type = this.filterData.skinType;
        this.skinType = this.filterData.skinType;
      }
    }
  }
  ngOnDestroy() {
    this.data.changeFilters(null);
  }

  getCases(page, items, allCases) {
    if (this.userId) {
      this.filterModel.user_id = this.userId;
      this.filterModel.is_draft = this.drafts;
    }
    this._caseService.getCases(page, items, this.filterModel, allCases).subscribe(
      data => {
        this.cases = this.offset === 0 ? data : this.isFiltering ? data : this.cases.concat(data);
        const sortedCases = this.cases.sort((a, b) => new Date(b.published_at).getTime() - new Date(a.published_at).getTime());
        sortedCases.forEach((caseItem) => {
          caseItem.angleVisible = 0;
          caseItem.angles.sort((a, b) => a[0].angle_number - b[0].angle_number);
        });
      },
      err => { },
      () => { }
    );
  }

  onScroll() {
    if (this.localStorage.retrieve('firstLoad')) {
      this.localStorage.clear('firstLoad');
    } else {
      this.isFiltering = false;
      this.offset = this.offset + this.limit;
      this.getCases(this.offset, this.limit, this.allCases);
    }
  }

  expendFilter(filterName) {
    switch (filterName) {
      case 'treatment':
        this.isTreatmentExpended = !this.isTreatmentExpended;
        if (this.isTreatmentExpended) {
          this.isGenderExpended = false;
          this.isAgeExpended = false;
          this.isSkinTypeExpended = false;
          this.isBrandExpended = false;
          this.isPrivacyExpended = false;
          this.isCaseExpended = false;
          this.loadFilterOnFirstClick = true;
        }
        break;
      case 'skin type':
        this.isSkinTypeExpended = !this.isSkinTypeExpended;
        if (this.isSkinTypeExpended) {
          this.isGenderExpended = false;
          this.isAgeExpended = false;
          this.isTreatmentExpended = false;
          this.isBrandExpended = false;
          this.isPrivacyExpended = false;
          this.isCaseExpended = false;
        }
        break;
      case 'gender':
        this.isGenderExpended = !this.isGenderExpended;
        if (this.isGenderExpended) {
          this.isSkinTypeExpended = false;
          this.isAgeExpended = false;
          this.isTreatmentExpended = false;
          this.isBrandExpended = false;
          this.isPrivacyExpended = false;
          this.isCaseExpended = false;
        }
        break;
      case 'age':
        this.isAgeExpended = !this.isAgeExpended;
        if (this.isAgeExpended) {
          this.isSkinTypeExpended = false;
          this.isGenderExpended = false;
          this.isTreatmentExpended = false;
          this.isBrandExpended = false;
          this.isPrivacyExpended = false;
          this.isCaseExpended = false;
        }
        break;
      case 'brand':
        this.isBrandExpended = !this.isBrandExpended;
        if (this.isBrandExpended) {
          this.isSkinTypeExpended = false;
          this.isGenderExpended = false;
          this.isTreatmentExpended = false;
          this.isAgeExpended = false;
          this.isPrivacyExpended = false;
          this.isCaseExpended = false;
          this.loadFilterOnFirstClick = true;
        }
        break;
      case 'privacy':
        this.isPrivacyExpended = !this.isPrivacyExpended;
        if (this.isPrivacyExpended) {
          this.isSkinTypeExpended = false;
          this.isGenderExpended = false;
          this.isTreatmentExpended = false;
          this.isAgeExpended = false;
          this.isBrandExpended = false;
          this.isCaseExpended = false;
        }
        break;
      case 'case':
        this.isCaseExpended = !this.isCaseExpended;
        if (this.isCaseExpended) {
          this.isSkinTypeExpended = false;
          this.isGenderExpended = false;
          this.isTreatmentExpended = false;
          this.isAgeExpended = false;
          this.isBrandExpended = false;
          this.isPrivacyExpended = false;
        }
        break;
      default:
        this.isTreatmentExpended = true;
    }
  }

  filterBy(filterName, value) {
    this.isFiltering = true;
    this.offset = 0;

    switch (filterName) {
      case 'skin type':
        this.skinType = value;
        this.filterModel.skin_type = this.skinType;
        break;
      case 'gender':
        this.gender = this.genders.find(x => x.id === +value);
        this.filterModel.gender = this.gender;
        break;
      case 'age':
        this.age = value;
        this.filterModel.age = this.age;
        break;
      case 'treatment':
        this.filterModel.treatments = this.treatmentFilters.map(t => t.id);
        break;
      case 'brand':
        this.filterModel.brands = this.brandFilters.map(b => b.id);
        break;
      case 'privacy':
        this.filterModel.public = value === 'public';
        this.filterModel.private = value === 'private';
        break;
      case 'case':
        this.filterModel.case_id = this.caseId;
        break;
      default:
        break;
    }
    this.getCases(this.offset, this.limit, this.allCases);
    window.scrollTo(0, 0);

  }

  countChangedHandler(type) {
    this.filterBy(type, null);
  }

  removeTag(type, item) {
    type === 'treatment'
      ? this.treatmentFilters.splice(this.treatmentFilters.indexOf(item), 1)
      : this.brandFilters.splice(this.brandFilters.indexOf(item), 1);

    this.filterBy(type, null);
  }

  isItemOwner(caseItem) {
    return this.currentUser && this.currentUser.id === caseItem.user_id;
  }

  isOwner() {
    return this.currentUser && (this.currentUser.provider_profile_url ? this.currentUser.provider_profile_url === this.userId :
      this.currentUser.id === +this.userId);
  }

  openMenu(id) {
    this.opened = !this.opened;
    this.openedMenu = [];
    this.openedMenu[id] = this.opened;
  }

  showTreatmentInfo(caseItem) {
    if (caseItem.treatments.length > 0) {
      return caseItem.treatments.length > 1 ? 'Combination Treatment' : caseItem.treatments[0].name;
    } else {
      return 'N/A';
    }
  }
  showTreatmentCount(caseItem) {
    let treatmentsCount = 0;
    caseItem.angles.forEach(angle => {
      angle.forEach(treatment => {
        if (treatment.treatment_number > treatmentsCount ) {
          treatmentsCount = treatment.treatment_number;
        }
      });
    });

    return caseItem.angles.length ? `${treatmentsCount} Treatments` : 'N/A';
  }

  showBrandInfo(caseItem) {
    if (caseItem.brands.length > 0) {
      return caseItem.brands.length > 1 ? 'Combination (see details)' : caseItem.brands[0].name;
    } else {
      return 'N/A';
    }
  }


  onCaseChanged(event) {
    this.caseId = event.target.value;
    clearTimeout(this.typingTimer);
    this.typingTimer = setTimeout(() => {
      this.filterBy('case', this.caseId);
    }, this.doneTypingIntervalMiliseconds);
  }

  onCaseEnter(event) {
    this.caseId = event.target.value;
    this.filterBy('case', this.caseId);
  }
  showAllcases() {
    this.filterBy('case', '');
  }

  clearFilters() {
    this.removeTag('treatment', null);
    this.removeTag('brand', null);
    this.filterBy('skin type', null);
    this.filterBy('gender', null);
    this.filterBy('age', null);
  }
}
