import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {AuthService} from '../../auth/auth.service';
import {UserService} from '../user.service';
import {ToastrService} from 'ngx-toastr';
import {UserModel} from '../user-model';
import {BsModalService} from 'ngx-bootstrap/modal/bs-modal.service';
import {CaseService} from '../../cases/cases.service';
import {DataService} from '../../helpers/data-service';
import {Constants} from '../../constants';
import {Router} from '@angular/router';

@Component({
  selector: 'app-user-embed-page',
  templateUrl: './user-embed-page.component.html',
  styleUrls: ['./user-embed-page.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class UserEmbedPageComponent implements OnInit {
  public currentUser;
  public user;
  public treatments;
  public brandsData;
  public treatmentSelection: any = {};
  public manufacturerSelection: any = {};
  public dynEmbedAreaValue = '';
  public entireEmbedAreaValue = '';
  public dynEmbedOptionsDisabled = false;
  public entireEmbedRadioSelected = true;
  public dynEmbedRadioSelected;


  constructor(private _authService: AuthService, private _userService: UserService,
              private _toastr: ToastrService, private _modalService: BsModalService,
              private _caseService: CaseService, private _dataService: DataService,
              private constants: Constants, private _router: Router) {
  }

  ngOnInit() {
    this.currentUser = this._authService.getCurrentUser();
    if (!this.currentUser) {
      this._router.navigate(['/signin/user/embed']);
      return;
    }
    this.user = new UserModel();
    this.getUser();
    const userID = this.currentUser.id;
    this._caseService.getTreatments(userID).subscribe(
      data => {
        this.treatments = data;
      },
      err => this._toastr.error('Error while loading options.'),
      () => {
      }
    );
    this._caseService.getBrands(userID).subscribe(
      data => {
        this.brandsData = data;
      },
      err => this._toastr.error('Error while loading options.'),
      () => {
      }
    );

    this.setEntireEmbedAreaValue();
  }

  setEntireEmbedAreaValue() {
    const entireEmbedURL = `"https://${window.location.host}/embed/${this.currentUser.id}"`;
    this.entireEmbedAreaValue = '<iframe style="width: 100%; height: 100%" src=' + entireEmbedURL + ' frameborder="0">';
  }

  getUser() {
    this._userService.getUserDetails(this.currentUser.id).subscribe(
      data => {
        this.user = data;
      },
      err => this._toastr.error('Error while loading user details.')
    );
  }


  treatmentLabelClick(treatmentOption) {
    this.labelClicked(treatmentOption, this.treatmentSelection);
  }

  manufacturerLabelClick(manufacturerOption) {
    this.labelClicked(manufacturerOption, this.manufacturerSelection);
  }

  labelClicked(selectedOption, selectionObj) {
    if (selectedOption != null) {
      const itemFound = Object.keys(selectionObj).find(e => e === selectedOption.name);
      if (itemFound) {
        selectionObj[selectedOption.name] =  !selectionObj[selectedOption.name];
      } else {
        selectionObj[selectedOption.name] = true;
      }
    }
    this.selectionClicked();
  }

  selectionClicked() {
    let urlPath;
    let urlPathManufacturer = '/ /';
    let urlPathTreatment = '/';

    const selectedManufacturerList = Object.keys(this.manufacturerSelection).filter((item, index) => {
      return this.manufacturerSelection[item];
    });

    const selectedTreatmentList = Object.keys(this.treatmentSelection).filter((item, index) => {
      return this.treatmentSelection[item];
    });

    if (selectedTreatmentList.length === 0 && selectedManufacturerList.length === 0) {
      this.dynEmbedAreaValue = '';
      return;
    }

    if (selectedManufacturerList.length === 0) {
      urlPathManufacturer = urlPathManufacturer + ' ';
    } else {
      urlPathManufacturer = this.getPath(selectedManufacturerList, urlPathManufacturer);
    }

    if (selectedTreatmentList.length === 0) {
      urlPathTreatment = '';
    } else {
      urlPathTreatment = this.getPath(selectedTreatmentList, urlPathTreatment);
    }
    urlPath = urlPathManufacturer + urlPathTreatment;
    const ulr = `"https://${window.location.host}/embed/filter/${this.currentUser.id}${urlPath}"`;
    this.dynEmbedAreaValue = '<iframe style="width: 100%; height: 100%" src=' + ulr + ' frameborder="0">';
  }

  getPath(optionList, path) {
    for (let i = 0; i < optionList.length; i++) {
      if (i === 0) {
        path = path + optionList[i];
      } else {
        path = path + '+' + optionList[i];
      }
    }
    return path;
  }

  entireEmbedSelection() {
    this.dynEmbedAreaValue = '';
    this.clearAllOptions();
    this.dynEmbedOptionsDisabled = true;
    this.entireEmbedRadioSelected = true;
    this.dynEmbedRadioSelected = false;
    this.setEntireEmbedAreaValue();
  }

  dynEmbedSelection() {
    this.dynEmbedOptionsDisabled = false;
    this.entireEmbedRadioSelected = false;
    this.dynEmbedRadioSelected = true;
    this.entireEmbedAreaValue = '';

  }

  clearAllOptions() {
    this.dynEmbedAreaValue = '';
    Object.keys(this.manufacturerSelection).filter((item, index) => {
      this.manufacturerSelection[item] = false;
    });

    Object.keys(this.treatmentSelection).filter((item, index) => {
      this.treatmentSelection[item] = false;
    });
  }
}
