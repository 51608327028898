<div>
  <img #img [src]="safeImgDataUrl" 
  [style.visibility]="imageVisible ? 'visible' : 'hidden'" 
  (load)="imageLoadedInView(img)"
    class="source-image" />
        <canvas  #canvas id="canvas" class="source-image"
            (mousedown)="onMouseDown($event)" (touchstart)="onMouseDown($event)"
            (mousemove)="onMouseMove($event)" (touchmove)="onMouseMove($event)"
            (mouseup)="onMouseUp($event)" (touchend)="onMouseUp($event)"
            (contextmenu)="onRightMouseClick()"style="position: absolute; top: 0; left: 0;">
        </canvas> 
  
    <div class="cropper" [class.rounded]="roundCropper" [style.top.px]="cropper.y1" [style.left.px]="cropper.x1"
    [style.width.px]="cropper.x2 - cropper.x1" [style.height.px]="cropper.y2 - cropper.y1" [style.margin-left]="marginLeft"
    [style.visibility]="cropperVisible ? 'visible' : 'hidden'">
    <div (mousedown)="startMove($event, 'move')" (touchstart)="startMove($event, 'move')" class="move">&nbsp;</div>
    <span class="resize topleft" (mousedown)="startMove($event, 'resize', 'topleft')" (touchstart)="startMove($event, 'resize', 'topleft')"><span
        class="square"></span></span>
    <span class="resize top"><span class="square"></span></span>
    <span class="resize topright" (mousedown)="startMove($event, 'resize', 'topright')" (touchstart)="startMove($event, 'resize', 'topright')"><span
        class="square"></span></span>
    <span class="resize right"><span class="square"></span></span>
    <span class="resize bottomright" (mousedown)="startMove($event, 'resize', 'bottomright')" (touchstart)="startMove($event, 'resize', 'bottomright')"><span
        class="square"></span></span>
    <span class="resize bottom"><span class="square"></span></span>
    <span class="resize bottomleft" (mousedown)="startMove($event, 'resize', 'bottomleft')" (touchstart)="startMove($event, 'resize', 'bottomleft')"><span
        class="square"></span></span>
    <span class="resize left"><span class="square"></span></span>
    <span class="resize-bar top" (mousedown)="startMove($event, 'resize', 'top')" (touchstart)="startMove($event, 'resize', 'top')"></span>
    <span class="resize-bar right" (mousedown)="startMove($event, 'resize', 'right')" (touchstart)="startMove($event, 'resize', 'right')"></span>
    <span class="resize-bar bottom" (mousedown)="startMove($event, 'resize', 'bottom')" (touchstart)="startMove($event, 'resize', 'bottom')"></span>
    <span class="resize-bar left" (mousedown)="startMove($event, 'resize', 'left')" (touchstart)="startMove($event, 'resize', 'left')"></span>
  </div>
</div>

<div class="edit-buttons">
    <div style="display:flex">
        <button (click)="showCropper($event)" id="cropperButton" [ngClass]="cropButtonActive ? 'active' : '' ">
            <i class="fa fa-crop"></i>
        </button>
        <button (click)="hideCropper($event)" id="blackbarButton" [ngClass]="blackbarButtonActive ? 'active' : '' ">
           Black Bars
        </button>
    </div>
    <div style="display:flex">
        <button (click)="undo()" id="undoButton" [ngClass]="undoButtonActive ? 'active' : '' ">
            <i class="fa fa-undo"></i>
        </button>
        <button (click)="saveChanges()" class="save" 
                    [ngClass]="saveButtonActive ? 'active' : '' " 
                    [style.color] = "isUploadButtonDisabled === true ? '#bbb' : '#555'">
            Save Black Bars
        </button>
    </div>
</div>