<app-navbar></app-navbar>

<div class="container">
  <div class="row document-container">
    <h2>Terms of Service</h2>

    <div class="row">
      <div class="twelve columns">
        <p><b>These Terms of Service form part of a contract between HumazeMD, LLC (the “<u>Company</u>”) and the individual,
          corporation, partnership, limited liability company or other business (the “<u>Customer</u>”) who registers to use
          the Company’s website at www.humazemd.com (the “<u>Company Site</u>”) and the related Company software application
          (the “<u>Company App</u>”) by completing and submitting an online Order (as defined below).</b></p>

        <p><b>Every Customer who submits an Order and who clicks on the “submit” button to signify agreement with these
          Terms of Service is a “<u>Customer</u>” for purposes of this Agreement.  The services provided by the Company pursuant
          to the Order, including the functions performed by the Company App, are collectively referred to as the “<u>Services</u>”.</b></p>

        <p><b>The Company Site contains information regarding the Company App.  The Company App facilitates the Customer’s
          organization of “before” and “after” photographs of the Customer’s patients, demonstrating the results of certain medical,
          therapeutic or aesthetic procedures conducted on the person depicted by the Customer.  The individual person who
          submits an online Order describing Customer is a “<u>Customer Representative.</u>”</b></p>

        <p><b>If a Customer Representative submits an online order (as applicable, an “<u>Order</u>”) to purchase a subscription
          for the Company App or for any of the Company’s other services, both the Customer Representative and the Customer
          will be responsible for such Order.  Such Order, together with these Terms of Service, will constitute a contract
          that is binding on the Customer, the Customer Representative and the Company.</b></p>

        <p class="main-second-number">1. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <u>Provisions Applicable to All Orders.</u></p>
        <p class="main-second-number">1.1.&nbsp;&nbsp;&nbsp;&nbsp;<u>Orders.</u>	Each Order submitted by a Customer Representative creates a contract binding on the Customer,
          the Customer Representative and Company, consisting of the Order, the Service Description applicable to the
        Services being purchased and these Terms of Service.</p>

        <p class="main-second-number">1.2.&nbsp;&nbsp;&nbsp;&nbsp;<u>Service Fees.</u> 	Company will provide, and the Customer will purchase and pay for, the Services specified in
          the Order for the fees specified therein (the “<u>Service Fees</u>”).  Customer Representative will be jointly and severally
          liable with the Customer for any such Service Fees and for the Customer’s due performance of its obligations as
          provided in the Order and these Terms of Service.</p>


        <p class="main-second-number">1.3.&nbsp;&nbsp;&nbsp;&nbsp;<u>Fees, Taxes and Payment.</u> Customer will pay to Company the Service Fees in the manner set forth in the Order.
          Company may increase the Service Fees at any time by providing ten (10) days prior written notice thereof to Customer.
          All fees are fully earned when due and non-refundable when paid.  Any amounts payable to Company not paid when due
          will bear interest at the rate of one and one half percent (1.5%) per month or the maximum rate permitted by
          applicable law, whichever is less.  If Company collects any payment due at law or through an attorney at law or
          under advice therefrom or through a collection agency, or if Company prevails in any action to which the Customer
          or the Customer Representative and Company are parties, Customer and Customer Representative will pay all costs
          of collection, arbitration and litigation, including, without limitation, all court costs and Company’s reasonable
        attorneys’ fees.  If any check is returned for insufficient funds Company may impose a processing charge of $35.</p>


        <p class="main-second-number">1.4.&nbsp;&nbsp;&nbsp;&nbsp;<u>Service Termination.</u>	A Customer Representative may terminate an Order for Services at any time but any
        accrued and unpaid Service Fees will be due and payable.</p>


        <p class="main-second-number">1.5. &nbsp;&nbsp;&nbsp;&nbsp; <u>Limited Warranty.</u></p>

        <p class="third-number">1.5.1&nbsp;&nbsp;&nbsp;&nbsp;Company represents and warrants that the Services will be performed (i) in a manner consistent with industry
          standards reasonably applicable to the performance thereof and (ii) at least at the same level of service as
          provided by Company generally to its other Customers for the same services.  Customer will be deemed to have accepted
          such Services unless Customer notifies Company within 30 days after performance of any Services of any breach of
          the foregoing warranties.  Customer’s sole and exclusive remedy, and Company’s sole obligation, for breach of
          the foregoing warranties shall be for Company, at its option, to re-perform the defective Services at no cost to
          Customer, or, in the event of interruptions to the Services caused by a breach of the foregoing warranties, issue
          Customer a credit in an amount equal to the current monthly Service Fees pro rated by the number of hours in which
        the Services have been interrupted.</p>


        <p class="third-number">1.5.2.&nbsp;&nbsp;&nbsp;&nbsp;The foregoing warranties shall not apply to performance issues or defects in the Services (i) caused by
          factors outside of Company’s reasonable control; (ii) that resulted from any actions or inactions of Customer or
          any third parties; or (iii) that resulted from Customer’s equipment or any third-party equipment not within the sole
        control of Company.</p>



        <p class="third-number">1.5.3.&nbsp;&nbsp;&nbsp;&nbsp;EXCEPT AS EXPRESSLY PROVIDED IN THIS SECTION, COMPANY MAKES NO REPRESENTATIONS OR WARRANTIES OF ANY KIND,
          EXPRESS OR IMPLIED, WITH RESPECT TO THE SERVICES OR ANY SOFTWARE PROVIDED UNDER THIS AGREEMENT, INCLUDING, WITHOUT
          LIMITATION, ANY WARRANTY OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE OR NON-INFRINGEMENT OF THIRD-PARTY
          RIGHTS, AND COMPANY HEREBY EXPRESSLY DISCLAIMS THE SAME.  WITHOUT LIMITING THE FOREGOING, ANY THIRD-PARTY SOFTWARE
          PROVIDED TO CUSTOMER HEREUNDER IS PROVIDED “AS IS” WITHOUT ANY CONDITION OR WARRANTY WHATSOEVER.  COMPANY DOES NOT
        WARRANT THAT THE SERVICES WILL BE UNINTERRUPTED, ERROR-FREE OR COMPLETELY SECURE.</p>



        <p class="main-second-number">2. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <u>Provisions Applicable to all Customers.</u></p>



        <p class="main-second-number">2.1.&nbsp;&nbsp;&nbsp;&nbsp;<u>Customer Content.</u>	Customer hereby represents and warrants to Company, and agrees that Customer will ensure
          that:  (a) Customer is the owner or valid licensee of all of the information, documents, pictures, photographs,
          videos, graphic items and other data (of every kind and description) that Customer may process through the Services
          or upload to the Company Website (as applicable, the “<u>Customer Content</u>”), and Customer has secured all necessary
          licenses, consents, privacy waivers, permissions, waivers and releases for the use of the Customer Content and
          each element thereof, including without limitation, all trademarks, logos, photographs, videos, depictions, names
          and likenesses contained therein, without any obligation by Company to pay any fees, residuals, guild payments or
          other compensation of any kind to any individual, partnership, joint venture, corporation, limited liability company,
          trust, unincorporated association or organization, or government or any agency or political subdivision thereof
          (as applicable, a “Person”); (b) Customer’s use, publication and display of the Customer Content will not infringe
          any copyright, patent, trademark, trade secret or other proprietary or intellectual property right of any Person,
          or constitute a defamation, invasion of privacy or violation of any right of publicity or any other right of any
          Person, including, without limitation, any contractual, statutory or common law right or any “moral right” or similar
          right however denominated; (c) Customer will comply with all applicable laws, rules and regulations regarding the
          Customer Content; (d) Customer has used its best efforts to ensure that the Customer Content is and will at all times
          remain free of all computer viruses, worms, trojan horses and other malicious code; and (e) Customer will use the
        Services only for the purpose of promoting the Customer’s own business and not for resale or distribution.</p>

        <p class="main-second-number">2.2.&nbsp;&nbsp;&nbsp;&nbsp;<u>License to Company.</u>	Customer hereby grants to Company a non-exclusive, royalty-free, worldwide right and
          license to do the following to the extent necessary in the performance of Services under an applicable Order or
          with respect to any Customer Content that is posted by Customer on the Company Site:  (a) digitize, convert,
          install, upload, select, arrange, compile, combine, synchronize, use, reproduce, store, process, retrieve, transmit,
          distribute, publish, publicly display, publicly perform and hyperlink the Customer Content; and (b) make archival
          or back-up copies of the Customer Content.  Except for the rights expressly granted above, Company is not acquiring
        any right, title or interest in or to the Customer Content, all of which shall remain solely with Customer.</p>

        <p class="main-second-number">2.3.&nbsp;&nbsp;&nbsp;&nbsp;<u>Acceptable Use Policy.</u>  Customer will:</p>



        <p class="third-number">2.3.1.&nbsp;&nbsp;&nbsp;&nbsp;Utilize the Company Site and the Services only in accordance with this Agreement and any applicable law.</p>



        <p class="third-number">2.3.2.&nbsp;&nbsp;&nbsp;&nbsp;Take care to protect any Customer passwords and take full responsibility for Customer's own, and
        third party, use of any Customer accounts.</p>



        <p class="third-number">2.3.3.&nbsp;&nbsp;&nbsp;&nbsp;Not copy any content from the Company Site or attempt to download or “scrape” the names of
        any persons or companies from the Company Site.</p>



        <p class="third-number">2.3.4.&nbsp;&nbsp;&nbsp;&nbsp;Not use the Company Site or any Services to facilitate the transmission of bulk commercial email or
        spam or for any purpose that is illegal or tortious.</p>



        <p class="third-number">2.3.5.&nbsp;&nbsp;&nbsp;&nbsp;Not publish or post any comment or Customer Content on the Company Site that is racist, derogatory of
          any national or ethnic group or any sexual orientation, false, defamatory or made with wrongful intent,
          dishonest, abusive, discriminatory or any content that the Company, in its sole discretion, determines may put
        the Company in a negative light or may harm the Company’s goodwill or public reputation.</p>



        <p class="third-number">2.3.6.&nbsp;&nbsp;&nbsp;&nbsp;Accurately identify the Customer by name and not attempt to use any name or identifying information
        that is false or an alias.</p>



        <p class="third-number">2.3.7.&nbsp;&nbsp;&nbsp;&nbsp;Not develop, support or use software, devices, scripts, robots or any other means or processes
          (including crawlers, browser plugins and add-ons, or any other technology or manual work) to scrape the Company
        Site or otherwise copy any content found on the Company Site.</p>



        <p class="main-second-number">2.4.&nbsp;&nbsp;&nbsp;&nbsp;<u>Privacy</u></p>



        <p class="third-number">2.4.1.&nbsp;&nbsp;&nbsp;&nbsp;Company will keep confidential Customer’s name, address, telephone number, email address, credit card
          information, password and other non-public information provided by Customer to Company for the purpose of obtaining
          or maintaining the Customer’s account or paying amounts owed to Company (collectively, the “<u>Customer Confidential
          Information</u>”) except that Company will not be obligated to keep confidential any Customer Content that Customer
        posts on the Company Site or authorizes to be published.</p>



        <p class="third-number">2.4.2.&nbsp;&nbsp;&nbsp;&nbsp;Company may use the Customer Confidential Information to administer the Customer’s account, collect fees
          owed by Customer and as reasonably necessary or convenient to facilitate the Services.  Company may not provide the
          Customer Confidential Information to any third party, except for attorneys, accountants, employees and agents
        working on behalf of Company pursuant to this Agreement.</p>


        <p class="third-number">2.4.3.&nbsp;&nbsp;&nbsp;&nbsp;Company may collect information regarding the Customer from sources other than the Customer Confidential
          Information (including data concerning Customer’s usage of the Services and the Company Site) (“<u>Customer Non-Confidential
            Information</u>”).  Company may use the Customer Non-Confidential Information for any purpose and may share Customer
          Non-Confidential Information with any party so long as the Customer Non-Confidential Information cannot be correlated
          with, or linked to, Customer Confidential Information.  The Company Site may use software cookies or web beacons to
          track Customer usage of the Company Site or the Services.  Information collected by Company through such means is
        Customer Non-Confidential Information.</p>


        <p class="third-number">2.4.4.&nbsp;&nbsp;&nbsp;&nbsp;Notwithstanding Section 2.4.1, the following shall not be considered Customer Confidential Information:
          (i) any information that Company can demonstrate by written documentation was within its legitimate possession
          prior to the time of disclosure by Customer; (ii) any information that was in the public domain prior to disclosure
          by Customer to Company as evidenced by documents that were published prior to such disclosure; (iii) any information
          that, after disclosure by the Customer to Company, comes into the public domain through no fault of Company, or (iv)
          any information that is disclosed to Company without restriction by a third party who has legitimate possession thereof
        and the legal right to make such disclosure.</p>

        <p class="main-second-number">3. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <u>Provisions Applicable to Customers and Customer Representatives.</u></p>

        <p class="main-second-number">3.1.&nbsp;&nbsp;&nbsp;&nbsp;<u>Customer Representative.</u>	Each Customer represents and warrants that (a) all of the information provided by Customer
          Representative regarding Customer is correct and current, (b) Customer Representative is legally authorized to act
          on behalf of the applicable Customerfor purposes of this Agreement and any Services, and (c) any information posted
          by Customer Representative with respect to the Customer: (i) complies with all applicable laws, (ii) does not
          violate any duty the Customer has towards any person or entity including, without limitation, rights of intellectual
          property, publicity or privacy or rights or duties under consumer protection, product liability, tort, or contract
        theories; and (iii) complies with all of the applicable requirements of these Terms of Service.</p>


        <p class="main-second-number">3.2.&nbsp;&nbsp;&nbsp;&nbsp;<u>Customers.</u>	Each Customer that is represented by a Customer Representative agrees, represents and warrants to
          Company that the representations and warranties made by the Customer Representative in Section 3.1 are true and
          correct and that the Customer hereby guarantees, and agrees to be jointly and severally liable with the Customer
          Representative, with respect to all acts or omissions taken (or omitted to be taken) by Customer Representative
        on the Customer’s behalf in connection with the Company Website, the Services or these Terms and Conditions.</p>


        <p class="main-second-number">4. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <u>General Provisions Applicable to Customers and Customers.</u></p>


        <p class="main-second-number">4.1.&nbsp;&nbsp;&nbsp;&nbsp;<u>Publicity.</u>	Each Customer and Customer Representative agrees that Company may use Customer's name and logo in presentations,
        marketing materials, customer lists, financial reports and Web site listings of customers.</p>


        <p class="main-second-number">4.2.&nbsp;&nbsp;&nbsp;&nbsp;<u>Limitation of Liability.</u></p>


        <p class="third-number">4.2.1.&nbsp;&nbsp;&nbsp;&nbsp;IN NO EVENT WILL COMPANY’S LIABILITY IN CONNECTION WITH THE SERVICES, ANY SOFTWARE PROVIDED HEREUNDER OR ANY
          ORDER, WHETHER CAUSED BY FAILURE TO DELIVER, NON-PERFORMANCE, DEFECTS, BREACH OF WARRANTY OR OTHERWISE, EXCEED THE
          AGGREGATE SERVICE FEES PAID TO COMPANY BY CUSTOMER DURING THE 6-MONTH PERIOD IMMEDIATELY PRECEDING THE EVENT
        GIVING RISE TO SUCH LIABILITY.</p>

        <p class="third-number">4.2.2.&nbsp;&nbsp;&nbsp;&nbsp;COMPANY CANNOT GUARANTEE CONTINUOUS SERVICE, SERVICE AT ANY PARTICULAR TIME, INTEGRITY OF DATA, INFORMATION
          OR CONTENT STORED OR TRANSMITTED VIA THE INTERNET.  COMPANY WILL NOT BE LIABLE FOR ANY UNAUTHORIZED ACCESS TO, OR
          ANY CORRUPTION, ERASURE, THEFT, DESTRUCTION, ALTERATION OR INADVERTENT DISCLOSURE OF, DATA, INFORMATION OR CONTENT
        TRANSMITTED, RECEIVED OR STORED ON ITS SYSTEM.</p>


        <p class="third-number">4.2.3.&nbsp;&nbsp;&nbsp;&nbsp;EXCEPT AS EXPRESSLY PROVIDED BELOW, NEITHER PARTY SHALL BE LIABLE IN ANY WAY TO THE OTHER PARTY OR ANY OTHER
          PERSON FOR ANY LOST PROFITS OR REVENUES, LOSS OF USE, LOSS OF DATA OR COSTS OF PROCUREMENT OF SUBSTITUTE GOODS,
          LICENSES OR SERVICES OR SIMILAR ECONOMIC LOSS, OR FOR ANY PUNITIVE, INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL
          OR SIMILAR DAMAGES OF ANY NATURE, WHETHER FORESEEABLE OR NOT, UNDER ANY WARRANTY OR OTHER RIGHT HEREUNDER, ARISING
          OUT OF OR IN CONNECTION WITH THE PERFORMANCE OR NON-PERFORMANCE OF ANY ORDER, OR (EXCEPT AS PROVIDED IN SECTION 4.3)
          FOR ANY CLAIM AGAINST THE OTHER PARTY BY A THIRD PARTY, REGARDLESS OF WHETHER IT HAS BEEN ADVISED OF THE POSSIBILITY
        OF SUCH CLAIM OR DAMAGES.</p>


        <p class="third-number">4.2.4.&nbsp;&nbsp;&nbsp;&nbsp;The limitations contained in this Section apply to all causes of action in the aggregate, whether based in contract,
          tort or any other legal theory (including strict liability), other than claims based on fraud or willful misconduct.
          The limitations contained in Section 4.2.3 shall not apply to liability arising on account of Customer’s indemnification
        obligations under Section 4.3.</p>

        <p class="main-second-number">4.3.&nbsp;&nbsp;&nbsp;&nbsp;<u>Indemnification of Company.</u>  Customer shall defend, indemnify and hold harmless Company, its affiliates and their
          respective present, former and future officers, directors, employees and agents, and their respective heirs, legal
          representatives, successors and assigns (collectively the <u>Company Indemnities</u>”), from and against any and all losses,
          damages, costs, liabilities and expenses (including, without limitation, amounts paid in settlement and reasonable
          attorneys’ fees) which any of the Company Indemnities may suffer, incur or sustain resulting from or arising out of
          (i) Customer’s breach of any representation, warranty, or covenant  contained in the Agreement, (ii) the Customer
          Content or any end user’s use of the Customer Content, (iii) violation by Customer or any of its officers, directors,
          employees or agents of any applicable law, (iv) claims or actions of third parties alleging misappropriation of trade
          secrets or infringement of patents, copyrights, trademarks or other intellectual property rights arising from the
          use, display or publication of Customer’s domain names, the Customer Content, or the use of the Services in
          combination with hardware, software or content not provided by Company, (v) claims or actions by third parties
          relating to or arising out of Customer’s use of the Services, and (vi) any damage to Company’s servers or other
        hardware caused by Customer.</p>


        <p class="main-second-number">5. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <u>Miscellaneous.</u></p>


        <p class="main-second-number">5.1.&nbsp;&nbsp;&nbsp;&nbsp;<u>Independent Contractor.</u>  Company and each Customer are independent contractors and nothing contained in
          this Agreement places Company and Customer in the relationship of principal and agent, master and servant,
          partners or joint venturers.  Neither party has, expressly or by implication, or may represent itself as having,
          any authority to make contracts or enter into any agreements in the name of the other party, or to obligate or
        bind the other party in any manner whatsoever.</p>



        <p class="main-second-number">5.2.&nbsp;&nbsp;&nbsp;&nbsp;<u>Governing Law; Jurisdiction.</u>  Any controversy or claim arising out of or relating to this Agreement, the
          formation of this Agreement or the breach of this Agreement, including any claim based upon arising from an alleged
          tort, shall be governed by the substantive laws of the State of Georgia, except that all arbitration and related
          proceedings conducted pursuant to Section 5.3 below, including without limitation confirmation proceedings,
          shall be governed by the Federal Arbitration Act, 9 U.S.C. §§ 1, et. seq.  The United Nations Convention on Contracts
          for the International Sale of Goods does not apply to this Agreement.  ANY SUIT, ACTION OR PROCEEDING CONCERNING
          THIS AGREEMENT THAT IS NOT SUBJECT TO MANDATORY ARBITRATION PURSUANT TO SECTION 5.3 BELOW MUST BE BROUGHT IN A
          GEORGIA STATE OR FEDERAL COURT LOCATED IN FULTON COUNTY, GEORGIA, AND EACH OF THE PARTIES HEREBY IRREVOCABLY
          CONSENTS TO THE EXCLUSIVE JURISDICTION OF SUCH COURTS (AND OF THE APPROPRIATE APPELLATE COURTS THEREFROM) IN
          ANY SUCH SUIT, ACTION OR PROCEEDING AND IRREVOCABLY WAIVES, TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW,
          ANY OBJECTION WHICH IT MAY NOW OR HEREAFTER HAVE TO THE LAYING OF THE VENUE OF ANY SUCH SUIT, ACTION OR
          PROCEEDING IN ANY SUCH COURT OR THAT ANY SUCH SUIT, ACTION OR PROCEEDING WHICH IS BROUGHT IN ANY SUCH COURT HAS
        BEEN BROUGHT IN AN INCONVENIENT FORUM.</p>

        <p class="main-second-number">5.3.&nbsp;&nbsp;&nbsp;&nbsp;<u>Mandatory Arbitration.</u>  Notwithstanding Section 5.2 above, each party agrees that any dispute between the
          parties arising out of this Agreement or in any manner relating to the Services must be submitted by the parties
          to arbitration in accordance with the Commercial Arbitration Rules of the American Arbitration Association, as
          administered by Resolutions Resources Corp. of Atlanta, Georgia (or such other recognized provider of arbitration
          services agreed upon by both parties) before a single arbitrator, appointed in accordance with such rules.  Any
          such dispute shall address only the claims brought by the applicable party and no party may represent a class
          of similarly situated persons.  Any such arbitrator must render a reasoned opinion in writing only where the
          amount in dispute exceeds $100,000. Judgment upon the award may be entered in any court having jurisdiction
          thereof.  Any such arbitration will be held in Atlanta, Georgia.  Any action filed by either party in any court
        in violation of this Section should be dismissed pursuant to this Section.</p>

        <p class="main-second-number">5.4.&nbsp;&nbsp;&nbsp;&nbsp;<u>Headings.</u>  The headings herein are for
        convenience only and are not part of this Agreement.</p>

        <p class="main-second-number">5.5.&nbsp;&nbsp;&nbsp;&nbsp;<u>Entire Agreement; Amendments.</u>  This Agreement, including documents incorporated herein by reference, supersedes
          all prior discussions, negotiations and agreements between the parties with respect to the subject matter hereof,
          and this Agreement constitutes the sole and entire agreement between the parties with respect to the matters covered
          hereby.  In case of a conflict between this Agreement and any Order, confirmation, correspondence or other
          communication of Customer or Company, the terms and conditions of this Agreement shall control.  This Agreement
          may not be modified or amended except by another agreement in writing executed by the parties hereto; provided,
          however, that these Terms of Service may be modified from time to time by Company in its sole discretion, which
        modifications will be effective upon posting to Company Site.</p>

        <p class="main-second-number">5.6.&nbsp;&nbsp;&nbsp;&nbsp;<u>Severability.</u>  All rights and restrictions contained in this Agreement may be exercised and shall be applicable
          and binding only to the extent that they do not violate any applicable laws and are intended to be limited to the
          extent necessary so that they will not render this Agreement illegal, invalid or unenforceable.  If any provision
          or portion of any provision of this Agreement shall be held to be illegal, invalid or unenforceable by a court of
          competent jurisdiction, it is the intention of the parties that the remaining provisions or portions thereof shall
          constitute their agreement with respect to the subject matter hereof, and all such remaining provisions or portions
        thereof shall remain in full force and effect.</p>

        <p class="main-second-number">5.7.&nbsp;&nbsp;&nbsp;&nbsp;<u>Notices.</u>  All notices and demands required or contemplated hereunder by one party to the other shall be in
          writing and shall be deemed to have been duly made and given upon date of delivery if delivered in person or
          by an overnight delivery or postal service, upon receipt if delivered by facsimile the receipt of which is confirmed
          by the recipient, or upon the expiration of five days after the date of posting if mailed by certified mail,
          postage prepaid, to the addresses or facsimile numbers set forth below the parties’ signatures.  Either party may
          change its address or facsimile number for purposes of this Agreement by notice in writing to the other party as
          provided herein.  Company may give written notice to Customer via e-mail to the Customer’s e-mail address as
        maintained in Company’s billing records.</p>

        <p class="main-second-number">5.8.&nbsp;&nbsp;&nbsp;&nbsp;<u>Waiver.</u>  No failure or delay by any party hereto to exercise any right or remedy hereunder shall operate as a
          waiver thereof, nor shall any single or partial exercise of any right or remedy by any party preclude any other
          or further exercise thereof or the exercise of any other right or remedy.  No express waiver or assent by any
          party hereto to any breach of or default in any term or condition of this Agreement shall constitute a waiver of
        or an assent to any succeeding breach of or default in the same or any other term or condition hereof.</p>

        <p class="main-second-number">5.9.&nbsp;&nbsp;&nbsp;&nbsp;<u>Assignment; Successors.</u>  Customer may not assign or transfer this Agreement or any of its rights or obligations
          hereunder, without the prior written consent of Company.  Any attempted assignment in violation of the foregoing
          provision shall be null and void and of no force or effect whatsoever.  Company may assign its rights and obligations
          under this Agreement, and may engage subcontractors or agents in performing its duties and exercising its rights
          hereunder, without the consent of Customer.  This Agreement shall be binding upon and shall inure to the benefit
        of the parties hereto and their respective successors and permitted assigns.</p>

        <p class="main-second-number">5.10.&nbsp;&nbsp;&nbsp;&nbsp;<u>Limitation of Actions.</u>  No action, regardless of form, arising by reason of or in connection with this
        Agreement may be brought by either party more than one year after the cause of action has arisen.</p>

        <p class="main-second-number">5.11.&nbsp;&nbsp;&nbsp;&nbsp;<u>Counterparts.</u>  If this Agreement is signed manually, it may be executed in any number of counterparts,
          each of which shall be deemed an original and all of which together shall constitute one and the same instrument.
          If this Agreement is signed electronically, Company’s records of such execution shall be presumed accurate unless
        proven otherwise.</p>

        <p class="main-second-number">5.12.&nbsp;&nbsp;&nbsp;&nbsp;<u>Force Majeure.</u>  Neither party is liable for any default or delay in the performance of any of its obligations
          under this Agreement (other than failure to make payments when due) if such default or delay is caused, directly
          or indirectly, by forces beyond such party’s reasonable control, including, without limitation, fire, flood, acts
          of God, labor disputes, accidents, acts of war or terrorism, interruptions of transportation or communications,
          supply shortages or the failure of any third party to perform any commitment relative to the production or delivery
        of any equipment or material required for such party to perform its obligations hereunder.</p>

        <p class="main-second-number">5.13.&nbsp;&nbsp;&nbsp;&nbsp;<u>No Third-Party Beneficiaries.</u>  Except as otherwise expressly provided in this Agreement, nothing in this
          Agreement is intended, nor shall anything herein be construed to confer any rights, legal or equitable, in any
        Person other than the parties hereto and their respective successors and permitted assigns.</p>

        <p class="main-second-number">5.14.&nbsp;&nbsp;&nbsp;&nbsp;<u>Government Regulations.</u>  Customer may not export, re-export, transfer or make available, whether directly or
          indirectly, any regulated item or information to anyone outside the United States in connection with this Agreement
          without first complying with all export control laws and regulations which may be imposed by the United States
        government and any country or organization of nations within whose jurisdiction Customer operates or does business.</p>
      </div>
    </div>
  </div>
</div>
<app-footer></app-footer>

