import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router} from '@angular/router';
import { AdminService } from '../../admin.service';
import { ToastrService } from 'ngx-toastr';
import { Location } from '@angular/common';

@Component({
  selector: 'app-create-edit-brand',
  templateUrl: './create-edit-brand.component.html',
  styleUrls: ['../../admin.component.scss']
})
export class CreateEditBrandComponent implements OnInit {

  public brand;
  private brandId;
  public manufacturers;

  constructor(private router: Router, private adminService: AdminService,
              private toastr: ToastrService, private location: Location,
              private activeRouter: ActivatedRoute) {
    this.activeRouter.params.subscribe(params => {
      this.brandId = params['id'];
    });
  }

  ngOnInit() {
    this.getManufacturers();
    if (this.brandId) {
      this.getBrand();
    } else {
      this.brand = {
        name: '',
        is_deleted: false,
        manufacturer_id: 1
      };
    }
  }

  getBrand() {
    this.adminService.getBrand(this.brandId).subscribe(
      data => {
        this.brand = data;
      }, err => {
        this.toastr.error('Oh... something went wrong');
      }
    );
  }

  getManufacturers() {
    this.adminService.getManufacturers().subscribe(
      data => {
        this.manufacturers = data;
      }
    );
  }

  saveBrand() {
    this.adminService.createBrand(this.brand).subscribe(
      data => {
        this.toastr.success('Successfully created');
        this.router.navigate(['/admin/brands']);
      }, err => {
        this.toastr.error('Could not create brand');
      }
    );
  }

  updateBrand() {
    if (!this.brand.is_deleted) {
      this.brand.is_deleted = false;
    }
    this.adminService.updateBrand(this.brand).subscribe(
      data => {
        this.toastr.success('Successfully updated');
        this.router.navigate(['/admin/brands']);
      }, err => {
        this.toastr.error('Could not update brand');
      }
    );
  }

  editBrand() {
    if (this.brandId) {
      this.updateBrand();
    } else {
      this.saveBrand();
    }
  }

  goBack() {
    this.location.back();
  }

}
