import { Component, OnInit } from '@angular/core';
import { LocalStorageService } from 'ngx-webstorage';
import {Router} from '@angular/router';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss']
})
export class AdminComponent implements OnInit {
  public isAdmin;
  constructor(private storage: LocalStorageService, private router: Router) { }

  ngOnInit() {
    this.isAdmin = this.storage.retrieve('isAdmin');
    if (!this.isAdmin) {
      this.router.navigate(['/']);
    }
  }

}
