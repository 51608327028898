import {Component, OnInit, TemplateRef} from '@angular/core';
import {CaseService} from '../../../cases/cases.service';
import {AuthService} from '../../../auth/auth.service';
import {ActivatedRoute, Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {BsModalRef} from 'ngx-bootstrap/modal/bs-modal-ref.service';
import {BsModalService} from 'ngx-bootstrap/modal/bs-modal.service';

@Component({
  selector: 'app-admin-case-details',
  templateUrl: './admin-case-details.component.html',
  styleUrls: ['./admin-case-details.component.scss']
})
export class AdminCaseDetailsComponent implements OnInit {
  public caseItem;
  caseId;
  opened = false;
  modalRef: BsModalRef;
  blob;

  constructor(private _caseService: CaseService, private _authService: AuthService,
              private activeRouter: ActivatedRoute, private toastr: ToastrService,
              private _modalService: BsModalService, private router: Router) {
    this.activeRouter.params.subscribe(params => {
      this.caseId = params['id'];
    });
  }

  ngOnInit() {
    this.getCase();
  }

  getCase() {
    this._caseService.getCase(this.caseId).subscribe(
      data => {
        this.caseItem = data;
      },
      error => {
        this.toastr.error('Error loading case');
      }
    );
  }

  openModal(template: TemplateRef<any>) {
    this.opened = false;
    this.modalRef = this._modalService.show(template, { class: 'modal-sm' });
  }

  decline(): void {
    this.modalRef.hide();
  }

  confirmDelete(id) {
    this._caseService.deleteCase(id).subscribe(
      data => {
        this.modalRef.hide();
        this.router.navigate(['/admin/cases']);
        this.toastr.success('Successfully ' + (this.caseItem.is_deleted ? 'restored' : 'deleted') + ' case.');
      },
      error => {
        this.modalRef.hide();
        this.toastr.error('Unable to ' + (this.caseItem.is_deleted ? 'restore' : 'delete') + ' case.');
      }
    );
  }
}
