import { Component, OnInit } from '@angular/core';
import { AdminService } from '../admin.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-manufacturers',
  templateUrl: './manufacturers.component.html',
  styleUrls: ['../admin.component.scss']
})
export class ManufacturersComponent implements OnInit {
  manufacturers;
  constructor(private adminService: AdminService, private toastr: ToastrService) { }

  ngOnInit() {
    this.getManufacturers();
  }

  getManufacturers() {
    this.adminService.getManufacturers().subscribe(
      data => {
        this.manufacturers = data;
      }
    );
  }

  deleteManufacturer(manufacturerId) {
    const manufacturer = {
      id: manufacturerId,
      is_deleted: true
    };
    this.adminService.updateManufacturer(manufacturer).subscribe(
      data => {
        this.toastr.success('Successfully deleted');
        this.getManufacturers();
      }, err => {
        this.toastr.error('Could not delete brand');
      }
    );
  }

}
