import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { AuthService } from '../auth.service';
import { environment } from '../../../environments/environment';
import { ToastrService } from 'ngx-toastr';
import { Constants } from '../../constants';

@Component({
  selector: "app-signup",
  templateUrl: "./signup.component.html",
  styleUrls: ["./signup.component.scss"]
})
export class SignupComponent implements OnInit {
  form: FormGroup;
  public firstName: FormControl;
  public lastName: FormControl;
  public email: FormControl;
  public password: FormControl;
  public terms: FormControl;
  public country: FormControl;
  private stripe;
  private cardNumber;
  private cardExpiry;
  private cardCvc;
  private postalCode;

  public couponCode = "";
  public couponCodeMsg;
  public couponAmountOff;

  public loading = false;

  public initialSubscriptionPlan;
  public subscriptionAmount;
  public total;

  public annualSubscription = true;
  public monthlySubscription = false;

  public annualSubscriptionAmount = 0;
  public monthlySubscriptionAmount = 0;

  public annualPlan;
  public monthlyPlan;

  public annualPlanMonthlyRate;

  public discountText = "";
  public discountValue;
  
  public savingsText = "";
  public savingsValue;

  public agreedTerms = false;
  public prefixes;
  public prefix: FormControl;
  constructor(
    private authService: AuthService,
    private _fb: FormBuilder,
    public toastr: ToastrService,
    private constants: Constants
  ) {
    this.prefixes = this.constants.prefixes;
  }

  ngOnInit() {
    this.loading = false;
    this.getSubscriptionAmount();
    this.mountStripe();
    this.createFormControls();
    this.createForm();
  }

  mountStripe() {
    const style = {
      base: {
        fontSize: "13px",
        fontWeight: "100",
        fontFamily: "Nunito, sans-serif",
        "::placeholder": {
          color: "#CFD7E0"
        }
      }
    };

    this.stripe = (<any>window).Stripe(environment.stripePublishKey);
    const elements = this.stripe.elements();

    this.cardNumber = elements.create("cardNumber", { style: style });
    this.cardNumber.mount("#cardNo");

    this.cardExpiry = elements.create("cardExpiry", { style: style });
    this.cardExpiry.mount("#expDate");

    this.cardCvc = elements.create("cardCvc", { style: style });
    this.cardCvc.mount("#cvcCode");

    this.postalCode = elements.create("postalCode", { style: style });
    this.postalCode.mount("#postalCode");
  }

  createFormControls() {
    this.firstName = new FormControl("", Validators.required);
    this.lastName = new FormControl("", Validators.required);
    this.email = new FormControl("", [Validators.required]);
    this.password = new FormControl("", [
      Validators.required,
      Validators.minLength(6),
      Validators.pattern(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d_$@!^%?*().|\\{}\[\]/#&+-=]{6,}$/
      )
    ]);
    this.country = new FormControl();
    this.terms = new FormControl();
    this.prefix = new FormControl();
  }

  createForm() {
    this.form = new FormGroup({
      firstName: this.firstName,
      lastName: this.lastName,
      email: this.email,
      password: this.password,
      country: this.country,
      terms: this.terms,
      prefix: this.prefix
    });
  }

  isButtonEnabled() {
    return (
      this.form.valid &&
      this.cardCvc._complete &&
      this.postalCode._complete &&
      this.cardNumber._complete &&
      this.cardExpiry._complete &&
      this.agreedTerms === true
    );
  }

  async onSignup() {
    this.loading = true;
    const user = {
      user: {
        email: this.form.value.email,
        password: this.form.value.password,
        first_name: this.form.value.firstName,
        last_name: this.form.value.lastName,
        country: this.form.value.country,
        prefix: this.form.value.prefix
      },
      stripe_token: "",
      card_id: "",
      coupon_code: null,
      annualSubscription: true
    };
    this.stripe.createToken(this.cardNumber).then(result => {
      if (result.token) {
        user.stripe_token = result.token.id;
        user.card_id = result.token.card.id;
        user.coupon_code = this.couponCode;
        user.annualSubscription = this.annualSubscription;
        this.authService.signupUser(user).then(res => {
          this.loading = false;
        });
      } else if (result.error) {
        this.toastr.error("Something went wrong");
      }
    });
  }
  getSubscriptionAmount() {
    this.authService.getSubsAmount().subscribe(
      data => {

        if (data) {
          this.annualSubscriptionAmount = data["annual_plan_amount"];
          this.monthlySubscriptionAmount = data["monthly_plan_amount"];
          this.annualPlan = this.formatValue(
            data["annual_plan_amount"].toString()
          );
          this.annualPlanMonthlyRate = (data["annual_plan_amount"]/12).toString();
          this.annualPlanMonthlyRate = this.formatValue(this.annualPlanMonthlyRate.toString());
          this.monthlyPlan = this.formatValue(
            data["monthly_plan_amount"].toString()
          );

          this.setSubscription();
          
          this.savingsValue = (this.monthlySubscriptionAmount * 12) - this.annualSubscriptionAmount;
          this.savingsText = (this.savingsValue / 100).toString();

          this.discountValue = (this.savingsValue / (this.monthlySubscriptionAmount * 12)) * 100;
          this.discountText = parseInt(this.discountValue).toString();
        }
      },
      error => {
        this.toastr.error("Unable to get subscription details.");
      }
    );
  }

  setCouponCode(event) {
    this.couponCode = event.target.value;
  }

  onEnter() {
    this.couponCodeMsg = "";
  }
  onCouponSubmit(event) {
    this.couponCode = this.couponCode.trim();

    if (this.couponCode.length > 0) {
        this.authService.getCouponCode(this.couponCode).subscribe(data => {
        this.couponCode = data["coupon_code"];
        this.couponAmountOff = data["amount_off"];

        this.setTotal()
        this.couponCode ? (this.couponCodeMsg = "Promo code applied") :
                           (this.couponCodeMsg = "Promo code not found");
      });
    }
  }

  formatValue(value) {
    return (
      value.substr(0, value.length - 2) + "." + value.substr(value.length - 2)
    );
  }

  agree(agreed) {
    this.agreedTerms = !agreed;
  }

  checkMontlySubscription(event) {
    if (event.target.checked) {
      this.annualSubscription = false;
      this.monthlySubscription = true;
       this.resetCoupon();
      this.setSubscription();
    } else {
      event.preventDefault();
    }
  }

  checkAnnualSubscription(event) {
    if (event.target.checked) {
      this.annualSubscription = true;
      this.monthlySubscription = false;
       this.resetCoupon();
      this.setSubscription();
    } else {
      event.preventDefault();
    }
  }

  onAnnualLabelClick() {
    if(this.monthlySubscription) {
      this.resetCoupon();
    }
    this.annualSubscription = true;
    this.monthlySubscription = false;
    this.setSubscription();
  }

  onMonthlyLabelClick() {
    if(this.annualSubscription) {
      this.resetCoupon();
    }
    this.annualSubscription = false;
    this.monthlySubscription = true;
    this.setSubscription();
  }

  setSubscription() {
    if(this.annualSubscription) {
      this.subscriptionAmount = this.annualSubscriptionAmount;
      this.initialSubscriptionPlan = this.annualSubscriptionAmount.toString();
    } else {
      this.subscriptionAmount = this.monthlySubscriptionAmount;
      this.initialSubscriptionPlan = this.monthlySubscriptionAmount.toString();
    }
    this.setTotal();
  }

  setTotal() {
    this.total = this.couponAmountOff
          ? (this.subscriptionAmount - this.couponAmountOff).toString()
          : this.initialSubscriptionPlan.toString();

    this.total = this.formatValue(this.total);
  }

  resetCoupon() {
    this.couponCode = "";
    this.couponCodeMsg = "";
    this.couponAmountOff = null;
  }


}
