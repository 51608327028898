<div class="sidenav">
  <a [routerLink]="['/']">HumazeMD</a>
  <a [routerLink]="['cases']">Cases</a>
  <a [routerLink]="['news']">News</a>
  <a [routerLink]="['treatments']">Treatments</a>
  <a [routerLink]="['brands']">Brands</a>
  <a [routerLink]="['manufacturers']">Manufacturers</a>
  <a [routerLink]="['genders']">Genders</a>
  <a [routerLink]="['skin-types']">Skin types</a>
  <a [routerLink]="['ethnicities']">Ethnicities</a>
  <a [routerLink]="['users']">Users</a>
  <a [routerLink]="['overall-kpis']">Overall KPIs</a>
</div>

<div class="main">
  <router-outlet></router-outlet>
</div>
