<div [ngClass]="responsiveClass()" id="myTopnav">
  <div>
    <img src="../../assets/images/logo.png" routerLink="/" class="logo" />
  </div>
  <div [hidden]="hideRight">
    <a href="#" routerLink="/" class="active logo-title">Home</a>
    <a *ngIf="showPricingLink" href="#" routerLink="/" [ngxScrollTo]="'#pricing'" [ngxScrollToOffset]="2300">Pricing</a>
    <a *ngIf="showPatientLink" href="#" routerLink="/feed">Patients</a>
    <a id="profile-button" class="menu-button" *ngIf="authService.isAuthenticated()" (click)="showDropdown()">
      <img id="profile-img" class="navbar-profile-img" src="{{imageUrl}}" />{{userFirstName}}
    </a>
    <a class="upload-button" *ngIf="authService.isAuthenticated()" routerLink="/create">
      <i class="fa fa-cloud-upload" aria-hidden="true"></i>
      Upload
    </a>
    <ul id="profile-dropown" class="profile-dropdown-content" *ngIf="profileDropdown"
      (clickOutside)="onClickedOutside($event)" (click)="profileDropdown = !profileDropdown">
      <li routerLink="/admin" class="dropdown-options" *ngIf="currentUser.is_admin">Admin</li>
      <li [routerLink]="['/hm/', providerProfileUrl || currentUser.id]" class="dropdown-options">Profile</li>
      <li class="dropdown-options" (click)="onLogout()">Logout</li>
    </ul>
    <ng-template [ngIf]="!authService.isAuthenticated()">
      <a routerLink="/signin" href="#">Sign in</a>
      <a routerLink="/signup" href="#" class="menu-button"><i class="fa fa-plus-circle"></i>Sign Up</a>
    </ng-template>
    <a style="font-size:15px;" class="hamburger" (click)="menuClicked()">&#9776;</a>
  </div>
</div>
