import {Component, Input, OnInit, OnChanges, ViewChild} from '@angular/core';
import {UserService} from '../../../user/user.service';
import {ToastrService} from 'ngx-toastr';
import {GeneralFeedComponent} from '../general-feed/general-feed.component';
import {Meta, Title} from '@angular/platform-browser';

@Component({
  selector: 'app-visitor-feed',
  templateUrl: './visitor-feed.component.html',
  styleUrls: ['./visitor-feed.component.scss']
})
export class VisitorFeedComponent implements OnInit, OnChanges {
  user;
  casesActive = true;
  aboutActive = false;
  draftsActive = false;
  @Input() userId;
  @ViewChild('feed')
  private feed: GeneralFeedComponent;

  constructor(private _userService: UserService, private _toastr: ToastrService, private titleService: Title,
              private meta: Meta) {}

  ngOnInit() {
    this.loadData();
  }
  ngOnChanges() {
    this.loadData();
  }

  loadData() {
    this.getUser();
  }

  private addMetaData(user) {
    const prefix = user.prefix != null ?  user.prefix : '';
    const userName =  user.first_name + ' ' +  user.last_name + ':';
    const company =  user.company_name;
    const pageTitle = prefix + ' ' + userName + company;
    this.titleService.setTitle(pageTitle);
    const companyCity =  user.company_city != null ?  user.company_city : '';
    const state = user.state != null ?  user.state : '';
    const description = prefix + ' ' + userName + ' at ' + company + ' in ' + companyCity + state;
    this.meta.updateTag({name: 'description', content: description});
  }

  getUser() {
    this._userService.getUserDetails(this.userId).subscribe(
      suc => {
        this.user = suc;
        this.addMetaData(this.user);
      },
      err => this._toastr.error('Error while loading user details.'),
    );
  }

  tabChanged(activeTabs) {
    this.casesActive = activeTabs[0];
    this.aboutActive = activeTabs[1];
    this.draftsActive = activeTabs[2];
    if (this.feed) {
      this.feed.activeTabChanged(this.draftsActive);
    }
  }
}
