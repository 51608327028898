import { Component, OnInit } from '@angular/core';
import {Location} from '@angular/common';
import {ActivatedRoute, Router} from '@angular/router';
import {AdminService} from '../../admin.service';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'app-create-edit-ethnicity',
  templateUrl: './create-edit-ethnicity.component.html',
  styleUrls: ['../../admin.component.scss']
})
export class CreateEditEthnicityComponent implements OnInit {
  private ethnicityId;
  public ethnicity;

  constructor(private activeRouter: ActivatedRoute, private _adminService: AdminService,
              private toastr: ToastrService, private location: Location,
              private router: Router) {
    this.activeRouter.params.subscribe(params => {
      this.ethnicityId = params['id'];
    });
  }

  ngOnInit() {
    if (this.ethnicityId) {
      this.getEthnicity();
    } else {
      this.ethnicity = {
        name: '',
        is_deleted: false
      };
    }
  }

  getEthnicity() {
    this._adminService.getEthnicity(this.ethnicityId).subscribe(
      data => {
        this.ethnicity = data;
      },
      error => {
        this.toastr.error('Unable to load ethnicity');
      }
    );
  }

  createEthnicity() {
    this._adminService.createEthnicity(this.ethnicity).subscribe(
      data => {
        this.router.navigate(['admin/ethnicities']);
        this.toastr.success('Ethnicity successfully created');
      },
      error => {
        this.toastr.error('Unable to create ethnicity');
      }
    );
  }

  updateEthnicity() {
    this._adminService.updateEthnicity(this.ethnicity).subscribe(
      data => {
        this.router.navigate(['admin/ethnicities']);
        this.toastr.success('Ethnicity successfully updated');
      },
      error => {
        this.toastr.error('Unable to update ethnicity');
      }
    );
  }

  editEthnicity() {
    if (this.ethnicityId) {
      this.updateEthnicity();
    } else {
      this.createEthnicity();
    }
  }

  goBack() {
    this.location.back();
  }
}
