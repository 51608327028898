import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-press-page',
  templateUrl: './press-page.component.html',
  styleUrls: []
})
export class PressPageComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
