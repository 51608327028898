import { Component, OnInit } from '@angular/core';
import { AdminService } from '../admin.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-genders',
  templateUrl: './genders.component.html',
  styleUrls: ['../admin.component.scss']
})
export class GendersComponent implements OnInit {

  genders;
  constructor(private adminService: AdminService,
              private toastr: ToastrService) { }

  ngOnInit() {
    this.getGenders();
  }

  getGenders() {
    this.adminService.getGenders().subscribe(
      data => {
        this.genders = data;
      }
    );
  }

  deleteGender(genderId) {
    const gender = {
      id: genderId,
      is_deleted: true
    };
    this.adminService.updateGender(gender).subscribe(
      data => {
        this.toastr.success('Successfully deleted');
        this.getGenders();
      }, err => {
        this.toastr.error('Could not delete gender');
      }
    );
  }

}
