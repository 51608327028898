import {Component, OnInit, Input, OnDestroy, TemplateRef, Inject, HostListener, ViewChild} from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal/bs-modal.service';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { SingleFeedModalComponent } from '../single-feed-modal/single-feed-modal.component';
import { CaseService } from '../../cases.service';
import { Constants } from '../../../constants';
import { FilterModel } from '../filter-model';
import { DataService } from '../../../helpers/data-service';
import { FeedDataService } from '../feed-data.service';
import { AuthService } from '../../../auth/auth.service';
import { ToastrService } from 'ngx-toastr';
import { NavigationStart, Router, ActivatedRoute } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { LocalStorageService } from 'ngx-webstorage';
import { CarouselConfig } from 'ngx-bootstrap/carousel';
import {Meta, Title} from '@angular/platform-browser';
import {UserService} from '../../../user/user.service';


@Component({
  selector: 'app-general-feed',
  templateUrl: './general-feed.component.html',
  styleUrls: ['./general-feed.component.scss'],
  providers: [
    { provide: CarouselConfig, useValue: { interval: 3000, noPause: true, showIndicators: true } }
  ]
})

export class GeneralFeedComponent implements OnInit, OnDestroy {
  private limit = 6;
  cases = [];
  offset = 0;
  sub;
  skinTypes;
  genders;
  ages;
  brandFilters = [];
  clinicFilters = [];
  nameFilters = [];
  skinType;
  gender;
  age;
  caseId;
  privacy;
  clinic;
  name;
  filterModel = new FilterModel();
  isFiltering = false;
  bsModalRef: BsModalRef;
  filterData;
  @Input() treatmentFilters: Array<any> = [];
  @Input() userId;
  @Input() drafts = false;
  @Input() allCases;
  @Input() onAdminPage;
  currentUser;
  public = true;
  private = true;
  openedMenu = [];
  opened = false;
  modalRef: BsModalRef;
  public filterBox = 'filters-wrapper';
  public caseBox = 'case-box-initial';
  public tagsContainer = '';
  typingTimer;
  doneTypingIntervalMilliSeconds = 800;
  loadFilterOnFirstClick = false;
  treatmentRemoved;
  brandRemoved;
  clinicRemoved;
  nameRemoved;
  expandedVars = {
    isTreatmentExpended : false ,
    isSkinTypeExpended : false,
    isGenderExpended : false,
    isAgeExpended : false,
    isBrandExpended : false,
    isPrivacyExpended : false,
    isCaseExpended : false,
    isClinicExpended : false,
    isNameExpended : false
  };

  constructor(private _caseService: CaseService, private constants: Constants,
              private _modalService: BsModalService, private data: DataService,
              private _feedDataService: FeedDataService, private _authService: AuthService,
              private toastr: ToastrService, private _router: Router,
              @Inject(DOCUMENT) private doc: Document, private localStorage: LocalStorageService,
              private _activatedRoute: ActivatedRoute, private titleService: Title,
              private meta: Meta, private _userService: UserService) {
    this.ages = constants.age;

    this._router.events.subscribe((evt) => {
      if (evt instanceof NavigationStart) {
        if (evt['url'].includes('/edit/') || evt['url'].includes('/create')) {
          this.localStorage.store('firstLoad', true);
        } else if (!evt['url'].includes('/feed/')) {
          this.localStorage.clear('firstLoad');
        }
      }
    });
  }

  @HostListener('window:beforeunload', [])
  beforeUnloadHander() {
    window.scrollTo(0, 0);
  }

  @HostListener('window:scroll', [])
  onWindowScroll() {
    const num = document.documentElement.scrollTop;
    if (window.location.pathname !== '/feed') {
      if (num >= 204) {
        if (this.cases.length > 2) {
          this.filterBox = 'filters-wrapper-fixed';
          this.caseBox = 'case-box';
        } else {
          this.filterBox = 'filters-wrapper-fixed-few';
          this.caseBox = 'case-box case-box-few';
        }
      } else {
        this.filterBox = 'filters-wrapper';
        this.caseBox = 'case-box-initial';
      }
    }
    if (window.location.pathname === '/feed') {
      if (num >= 24) {
        if (this.cases.length > 2) {
          this.filterBox = 'filters-wrapper-fixed-search';
          this.caseBox = 'case-box';
        } else {
          this.filterBox = 'filters-wrapper-fixed-search-few';
          this.caseBox = 'case-box case-box-few';
        }
      } else {
        this.filterBox = 'filters-wrapper';
        this.caseBox = 'case-box-initial';
      }
    }

  }
  openModalWithComponent(caseItem, index) {
    if (this._router.url !== '/admin/cases') {
      const initialState = { caseItem };
      this.bsModalRef = this._modalService.show(SingleFeedModalComponent, { initialState });
      this._feedDataService.transferService(this.bsModalRef);
    } else {
      this._router.navigate(['admin/cases/details', caseItem.id]);
    }
  }
  ngOnInit() {
    this.currentUser = this._authService.getCurrentUser();
    if (this.currentUser) {
      const prefix = this.currentUser.prefix != null ? this.currentUser.prefix : '';
      const userName = this.currentUser.first_name + ' ' + this.currentUser.last_name + ':';
      const company = this.currentUser.company_name;
      const pageTitle = prefix + ' ' + userName + company;
      this.titleService.setTitle(pageTitle);
      const companyCity = this.currentUser.company_city != null ? this.currentUser.company_city : '';
      const state = this.currentUser.state != null ? this.currentUser.state : '';
      const description = prefix + ' ' + userName + ' at ' + company + ' in ' + companyCity + state;
      this.meta.updateTag({name: 'description', content: description});
    }

    if (window.location.pathname === '/feed') {
      this.tagsContainer = 'tags-search-page';
    }
    this._activatedRoute.params.subscribe(params => {
      this.userId = params['id'];
      this.loadData();

    });
    this.checkProfileVisits();

    setInterval(
      () => {
        this.cases.forEach((caseItem) => {
          if (caseItem.angleVisible < caseItem.angles.length - 1) {
            caseItem.angleVisible++;
          } else {
            caseItem.angleVisible = 0;
          }
        }

        ); }
      , 7000 );
  }

  openModal(template: TemplateRef<any>, caseId) {
    this.opened = false;
    this.modalRef = this._modalService.show(template, { class: 'modal-sm' });
  }

  decline(): void {
    this.modalRef.hide();
  }

  editCase(caseItem, isModalOpen) {
    window.scrollTo(0, 0);
    this._router.navigate(['/edit', caseItem.id]);
    if (isModalOpen) {
      this.modalRef.hide();
    }
  }

  downloadCase(caseId) {
    this.opened = false;
    this.toastr.success('Zip file is downloading');
    this._caseService.downloadCaseImages(caseId).subscribe(
      data => {
        const blob = new Blob([data], { type: 'application/zip' });
        const url = window.URL.createObjectURL(blob);
        window.open(url);
      },
      err => {
        this.toastr.error('Unable to download the zip file');
      }
    );
  }

  confirmDelete(caseId) {
    this._caseService.deleteCase(caseId).subscribe(
      data => {
        this.toastr.success('Case status updated');
        this.offset = 0;
        this.getCases(this.offset, this.limit, this.allCases);
        this.loadData();
        },
      err => this.toastr.success('Could not delete case'),
      () => {}
    );
    this.modalRef.hide();
  }

  activeTabChanged(draftsActive) {
    this.drafts = draftsActive;
    this.offset = 0;
    this.loadData();
  }

  loadData() {
    const userID = this.userId || false;
    this._caseService.getSkinTypes(userID).subscribe(
      data => { this.skinTypes = data; },
      err => {},
      () => {}
    );
    this._caseService.getGenders(userID).subscribe(
      data => { this.genders = data; },
      err => {},
      () => {}
    );
    if (this.userId) {
      this._caseService.getAges(userID).subscribe(
        data => { this.ages = data; },
        err => { },
        () => { }
        );
      }
    this.setHomeFilters();

    this.getCases(this.offset, this.limit, this.allCases);
  }

  setHomeFilters() {
    this.sub = this.data.currentFilters.subscribe(message => this.filterData = message);
    if (this.filterData) {
      if (this.filterData.gender) {
        this.filterModel.gender = this.filterData.gender;
        this.gender = this.filterData.gender;
      }
      if (this.filterData.skinType) {
        this.filterModel.skin_type = this.filterData.skinType;
        this.skinType = this.filterData.skinType;
      }
    }
  }
  ngOnDestroy() {
    this.data.changeFilters(null);
  }

  getCases(page, items, allCases) {
    if (this.userId) {
      this.filterModel.user_id = this.userId;
      this.filterModel.is_draft = this.drafts;
     }
    this._caseService.getCases(page, items, this.filterModel, allCases).subscribe(
      data => {
        this.cases = this.offset === 0 ? data : this.isFiltering ? data : this.cases.concat(data);
        const sortedCases = this.sortOnFeed(this.cases);
        sortedCases.forEach((caseItem) => {
          caseItem.angleVisible = 0;
          caseItem.angles.sort((a, b) => a[0].angle_number - b[0].angle_number);
        });
      },
      err => {},
      () => {}
    );
  }

  sortOnFeed(cases) {
    if (window.location.pathname === '/feed') {
      return cases.sort((a, b) => new Date(b.published_at).getTime() - new Date(a.published_at).getTime());
    } else if (window.location.pathname !== '/feed' && this.filterModel.is_draft) {
      return cases.sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime());
    } else {
      return cases.sort((a, b) => new Date(b.published_at).getTime() - new Date(a.published_at).getTime());
    }
  }

  onScroll() {
    if (this.localStorage.retrieve('firstLoad')) {
      this.localStorage.clear('firstLoad');
    } else {
      this.isFiltering = false;
      this.offset = this.offset + this.limit;
      this.getCases(this.offset, this.limit, this.allCases);
    }
  }

  expandFilter(filterName, expandedVarName) {
    for (const key in this.expandedVars) {
      key === expandedVarName ? this.expandedVars[key] = true : this.expandedVars[key] = false;
    }

    if (['treatment', 'brand', 'clinic', 'name'].includes(filterName)) {
      this.loadFilterOnFirstClick = true;
    }
  }

  filterby(filterName, value) {
    this.isFiltering = true;
    this.offset = 0;
    switch (filterName) {
      case 'skin type':
        this.skinType = value;
        this.filterModel.skin_type = this.skinType;
        break;
      case 'gender':
        this.gender = this.genders.find(x => x.id === +value);
        this.filterModel.gender = this.gender;
        break;
      case 'age':
        this.age = value;
        this.filterModel.age = this.age;
        break;
      case 'treatment':
        this.filterModel.treatments = this.treatmentFilters.map(t => t.id);
        break;
      case 'brand':
        this.filterModel.brands = this.brandFilters.map(b => b.id);
        break;
      case 'privacy':
        this.privacy = value;
        this.filterModel.public = value === 'public';
        this.filterModel.private = value === 'private';
        this.filterModel.is_draft = value === 'draft' ;
        break;
      case 'case':
        this.filterModel.case_id = this.caseId;
        break;
      case 'clinic':
        this.filterModel.clinics = this.clinicFilters.map(c => c.company_name);
        break;
      case 'name':
        this.filterModel.filter_user_ids = this.nameFilters.map(n => n.id);
        break;
      default:
        break;
    }
  }

  makeFilterBy(filterName, value) {
    this.filterby(filterName, value);
    if (this.onAdminPage) {
      if (value === 'all') {
        this.allCases = true;
        this.filterModel.is_draft = null;
      } else {
        this.allCases = false;
      }
    }
    this.getCases(this.offset, this.limit, this.allCases);
    window.scrollTo(0, 0);

  }

  countChangedHandler(type) {
      this.makeFilterBy(type, null);
  }


  clearFilters() {
    this.filterby('skin type', null);
    this.filterby('gender', null);
    this.filterby('age', null);
    this.filterby('privacy', null);
    this.treatmentFilters = [];
    this.filterby('treatment', null);
    this.brandFilters = [];
    this.filterby('brand', null);
    this.clinicFilters = [];
    this.filterby('clinic', null);
    this.nameFilters = [];
    this.filterby('name', null);
    for (const key in this.expandedVars) {
      this.expandedVars[key] = false;
    }
    if (this.onAdminPage) {
      this.allCases = true;
      this.filterModel.is_draft = null;
    }


    this.getCases(this.offset, this.limit, this.allCases);
    window.scrollTo(0, 0);

  }

  removeTag(type, item) {
    if (type === 'treatment') {
      this.treatmentFilters.splice(this.treatmentFilters.indexOf(item), 1);
      this.treatmentRemoved = item;
    } else if (type === 'brand') {
      this.brandFilters.splice(this.brandFilters.indexOf(item), 1);
      this.brandRemoved = item;
    } else if (type === 'clinic') {
      this.clinicFilters.splice(this.clinicFilters.indexOf(item), 1);
      this.clinicRemoved = item;
    } else {
      this.nameFilters.splice(this.nameFilters.indexOf(item), 1);
      this.nameRemoved = item;
    }
    this.makeFilterBy(type, null);
  }

  isItemOwner(caseItem) {
    return this.currentUser && this.currentUser.id === caseItem.user_id;
  }

  isOwner() {
    return this.currentUser && (this.currentUser.provider_profile_url ? this.currentUser.provider_profile_url === this.userId :
      this.currentUser.id === +this.userId);
  }

  openMenu(id) {
    this.opened = !this.opened;
    this.openedMenu = [];
    this.openedMenu[id] = this.opened;
  }

  showTreatmentInfo(caseItem) {
    if (caseItem.treatments.length > 0) {
      return caseItem.treatments.length > 1 ? 'Combination Treatment' : caseItem.treatments[0].name;
    } else {
      return 'N/A';
    }
  }
  showTreatmentCount(caseItem) {
    let treatmentsCount = 0;
    caseItem.angles.forEach(angle => {
      angle.forEach(treatment => {
        if (treatment.treatment_number > treatmentsCount ) {
          treatmentsCount = treatment.treatment_number;
        }
      });
    });

    return caseItem.angles.length ? `${treatmentsCount} ${treatmentsCount === 1 ? 'Treatment' : 'Treatments' }` : 'N/A';
   }

  showBrandInfo(caseItem) {
    if (caseItem.brands.length > 0) {
      return caseItem.brands.length > 1 ? 'Combination (see details)' : caseItem.brands[0].name;
    } else {
      return 'N/A';
    }
  }

  checkProfileVisits() {
    if (window.location.pathname.includes('/hm')) {
      if (this.currentUser === undefined) {
        this._caseService.updateProfileVisists(this.userId).subscribe(
          data => {
          },
          error => {
          }
        );
      } else if (!this.currentUser.is_admin) {
          if (this.currentUser.provider_profile_url === '' || this.currentUser.provider_profile_url === null) {
            if (this.currentUser.id !== +this.userId) {
              this._caseService.updateProfileVisists(this.userId).subscribe(
                data => {},
                error => {}
              );
            }
          } else {
            if (this.currentUser.provider_profile_url !== this.userId) {
              this._caseService.updateProfileVisists(this.userId).subscribe(
                data => {},
                error => {}
              );
            }
          }
      }
    }
  }
  checkEmbedRequest() {
    this._caseService.updateProfileVisists(this.userId).subscribe(
      data => {
      },
      error => {
      }
    );
  }


  onCaseChanged(event) {
    this.caseId = event.target.value;
    clearTimeout(this.typingTimer);
    this.typingTimer = setTimeout(() => {
      this.makeFilterBy('case', this.caseId);
    }, this.doneTypingIntervalMilliSeconds);
  }

  onCaseEnter(event) {
    this.caseId = event.target.value;
    this.makeFilterBy('case', this.caseId);
  }
  showAllCases() {
    this.makeFilterBy('case', 'all');
  }

  getAltValues(feedCase) {
    return this.data.getCaseAltValues(feedCase);
  }

}
