<div class="treatment-multi-selector">
  <div class="input-field">
    <div [ngClass]="getWrapClass()">
      <ul>
        <li *ngFor="let item of selectedNames" [ngClass]="home ? 'selected-landing' : 'selected'" >
          <span>{{item.first_name + ' ' + item.last_name + ' ( id = ' +item.id +' )' }}</span>
          <a (click)="remove(item)">x</a>
        </li>
        <li class="search-field">
          <input ngClass="landing-height" id="name" type="text" [ngClass]="getClass()"
                 [(ngModel)]=name (click)=getFilteredList() (keyup)="filter()" placeholder="{{placeholder}}" autocomplete="off">
        </li>
      </ul>
    </div>
  </div>
  <div class="suggestions" *ngIf="filteredList.length > 0" (clickOutside)="onClickedOutside($event)">
    <ul *ngFor="let item of filteredList">
      <li>
        <a (click)="select(item)" *ngIf="item.is_visible">{{item.first_name + ' ' + item.last_name + ' ( id = ' +item.id +' )' }}</a>
      </li>
    </ul>
  </div>
</div>
