import { Component, OnInit } from '@angular/core';
import {AdminService} from '../admin.service';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'app-ethnicites',
  templateUrl: './ethnicities.component.html',
  styleUrls: ['../admin.component.scss']
})
export class EthnicitiesComponent implements OnInit {
  public ethnicities;

  constructor(private _adminService: AdminService, private toastr: ToastrService) { }

  ngOnInit() {
    this.getEthnicities();
  }

  getEthnicities() {
    this._adminService.getEthnicities().subscribe(
      data => {
        this.ethnicities = data;
      },
      error => {
        this.toastr.error('Unable to load ethnicities');
      }
    );
  }

  deleteEthnicity(ethnicityId) {
    const ethnicity = {
      id: ethnicityId,
      is_deleted: true
    };
    this._adminService.updateEthnicity(ethnicity).subscribe(
      data => {
        this.toastr.success('Successfully deleted');
        this.getEthnicities();
      },
      error => {
        this.toastr.error('Could not delet ethnicity');
      }
    );
  }
}
