<div class="container admin">
  <button class="button save-button" [routerLink]="['/admin/brand']">Add brand</button>
  <select (change)="onManufacturerChange($event.target.value)">
    <option *ngFor="let manufacturer of manufacturers" [value]="manufacturer.id">{{manufacturer.name}}</option>
  </select>
  <table class="table">
    <thead class="thead-light">
    <tr>
      <th>Name</th>
      <th>Is deleted</th>
      <th></th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let brand of brands" style="height: 30px">
      <td>
        {{brand.name}}
      </td>
      <td>
        {{brand.is_deleted ? 'YES' : 'NO'}}
      </td>
      <td>
        <i class="fa fa-edit" aria-hidden="true" [routerLink]="['/admin/brand/' + brand.id + '/edit']"></i>
        <i class="fa fa-trash" aria-hidden="true" (click)="deleteBrand(brand.id)"></i>
      </td>
    </tr>
    </tbody>
  </table>
</div>
