export class UserModel {
  id: number;
  first_name: string;
  last_name: string;
  email: string;
  company_name: string;
  company_address: string;
  company_phone: string;
  company_city: string;
  facebook: string;
  twitter: string;
  linkedin: string;
  pinterest: string;
  instagram: string;
  web: string;
  image_url: string;
}
