<app-navbar></app-navbar>
<div class="container header">
  <div class="left"><a class="button cancel" [routerLink]="['/hm/', userId]">Cancel</a></div>
  <h2 class="center">Edit Case</h2>
  <div class="right"></div>
</div>

<div class="cropper-area" *ngIf="showCropper">
  <edit-image class="cropper-area2"
                 [imageChangedEvent]="imageChangedEvent"
                 [maintainAspectRatio]="true"
                 [aspectRatio]="1"
                 format="jpg"
                 (imageCropped)="imageCropped($event)"
                 (imageOriginal)="imageOriginal($event)"
                 (disableUploadButton)="disableUploadButton($event)"
                 [isUploadButtonDisabled]="isUploadButtonDisabled"
                 [imageToEdit]='imageToEdit'
                 [pageYOffset]="pageYOffset">
</edit-image>
  <div class="crop-buttons">
    <button (click)="uploadCroppedImage()" class="upload-img-button" [disabled]='isUploadButtonDisabled'>Upload</button>
    <button (click)="cancelUpload()" class="cancel-img-button">Cancel</button>
  </div>
</div>

<div class="loader" *ngIf="updatingCase">
  <span>Updating case...</span>
  <div class="animation"></div>
</div>

<div class="container edit" *ngIf="caseItem">
  <div class="twelve columns">
    <div class="row wrapper">
      <form novalidate [formGroup]="form">
        <div class="case-form">
          <p class="create-case">Case photos</p>
            <div class="row">
              <div class="nav-head">
                <ul class="nav nav-tabs">
                  <li *ngFor="let angle of angles; let tabIndex = index" [ngClass]="{active: tabSelected === tabIndex}"
                    (click)="selectTab(tabIndex)" class="nav-item">
                    <button (click)="removeAngle(tabIndex, angle[0].angleNumber)" class="remove-tab" *ngIf="tabIndex !==0">x</button>
                    <button class="nav-link tab"> Angle {{tabIndex + 1}}</button>
                  </li>
                  <li class="nav-item" *ngIf="angles.length < 5">
                    <button class="nav-link" (click)="addAngle()"><i class="fa fa-plus last"></i><span>Add</span> Angle</button>
                  </li>
                </ul>
              </div>
              <div class="nav-body" *ngFor="let angle of angles; let angleIndex = index">
                <div class="twelve columns upload-section" *ngIf="tabSelected === angleIndex">
                  <ol [ngClass]="getOlClass()">
                    <li *ngFor="let item of angle; let i = index" class="primary-ol-section li-of-ol">
                      <div class="left">
                        <span *ngIf="i > 0">Treatment #
                          <select class="treatment-number-dropdown" #t (change)="chosenNumber(t.value, angleIndex, i)">
                            <option *ngFor="let numb of treatmentNumberDropdown"
                              [selected]="numb.number == item.treatmentName.split(' ')[1]"
                              [value]="numb.number">{{numb.number}}</option>
                          </select>
                        </span>
                        <span class="" *ngIf="i==0">Before</span>
                      </div>
                      <div class="right">
                        <div class="upload-button btn btn-primary" *ngIf="!item.thumbnailUrl && !loading">
                          <span>Upload</span>
                          <input type="file" accept=".jpg,.jpeg,.png" class="upload"
                            (change)="fileChangeEvent($event, i, angleIndex)" (keydown.enter)="$event.preventDefault()"
                            (keydown.space)="$event.preventDefault()" />
                        </div>
                        <div class="loading remove" *ngIf="!item.thumbnailUrl && loading">
                          <div class="animation"></div>
                        </div>
                        <div [ngClass]="setImageIconClass(i, angleIndex)" *ngIf="item.thumbnailUrl">
                          <img class="uploaded-icon" src="{{item.thumbnailUrl}}" />
                          <i class="fa fa-2x fa-cloud-upload add-treatment-link" title="New photo">
                            <div>
                              <input type="file" class="update"  (change)="fileChangeEvent($event, i)" (click)="updatingImage()"
                              (keydown.enter)="$event.preventDefault()" (keydown.space)="$event.preventDefault()" />
                            </div>
                          </i>
                          <i class="fa fa-pencil fa-2x add-treatment-link" (click)="editTreatmentImage(i, item)" title="Edit photo"></i>
                          <i *ngIf="i!==0 && !loading" class="fa fa-2x fa-remove add-treatment-link" (click)="deleteItem(i, angleIndex)" title="Delete treatment"></i>
                            <div class="loading remove" *ngIf="i!==0 && loading">
                              <div class="animation"></div>
                            </div>
                        </div>
                      </div>
                    </li>
                  </ol>
                  <div *ngIf="treatmentItems.length < 21" (click)="addItem(angleIndex)" class="last upload-height">
                    <i class="fa fa-plus"></i><span class="add-treatment-link">Add treatment</span>
                  </div>
                </div>
              </div>
            </div>
        </div>
        <div class="form-control-feedback" *ngIf="validationError">
          <p class="error" style="padding-left:30px;">{{errorMessage}}</p>
        </div>
        <div class="form-separator"></div>

        <div class="case-form details">
          <p class="create-case">Case details</p>
          <div class="row">
            <div class="three columns relative">
              <div class="input-field">
                <p for="gender">Patient Gender:</p>
                <div class="gender-container">
                  <div *ngFor="let gender of genders; let i = index">
                    <div class="relative">
                      <label [attr.for]="'radio'+i">{{ gender.name}}
                        <input type="radio" name="formGender" id="radio{{i}}" formControlName="formGender" (click)="onGenderSelect(gender.name)"
                        value="{{gender.name}}">
                        <span class="checkmark"></span>
                      </label>
                    </div>
                  </div>
                </div>
                <div class="form-control-feedback"
                     *ngIf="formGenderError || (formGender.errors && formGender.touched)">
                  <p *ngIf="form.hasError('required', 'formGender')" class="error">Gender is required</p>
                </div>
              </div>
            </div>
            <div class="three columns relative">
              <div class="input-field">
                <p>Patient Age:</p>
                <div class="age-container">
                  <div *ngFor="let age of ageList; let i = index">
                    <div class="relative">
                      <label [attr.for]="'radioAge'+i">{{age}}
                        <input type="radio" name="formAge" id="radioAge{{i}}" formControlName="formAge" value="{{age}}">
                        <span class="checkmark"></span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-control-feedback" *ngIf="formAgeError || (formAge.errors && formAge.touched)">
                <p *ngIf="form.hasError('required', 'formAge')" class="error">Age is required</p>
              </div>
            </div>
            <div class="three columns relative">
              <div class="input-field">
                <p for="skinType">Skin type:</p>
                <div class="skins-container">
                  <div *ngFor="let skinType of skinTypes ; let i = index" class="skins">
                    <div class="relative">
                      <div class="skin-color" [style.background-color]="skinType.color"></div>
                      <label [attr.for]="'radioskin'+i" class="skin-name">{{skinType.name}}
                        <input type="radio" name="SkinRadio" id="radioskin{{i}}" (click)="selectSkinType(skinType)" value="{{skinType}}" checked="{{skinType.id === caseItem.skin_type_id ? 'checked': '' }}">
                        <span class="checkmark"></span>
                      </label>
                    </div>
                  </div>
                </div>
                <div class="form-control-feedback"
                    *ngIf="skinTypeError">
                  <p class="error">Skin type is required</p>
                </div>
              </div>
          </div>
          <div class="three columns">
            <div class="input-field">
              <label for="treatment">Treatment(s):</label>
              <app-select-treatments id="treatment" [selectedTreatments]="selectedTreatments" (countChanged)="checkTreatmentsLength()"></app-select-treatments>
            </div>
            <div class="form-control-feedback"
                  *ngIf="treatmentListError && selectedTreatments.length < 1">
              <p class="error">Choose at least one treatment</p>
            </div>
            <div class="input-field">
              <label for="brand">Brand(s):</label>
              <app-select-brands id="brand" [selectedBrands]="selectedBrands" [setOtherOnTop]="true" (countChanged)="checkBrandsLength()"></app-select-brands>
            </div>
            <div class="form-control-feedback"
                  *ngIf="brandListError && selectedBrands.length < 1">
              <p class="error">Choose at least one brand</p>
            </div>
          </div>

          </div>
          <div class="row">
            <div class="five columns">
              <label for="description">Description</label>
              <textarea type="text" formControlName="formDescription" name="description" id="description" class="form-control" [(ngModel)]="caseItem.description" maxlength="10000"></textarea>
              <div class="form-control-feedback"
                   *ngIf="formDescriptionError || (formDescription.errors && formDescription.touched)">
                <p *ngIf="form.hasError('required', 'formDescription')" class="error">Description is required</p>
              </div>
            </div>
            <div class="seven columns">
              <label for="notes">Case Notes (Private)</label>
              <textarea type="text" name="notes" id="notes" class="form-control" [ngModelOptions]="{standalone: true}" [(ngModel)]="caseItem.settings_notes" maxlength="10000"></textarea>
            </div>
          </div>
          <div class="terms">
            <input type="checkbox" name="terms" value="terms" [ngModelOptions]="{standalone: true}" [(ngModel)]="agreedTerms"/>
            <label class="terms-label" (click)="agree(agreedTerms)">I agree to the</label>
            <span class="terms-and-conditions"><a href="#" routerLink="/terms-of-service">Terms and Conditions</a> & <a href="#" routerLink="/terms-of-use">Terms of Use.</a>.</span>
          </div>
          <div class="row">
            <div class="four columns submit-button">
              <button class="button" type="submit" *ngIf="!loading" (click)="onSubmit(true)">Save draft
                <i class="fa fa-question-circle" (mouseover)="draftTooltip=true" (mouseout)="draftTooltip=false"></i>
              </button>
              <span class="tooltip-message" *ngIf="draftTooltip">Drafts will not be viewable to Patients and will be saved to account for editing and posting </span>
            </div>
            <div class="four columns submit-button">
              <button class="button-info" type="submit" *ngIf="!loading" (click)="onSubmit(false, false)">Update to private feed only
                <i class="fa fa-question-circle" (mouseover)="privateTooltip=true" (mouseout)="privateTooltip=false"></i>
              </button>
              <span class="tooltip-message" *ngIf="privateTooltip">Private Feed is only viewable on Doctor's logged-in device which can be viewed by patients during consultations </span>
            </div>
            <div class="four columns submit-button">
              <button class="button-info" type="submit" *ngIf="!loading" (click)="onSubmit(false, true)">Update to private & public
                feed <i class="fa fa-question-circle" (mouseover)="publicTooltip=true" (mouseout)="publicTooltip=false"></i>
              </button>
              <span class="tooltip-message" *ngIf="publicTooltip">Public Feed is viewable by "anyone" visiting the HumazMD website (this also posts to the Private Feed which is used
              during consultations)</span>
            </div>
            <div class="twelve columns">
              <div class="loading" *ngIf="loading">
                <span>Uploading...</span>
                <div class="animation"></div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>

<app-footer></app-footer>
