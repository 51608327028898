<div id="myModal" class="feed-modal">
  <div class="case" (scroll)="onScroll($event)">
    <div class="display" [ngClass]="{'toggle': toggleTrans}" [style.background-image]="displayImageUrl">
      <div class="blurred" *ngIf="isBlurActive">
        <img [src]="befActDisplayImage" class="blurred-image"/>
      </div>
      <div *ngIf="isBefAftActive" >
        <ng-container *ngFor="let angleImage of modalImages; let angleImageIndex = index" >
          <div class="comparison" *ngIf="angleIndex === angleImageIndex">
            <div class="comparison-before"
              [style.background-image]="angleImage.length > 0 && angleImage[0].thumbnail_url || ''">
            </div>
            <div class="arrow">
              <i class="fa fa-arrow-right"></i>
            </div>
            <div class="comparison-after"
              [style.background-image]="angleImage.length > 1 && angleImage[angleImage.length-1].thumbnail_url ||
              placeholderImage">
            </div>
          </div>
        </ng-container>
      </div>
      <div class="treatmentno" *ngIf="isBoxHovered">
        {{tooltipText}}
      </div>

      <div class="invisible-box" [ngClass]="{'bef-aft-active': isBefAftActive}" (mouseenter)="selectorMouseEnter()" (mouseleave)="selectorMouseLeave()">
        <div class="angle-up" [ngClass]="{'bef-aft-active-fade': isBefAftActive}" *ngIf="modalImages.length > 1">
          <i class="fa fa-2x fa-angle-up" (click)="angleDown()"></i>
        </div>
        <div class="selector" *ngIf="isSelectorShown">
          <div  *ngFor="let angleImageSelector of modalImages; let angleImageSelectorIndex = index ">
            <div *ngIf="angleSelectorIndex === angleImageSelectorIndex">
              <div class="photo" [ngClass]="{'active': isBefAftActive}" (click)="setBefAftDisplayImage()"
                (mouseenter)="mouseEnter('Before/After')" (mouseleave)="mouseLeave()"
                [style.background-image]="angleImageSelector.length > 0 && angleImageSelector[0].cropped_watermark_image_url || ''">
                <div [ngClass]="{'active': isBefAftActive}" class="after-in-photo"
                  [style.background-image]="angleImageSelector.length > 0 && angleImageSelector[angleImageSelector.length-1].thumbnail_url || ''"></div>
              </div>
              <div class="angle-left" (click)="prevImg(imgIndex, angleImageSelector)" *ngIf="angleImageSelector.length > 5">
                <i class="fa fa-2x fa-angle-left"></i>
              </div>
              <ng-container *ngFor="let img of angleImageSelector; let imgIndex = index ">
                <div class="photo" [ngClass]="{'active': isBoxActive(img)}"
                *ngIf="imgIndex <= maxLimit && imgIndex >= minLimit"
                (click)="setDisplayImage(img.cropped_watermark_image_url, img)" (mouseenter)="mouseEnter(img.treatment_name)"
                (mouseleave)="mouseLeave()"  [style.background-image]="img.thumbnail_url || ''">
              </div>
            </ng-container>
            <div class="angle-right" (click)="nextImg(angleImageSelector)" *ngIf="angleImageSelector.length > 5">
              <i class="fa fa-2x fa-angle-right"></i>
            </div>
            </div>
          </div>
        </div>

        <div class="angle-down" [ngClass]="{'bef-aft-active-fade': isBefAftActive}" *ngIf="modalImages.length > 1">
          <i class="fa fa-2x fa-angle-down" (click)="angleUp()"></i>
        </div>
      </div>
    </div>
    <div class="description">
      <div class="header">
        <div>
          <img class="header-image" src="{{caseItem.user && caseItem.user.image_url ? caseItem.user.image_url : '../../../assets/images/profilePlaceholder.png'}}"/>
        </div>
        <div>
          <h6 class="treatments-name">
            {{caseItem.treatments.length>0 && caseItem.treatments[0].name || 'N/A'}}&nbsp;
            <small *ngIf="caseItem.treatments.length > 1">
              <span [popover]="popTreatment" placement="bottom" *ngIf="caseItem.treatments.length > 0" triggers="mouseenter:mouseleave" class="popover-box">
                +{{caseItem.treatments.length-1}}
              </span>
            </small>
          </h6>
          <div *ngIf="caseItem.user.id === currentUserId" class="privacy-dropdown">
            <a id="privacy-button" class="dropdown-box">
              <i class="fa dropdown-image" [ngClass]="(isCasePublic)?'fa-eye':'fa-eye-slash'"></i>
              <span class="hide-small">{{casePrivacyValue}}</span>
            </a>
          </div>
          <p *ngIf="caseItem.user.id !== currentUserId" class="bold treatments-name">by
            <a href="#" routerLink="/hm/{{caseItem.user.provider_profile_url || caseItem.user.id}}" (click)="closeModal()">
              {{caseItem.user.prefix}} {{caseItem.user.first_name}} {{caseItem.user.last_name}}
            </a>
          </p>
        </div>
        <div *ngIf="caseItem.user.id === currentUserId && !embed" class="edit-button-box">
          <button type="button" class="edit-button" (click)="editCase(caseItem)">Edit</button>
        </div>
        <div class="close-modal" (click)="closeModal()">x</div>
      </div>
      <div class="body">
        <p>{{caseItem.description}}</p>
      </div>
      <div class="details">
        <div class="flex-wrapper">
        <div class="brand">
          <p>{{caseItem.brands.length > 0 && caseItem.brands[0].name || 'N/A'}}&nbsp;
            <small style="display: inline-block" *ngIf="caseItem.brands.length > 1">
              <span [popover]="popBrands" placement="bottom" triggers="mouseenter:mouseleave" class="popover-box">
                +{{caseItem.brands.length - 1}}
              </span>
            </small>
          </p>
          <small>{{showTreatmentCount()}}</small>
        </div>

        <div class="case-number">
          <p>Case number
            <small style="display: inline-block">
              <span placement="bottom" triggers="mouseenter:mouseleave" class="popover-box">
              </span>
            </small>
          </p>

          <small>#{{caseItem.id}}</small>
        </div>
      </div>


        <div class="stats">
          <div>
            <label>Age</label>
            <p>{{caseItem.patient_age || 'N/A'}}</p>
          </div>
          <div>
            <label>Sex</label>
            <p>{{caseItem.gender && caseItem.gender.name || 'N/A'}}</p>
          </div>
          <div>
            <label>Skin Type</label>
            <p><i class="fa fa-square skintype" [style.color]="caseItem.skin_type && caseItem.skin_type.color || 'white'"></i>
              {{caseItem.skin_type && caseItem.skin_type.name || 'N/A'}}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #popTreatment>
  <span *ngFor="let treatment of caseItem.treatments | slice:1; let isLast=last" style="font-size: 12px;">
    {{treatment.name}}{{isLast ? '' : ', '}}
  </span>
</ng-template>

<ng-template #popBrands>
  <span *ngFor="let brand of caseItem.brands | slice: 1; let isLast=last" style="font-size: 12px;">
    {{brand.name}}{{isLast ? '' : ', '}}
  </span>
</ng-template>

