<div class="container">
    <div class="row">
      <div class="login-wrapper">
        <div class="row logo">
          <div class="twelve columns">
            <img src="../../../assets/images/logo.png" />
          </div>
        </div>
        <div class="row">
          <div class="twelve columns">
            <label for="text">To reset your password, please enter old password and new password twice.</label>
          </div>
        </div>
  

        <div class="row">
            <div class="twelve columns">
              <!-- <label for="password">Password</label> -->
              <input class="u-full-width" placeholder="Current Password" id="passwordOld" ngModel type="password" name="passwordOld">
            </div>
          </div>

        <div class="row">
            <div class="twelve columns">
              <!-- <label for="password">Password</label> -->
              <input class="u-full-width" placeholder="New Password" id="passwordNew1" ngModel type="password" name="passwordNew1">
            </div>
          </div>

          <div class="row">
              <div class="twelve columns">
                <!-- <label for="password">Password</label> -->
                <input class="u-full-width" placeholder="New Password Again" id="passwordNew2" ngModel type="password" name="passwordNew2">
              </div>
            </div>
  
        <div class="row">
          <div class="twelve columns">
            <input class="button-primary u-full-width" value="Submit" type="submit">
          </div>
        </div>
      </div>
    </div>
  </div>