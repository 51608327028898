import { Component, OnInit } from '@angular/core';
import { AdminService } from '../admin.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-brands',
  templateUrl: './brands.component.html',
  styleUrls: ['../admin.component.scss']
})
export class BrandsComponent implements OnInit {

  public brands;
  public manufacturers;
  private manufacturerId = 1;
  constructor(private adminService: AdminService, private toastr: ToastrService) { }

  ngOnInit() {
    this.getManufacturers();
    this.getBrands(this.manufacturerId);
  }

  getBrands(id) {
    this.adminService.getBrands(id).subscribe(data => {
      this.brands = data;
    });
  }

  getManufacturers() {
    this.adminService.getManufacturers().subscribe(
      data => {
        this.manufacturers = data;
      }
    );
  }

  onManufacturerChange(manufacturerId) {
    this.getBrands(manufacturerId);
    this.manufacturerId = manufacturerId;
  }

  deleteBrand(id) {
    const brand = {
      id: id,
      is_deleted: true
    };
    this.adminService.updateBrand(brand).subscribe(
      data => {
        this.toastr.success('Successfully deleted');
        this.getBrands(this.manufacturerId);
      }, err => {
        this.toastr.error('Could not delete brand');
      }
    );
  }

}
