import { Component, OnInit } from '@angular/core';
import {environment} from '../../../environments/environment';
import {AuthService} from '../../auth/auth.service';
import {ActivatedRoute, Router} from '@angular/router';
import {UserService} from '../user.service';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'app-change-credit-card',
  templateUrl: './change-credit-card.component.html',
  styleUrls: ['./change-credit-card.component.scss', '../../auth/signup/signup.component.scss']
})
export class ChangeCreditCardComponent implements OnInit {
  private stripe;
  private cardNumber;
  private cardExpiry;
  private cardCvc;
  private postalCode;
  currentUser;
  public updatingCard = false;

  constructor(private _authService: AuthService, private router: Router,
              private userService: UserService, private toastr: ToastrService) {

  }

  ngOnInit() {
    this.currentUser = this._authService.getCurrentUser();
    if (this.currentUser) {
      this.mountStripe();
    } else {
      this.router.navigate(['/signin/change']);
    }
  }

  mountStripe() {
    const style = {
      base: {
        'fontSize': '13px',
        'fontWeight': '100',
        'fontFamily': 'Nunito, sans-serif',
        '::placeholder': {
          color: '#CFD7E0',
        },
      },
    };

    this.stripe = (<any>window).Stripe(environment.stripePublishKey);
    const elements = this.stripe.elements();

    this.cardNumber = elements.create('cardNumber', {style: style});
    this.cardNumber.mount('#cardNo');

    this.cardExpiry = elements.create('cardExpiry', {style: style});
    this.cardExpiry.mount('#expDate');

    this.cardCvc = elements.create('cardCvc', {style: style});
    this.cardCvc.mount('#cvcCode');

    this.postalCode = elements.create('postalCode', {style: style});
    this.postalCode.mount('#postalCode');
  }

  isButtonEnabled() {
    return this.cardCvc._complete && this.postalCode._complete
      && this.cardNumber._complete && this.cardExpiry._complete;
  }

  onChange() {
    this.updatingCard = true;
    let stripe_token;
    let card_tok;
    this.stripe.createToken(this.cardNumber)
      .then(result => {
        if (result.token) {
          stripe_token = result.token.id;
          card_tok = result.token.card.id;
          this.userService.changeCreditCard(this.currentUser.id, stripe_token).subscribe(
            data => {
              this.updatingCard = false;
              this.toastr.success('Card changed successfully');
              this.router.navigate(['/']);
            },
            err => {
              this.updatingCard = false;
              this.toastr.error(err.error.error);
            }
          );
        } else if (result.error) {
          this.updatingCard = false;
          this.toastr.error('Unable to change card.');
        }
      });
  }

}
