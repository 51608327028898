<div class="container admin" *ngIf="userOverallKpis">
  <div class="row">
    <div class="six columns">
      <div class="input-field">
        <label>Company name:</label>
        <p>{{userOverallKpis.company_name || '/'}}</p>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="six columns">
      <div class="input-field">
        <label>Link to profile:</label>
        <p><a href="{{userOverallKpis.profile_link}}">{{userOverallKpis.profile_link}}</a></p>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="six columns">
      <div class="input-field">
        <label>User's Date Created</label>
        <p>{{userOverallKpis.date_created | date:'MM/dd/yyyy'}}</p>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="six columns">
      <div class="input-field">
        <label>First name:</label>
        <p>{{userOverallKpis.first_name || '/'}}</p>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="six columns">
      <div class="input-field">
        <label>Last name:</label>
        <p>{{userOverallKpis.last_name || '/'}}</p>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="six columns">
      <div class="input-field">
        <label>Number of cases:</label>
        <p>{{userOverallKpis.numb_of_cases}}</p>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="six columns">
      <div class="input-field">
        <label>Number of Completed Public Cases</label>
        <p>{{userOverallKpis.numb_of_public_cases}}</p>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="six columns">
      <div class="input-field">
        <label>Number of Completed Private Cases</label>
        <p>{{userOverallKpis.numb_of_private_cases}}</p>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="six columns">
      <div class="input-field">
        <label>Number of Photos</label>
        <p>{{userOverallKpis.numb_of_photos}}</p>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="six columns">
      <div class="input-field">
        <label>Number of Deleted Cases</label>
        <p>{{userOverallKpis.numb_of_deleted_cases}}</p>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="six columns">
      <div class="input-field">
        <label>Number of Drafts</label>
        <p>{{userOverallKpis.numb_of_drafts}}</p>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="six columns">
      <div class="input-field">
        <label>Number of Web Sign-Ins</label>
        <p>{{userOverallKpis.numb_of_signins}}</p>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="six columns">
      <div class="input-field">
        <label>Number of Profile Views</label>
        <p>{{userOverallKpis.numb_of_profile_views}}</p>
      </div>
    </div>
  </div>
  <button class="btn save-button" (click)="exportUserToCsv()">Export to CSV</button>
</div>
