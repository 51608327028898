<div class="container">
  <div class="row">
    <div class="loader" [hidden]="!processingRequest">
      <span>Processing request...</span>
      <div class="animation"></div>
    </div>
    <div class="login-wrapper">
      <div class="row logo">
        <div class="twelve columns">
          <img src="../../../assets/images/logo.png" />
        </div>
      </div>
      <form novalidate [formGroup]="form">
        <div class="row">
          <div class="twelve columns">
            <label for="email">Enter your email and we will send you a link to reset your password.</label>
          </div>
        </div>

        <div class="row">
          <div class="twelve columns">
            <input type="email" formControlName="formEmail" class="u-full-width" placeholder="Email" id="email" name="email">
            <div class="form-control-feedback"
                 *ngIf="showError || (formEmail.errors && formEmail.touched)">
              <p class="error">{{errorContent}}</p>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="twelve columns">
            <input class="button-primary u-full-width" value="Submit" type="submit" (click)="sendEmail()">
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
