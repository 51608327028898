<ul>
  <li *ngFor="let case of cases"><a style="cursor: pointer" (click)="getCase(case.id)">{{case.id}}</a></li>

  <div *ngIf="case">
    {{case.id}}
    {{case.created_at}}
  </div>

  CASES WORKS
</ul>
