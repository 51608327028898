<app-navbar></app-navbar>

<div class="container">
  <div class="row document-container">
    <h2>Privacy Policy</h2>

    <div class="row">
      <div class="twelve columns">
        <p>HUMAZEMD, LLC (the “<u>Company</u>”) respects the privacy of its customers and the visitors and users of its websites.</p>
        <p>This policy (“<u>Privacy Policy</u>”) is intended to inform you of our policies and practices regarding the collection,
          use, and disclosure of any personal information we obtain about you based upon your use of our website, products,
          social media sites that bear our trademarks or trade dress (such as our Facebook, Twitter and Instagram sites)
          or services (collectively, our “<u>Services</u>”). </p>
        <p>
          The Company collects information differently from different types of Service users.  Visitors to the Services are divided into two categories:
        </p>
        <ul>
          <li>“<u>Customers</u>” are users of the Services who have provided the Company with personally-identifiable information about themselves for the
            purpose of registering with the Services and creating a profile for themselves.</li>
          <li>“<u>Unregistered Users</u>” are persons who are not Customers.</li>
        </ul>


        <p class="document-titles">
          <b>How the Company Collects Personal Information</b>
        </p>
        <p>“<u>Personal Information</u>” is information about you that is personally identifiable to you such as your name, address,
          e-mail address, phone number, and other non-public information that is associated with the foregoing, as well as
          log-in information and passwords (other than log-in information and passwords that constitute “<u>Financial Information</u>”
          as defined below).  We pay close attention to how Personal Information is collected, used, and shared, and we use
          Personal Information sensibly.
        </p>
        <p>The Company does not collect Personal Information from Unregistered Users. All users of the Company’s Services who provide Personal Information are Customers.</p>
        <p>The Company collects Personal Information from Customers through the online registration process in which Customers are asked to identify themselves and to
          provide their names, addresses, email addresses, telephone numbers, and similar identifying information. </p>

        <p class="document-titles">
          <b>How the Company Collects Financial Information</b>
        </p>
        <p>“<u>Financial Information</u>” is information about a person’s financial condition or information about a person’s
          bank accounts, credit accounts, financial accounts, escrow accounts (such as PayPal or similar services), payment
          history, and records of invoices to that person, payments made by that person, other information pertaining to
          payment obligations, log-in information, and payments to online accounts that have the power to initial or to
          receive payments.</p>

        <p>We pay close attention to how Financial Information is collected, used, and shared, and we use Financial Information sensibly.</p>

        <p>The Company does not collect Financial Information from Unregistered Users. All users of the Company’s website
          that provide Financial Information are Customers.</p>

        <p>The Company collects Financial Information from Customers through the online registration process and through the online billing
          process in which Customers are asked to provide a means of effecting payment for goods or services received from the Company
          and through which information is created pertaining to Company invoices, Customer payments, and other data pertaining to
          Customer payment obligations.</p>

        <p class="document-titles">
          <b>USER CONSENT</b>
        </p>
        <p>BY VISITING OUR WEBSITE OR USING OUR PRODUCTS OR SERVICES, YOU AGREE TO THE TERMS OF THIS PRIVACY POLICY.</p>

        <p class="document-titles">
          <b>Use of Personal Information</b>
        </p>
        <p>
          The Personal Information we gather from Customers helps us learn about our Customers.  We use this information to
          provide better tailored features, performance, and support of our solutions, products, and services, and to contact
          you from time to time with information and offers about our or a third party’s products and services.
        </p>
        <p>
          We may also use Personal Information collected from Customers to suggest, through the interactive functions available
          on the Company’s website, other products and services that may be of interest to the Customer based upon the Customer’s
          website usage history and personal profile.  We may also share Personal Information of Customers with other businesses
          to offer Customers products, services, and promotions. In addition, we may use such Personal Information with other
          businesses that may contact Customers regarding requests for service. We may also use Personal Information to provide
          Customers with electronic newsletters or promotional e-mails as well as information and offers about our products and
          services.
        </p>

        <p class="document-titles">
          <b>Use of Financial Information</b>
        </p>
        <p>
          We may use Financial Information collected from Customers in order to bill Customers for fees due, process payments,
          collect amounts due, manage Customer billing disputes, and otherwise manage the billing and collection of fees from
          Customers in accordance with Orders they have submitted (collectively, the “<u>Billing Process</u>”).
        </p>

        <p>
          We do not share Financial Information with other parties (except to the extent Financial Information is shared with banks,
          financial intermediaries, or collection agencies in connection with the Billing Process).
        </p>

        <p class="document-titles">
          <b>Personal Information Collected via Technology</b>
        </p>
        <p>
          As you navigate our website, certain passive information may also be collected, including your Internet Protocol address,
          browser type, and operating system. We also use cookies and navigational data like Uniform Resource Locators (URL) to
          gather information regarding the date and time of your visit and the solutions and information for which you searched
          and viewed. This type of information is collected to make our solutions and services more useful to you.
        </p>

        <p class="document-titles">
          <b>Personal Information We Receive from Other Sources</b>
        </p>
        <p>We may receive Personal Information about you from other sources like your telephone or email address. We may add this
          information to the information we have already collected about you in order to improve the solutions and services we provide.
        </p>

        <p class="document-titles">
          <b>Disclosure of Personal Information</b>
        </p>
        <p>
          Except as otherwise stated in this Privacy Policy, we do not generally sell Personal Information to third parties. We may
          engage third parties to assist us, and, in connection with such assistance, we may provide them with Personal Information
          that we have collected from Customers and that is necessary for them to assist us.
        </p>
        <p>
          We also use third parties to maintain and to store Personal Information and to process any payment you make to us.
          Generally, these third parties are required not to use your Personal Information other than to provide the services
          requested by the Company.
        </p>
        <p>
          We may at any time have subsidiaries and other companies under a common control (collectively, “affiliates”). We may
          share some or all of your Personal Information with these affiliates, in which case we will require our affiliates
          to honor this Privacy Policy.
        </p>
        <p>
          If our Company or our assets are acquired by another company, that company will possess the Personal Information
          collected by us and it will assume the rights and obligations regarding your Personal Information as described in
          this Privacy Policy.
        </p>
        <p>
          We may disclose your Personal Information if we believe in good faith that such disclosure is necessary (a) to comply
          with relevant laws or to respond to subpoenas or warrants served on the Company; or (b) to protect and defend the rights
          or property of the Company or our Customers.
        </p>

        <p class="document-titles">
          <b>Your Choices Regarding Your Personal Information</b>
        </p>
        <p>
          Should you decide to stop receiving marketing e-mail communications or newsletters from us, you will have the opportunity
          to “opt-out” by following the unsubscribe instructions provided in the e-mail. Should you decide to stop receiving
          application generated emails such as system alerts, application confirmation emails, or other application generated emails,
          you must request this by emailing support@humazemd.com. If at any time you wish to subscribe to any communications from us,
          you may do so by contacting us at support@humazemd.com.  Despite your indicated e-mail preferences, we may send you
          e-mail regarding updates to legal notices applicable to the account you created with us. From time to time, we may
          contact you by telephone to offer you products or services which we believe may be valuable to you. Should you prefer
          not to receive such calls, please advise the representative who contacts you or contact customer service.
        </p>

        <p class="document-titles">
          <b>Regarding Minors</b>
        </p>
        <p>
          Our services are not designed for minors under the age of 18. We do not intentionally gather Personal Information
          about visitors who are under the age of 18.
        </p>

        <p class="document-titles">
          <b>Links to Other Sites</b>
        </p>
        <p>
          Our provision of a link to any other website or location is for your convenience and does not signify our endorsement
          of such other website or location or its contents. We have no control over, do not review, and cannot be responsible
          for these outside websites or their content. Please be aware that the terms of our Privacy Policy do not apply to
          these outside websites. It is your responsibility to monitor the use made of any outside website of information
          that you supply to such outside website.
        </p>

        <p class="document-titles">
          <b>Security</b>
        </p>
        <p>
          We use reasonable measures to maintain the security of your Personal Information. Even so, technical mistakes are
          possible. No service provider, including our Company, can fully eliminate security risks associated with Personal
          Information.
        </p>

        <p class="document-titles">
          <b>Contact Us</b>
        </p>
        <p>
          Should you have any questions or concerns regarding this Privacy Policy, please contact us:
        </p>
        <p class="address-details">HUMAZEMD, LLC</p>
        <p class="address-details">200 Galleria Pkwy SE</p>
        <p class="address-details">Suite 400</p>
        <p>Atlanta, Georgia 30339</p>

        <p class="document-titles">
          <b>Privacy Policy Updates</b>
        </p>
        <p>
          This Privacy Policy is subject to occasional revision, and if we make any changes in the way we use your Personal
          Information, we shall notify you by posting an updated Private Policy at
          <a href="#" routerLink="/privacy">{{environmentUrl}}/privacy</a>.  If you object
          to any such changes, you must cease using our website, products, or services, and opt-out of continued communication
          with us. Continued use of our Services following notice of any such changes shall indicate your acknowledgment of
          such changes and agreement to be bound by the terms and conditions of such changes.
        </p>

        <p class="document-titles">
          <b>Choice of Law</b>
        </p>
        <p>
          If you choose to visit the Company’s website or use our products or services, any dispute over privacy is subject to
          this Privacy Policy as governed by the laws of the State of Georgia.
        </p>

        <br>
        <br>
        <br>
        <p style="font-style: italic">Last revised: April 17, 2018</p>
      </div>
    </div>
  </div>
</div>
<app-footer></app-footer>
