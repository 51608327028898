<div class="row">
  <div class="col-xs-12 col-12 col-md-6 form-group">
    <input
           type="text"
           placeholder="from"
           class="form-control"
           bsDatepicker
           [bsConfig]="{containerClass:'theme-default'}"
           (bsValueChange)="onFromValueChange($event)"
           required
    >
  </div>
  <div class="col-xs-12 col-12 col-md-6 form-group">
    <input
           type="text"
           placeholder="to"
           class="form-control"
           bsDatepicker
           [bsConfig]="{containerClass:'theme-default'}"
           (bsValueChange)="onToValueChange($event)"
    >
  </div>
</div>
