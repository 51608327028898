<app-navbar></app-navbar>
<div class="top-banner">
  <div class="container">
    <div class="row hero">
      <div class="six columns">
        <h2>Empower Your Before <br> and After Photos.</h2>
        <p class="lead">
          Organize all of your Before & After photos, quickly access your custom gallery, promote select outcomes across social
          media, and increase your practice revenue.
        </p>
        <a class="button button-primary desktop" routerLink="/signup" href="#"> Start Maximizing Your Photos Today </a>
        <a class="button button-primary mobile" routerLink="/signup" href="#"> Start Maximizing Your <br> Photos Today </a>
      </div>
    </div>
  </div>
</div>
<div class="middle-banner">
  <div class="promo hero">
    <div class="mobile-picture">
      <div class="floating-image">
        <img src="../../assets/images/iphone.png" class="u-max-full-width" />
      </div>
      <div class="custom-separator">
        <div class="lines"></div><div class="circles"></div>
      </div>
      <div class="custom-separator">
        <div class="lines"></div><div class="circles"></div>
      </div>
      <div class="custom-separator">
        <div class="lines"></div><div class="circles"></div>
      </div>
      <div class="custom-separator">
        <div class="lines"></div><div class="circles"></div>
      </div>
    </div>
    <div class="mobile-banner">
      <h2>Seamless Onboarding</h2>
      <p class="lead">Access HumazeMD on your computer, tablet, or mobile<br> device via our App</p>
      <p class="lead">Start posting your cases in seconds</p>
      <p class="lead">Easily upload and tag both new and existing photos</p>
      <p class="lead">Dedicated Success Manager assigned to your practice</p>
      <a routerLink="/signup" href="#" class="button join">Join HumazeMD Today</a>
      <a href="https://apps.apple.com/us/app/humazemd/id1385144079" target="blank">
        <img class="app-store-logo" src="../../assets/images/download-on-app-store.png" alt="app-store">
      </a>
    </div>
  </div>
</div>
<div class="bottom-banner">
  <div class="organize-image">
    <img src="../../assets/images/new_orginaze.png" />
  </div>
  <div class="organize-text">
    <div class="promo hero">
      <h2>Organize & Showcase</h2>
      <p class="lead">
        <b>Upload, organize and showcase your Before & After photos.</b> These photos can be tagged with Treatment, Age, Skin Type,
        Gender and Brand for faster access and to show relatable patient outcomes.
      </p>
      <p class="lead">
        <b>Website Integration.</b> With this included benefit, embed your real-time HumazeMD Gallery directly to your practice website
        to promote your selected outcomes.
      </p>
      <p class="lead">
        <b>Unlimited Storage.</b> Manage all your photos easily and curate the ideal Before & After Gallery to showcase all the
        treatments you offer.
      </p>
      <a routerLink="/signup" href="#" class="button join">Join HumazeMD Today</a>
    </div>
  </div>
</div>
<div class="container">
  <div class="separator"></div>
  <div class="promo" id="pricing">
    <div class="row">
      <div class="five columns card left">
        <div class="pricing-header">
          <div class="title">Professional</div>
          <div class="bold-500">
            Practice with 1-5 locations
          </div>
          <div class="price bold-800">$149</div>
          <div class="price-description">
            <div class="mute-text">/month billed annually</div>
            <div class="separator"></div>
            <div class="mute-text">($199/month billed monthly)</div>
          </div>
          <a class="button button-primary" routerLink="/signup" href="#"> Sign Up Now </a>
        </div>
        <div class="pricing-list">
          <p class="bold-800">+ Includes</p>
          <ul>
            <li><i class="fa fa-check"></i> Unlimited case photo uploads</li>
            <li><i class="fa fa-check"></i> Tag with hundreds of treatment types</li>
            <li><i class="fa fa-check"></i> Filter & Showcase on mobile device</li>
            <li><i class="fa fa-check"></i> Share case via SMS with patient</li>
            <li><i class="fa fa-check"></i> One-click social media posting</li>
            <li><i class="fa fa-check"></i> Dedicated support team</li>
            <li><i class="fa fa-check"></i> Unlimited users</li>
            <li><i class="fa fa-check"></i> Website gallery integration</li>
          </ul>
        </div>
      </div>
      <div class="five columns card right">
        <div class="pricing-header">
          <div class="title">Enterprise</div>
          <div class="bold-500">
            Practice with 5+ locations
          </div>
          <div class="price bold-800">CUSTOM</div>
          <div class="price-description">
            <div>Custom Pricing & Integration</div>
            <div>sales@humazemd.com</div>
          </div>
          <a class="button button-primary" routerLink="/contact" href="#"> Request a Demo </a>
        </div>
        <div class="pricing-list">
          <p class="bold-800">+ Includes</p>
          <ul>
            <li><i class="fa fa-check"></i> Everything from Professional</li>
            <li><i class="fa fa-check"></i> Featured posts on feed and social</li>
            <li><i class="fa fa-check"></i> Dedicated account rep</li>
            <li><i class="fa fa-check"></i> Unlimited users</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
<app-footer></app-footer>
