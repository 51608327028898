import {Component, OnInit} from '@angular/core';
import { AdminService } from '../admin/admin.service';
import { Router } from '@angular/router';
import { DataService} from '../helpers/data-service';

@Component({
  selector: 'home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss', './helpers.scss']
})
export class HomePageComponent implements OnInit {
  public news;
  public banners;
  treatments = [];
  skinType;
  genders = [];
  selectedTypes = [];

  constructor(private _newsService: AdminService,
              private data: DataService, private router: Router) { }

  ngOnInit() {
    this.getLandingPageNews();
    this.getBanners();

    if (window.location.hash === '#pricing') {
      setTimeout(() => {
        document.getElementById('pricing-separator').scrollIntoView();
      }, 0);
    }
  }

  getLandingPageNews() {
    this._newsService.getLandingPageNews().subscribe(
      data => { this.news = data; },
      err => {}
    );
  }
  getBanners() {
    this._newsService.getBanners().subscribe(
      data => {
        this.banners = data;
      },
      error => {}
    );
  }

  scroll(el) {
    el.scrollIntoView();
  }

  watchVideo() {
    this.router.navigate(['/watch-the-video']);
  }

}
