import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-privacy-page',
  templateUrl: './privacy-page.component.html',
  styleUrls: ['../home-page.component.scss']
})
export class PrivacyPageComponent implements OnInit {
  public environmentUrl;

  constructor() { }

  ngOnInit() {
    this.environmentUrl = window.location.host;
  }

}
