import { Component, OnInit } from '@angular/core';
import {Location} from '@angular/common';
import {ToastrService} from 'ngx-toastr';
import {ActivatedRoute, Router} from '@angular/router';
import {AdminService} from '../../admin.service';

@Component({
  selector: 'app-create-edit-gender',
  templateUrl: './create-edit-gender.component.html',
  styleUrls: ['../../admin.component.scss']
})
export class CreateEditGenderComponent implements OnInit {

  private genderId;
  public gender;

  constructor(private router: Router, private adminService: AdminService,
              private toastr: ToastrService, private location: Location,
              private activeRouter: ActivatedRoute) {
    this.activeRouter.params.subscribe(params => {
      this.genderId = params['id'];
    });
  }

  ngOnInit() {
    if (this.genderId) {
      this.getGender();
    } else {
      this.gender = {
        name: '',
        is_deleted: false
      };
    }
  }

  getGender() {
    this.adminService.getGender(this.genderId).subscribe(
      data => {
        this.gender = data;
      }, err => {
        this.toastr.error('Oh... something went wrong');
      }
    );
  }

  saveGender() {
    this.adminService.createGender(this.gender).subscribe(
      data => {
        this.toastr.success('Successfully created');
        this.router.navigate(['/admin/genders']);
      }, err => {
        this.toastr.error('Could not create gender');
      }
    );
  }

  updateGender() {
    this.adminService.updateGender(this.gender).subscribe(
      data => {
        this.toastr.success('Successfully updated');
        this.router.navigate(['/admin/genders']);
      }, err => {
        this.toastr.error('Could not update gender');
      }
    );
  }

  editGender() {
    if (this.genderId) {
      this.updateGender();
    } else {
      this.saveGender();
    }
  }

  goBack() {
    this.location.back();
  }
}
