import { Component, OnInit } from '@angular/core';
import { AdminService } from '../admin.service';
import { saveAs } from 'file-saver/FileSaver';
import { DatePipe } from '@angular/common';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['../admin.component.scss']
})
export class UsersComponent implements OnInit {

  public users;
  constructor(private adminService: AdminService, private datePipe: DatePipe, private toastr: ToastrService) {
  }

  ngOnInit() {
    this.getAllUsers();
  }

  getAllUsers() {
    this.adminService.getUsers(false).subscribe(data => {
      this.users = data;
    });
  }

  exportAllUsersToCsv() {
    this.adminService.exportAllUsersToCsv().subscribe(
      data => {
        const blob = new Blob([data], { type: 'text/csv'});
        saveAs(blob, 'all-users' + this.datePipe.transform(Date.now(), 'yyyy-MM-dd') + '.csv');
        this.toastr.success('File is downloading');
      },
      err => {
        this.toastr.error('Unable to download file');
      }
    );
  }

  showDisabled(event) {
    this.adminService.getUsers(event.target.checked).subscribe(data => {
      this.users = data;
    });
  }

}
