<div class="treatment-multi-selector">
  <div class="input-field">
    <div [ngClass]="getWrapClass()">
      <ul>
        <li *ngFor="let item of selectedTreatments" [ngClass]="home ? 'selected-landing' : 'selected'" >
          <span>{{item.name}}</span>
          <a (click)="remove(item)">x</a>
        </li>
        <li class="search-field">
          <input ngClass="landing-height" id="treatment" type="text" [ngClass]="getBrandClass()"
                 [(ngModel)]=treatment (click)=getDevices() (keyup)="filter()" placeholder="{{placeholder}}" autocomplete="off">
        </li>
      </ul>
    </div>
  </div>
  <div class="suggestions" *ngIf="filteredList.length > 0" (clickOutside)="onClickedOutside($event)">
    <ul *ngFor="let item of filteredList">
      <li>
        <a (click)="select(item)" *ngIf="item.is_visible">{{item.name}}</a>
      </li>
    </ul>
  </div>
</div>
