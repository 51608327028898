<i class="fa fa-chevron-left fa-2x back-button" aria-hidden="true" title="Go Back" (click)="goBack()"></i>

<div class="container" *ngIf="treatment">
  <div class="twelve columns">

    <form (ngSubmit)="editTreatment()" #form="ngForm">
      <div class="form-group">
        <label for="treatment-name">Treatment name</label>
        <input id="treatment-name" name="treatment_name" class="u-full-width" [(ngModel)]="treatment.name"/>
      </div>
      <div class="form-group">
        <label for="is-deleted">Is Deleted</label>
        <input id="is-deleted" name="is_deleted" class="u-full-width" [(ngModel)]="treatment.is_deleted" type="checkbox"/>
      </div>
      <button type="submit" class="button save-button">Save</button>
    </form>
  </div>
</div>
