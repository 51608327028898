<i class="fa fa-chevron-left fa-2x back-button" aria-hidden="true" title="Go Back" (click)="goBack()"></i>

<div class="container">
  <div class="twelve columns">

    <form novalidate (ngSubmit)="saveUser()" [formGroup]="form">
      <div class="registration-form">
        <div class="row">
          <div class="two columns">
            <label for="prefix">Prefix</label>
            <select class="u-full-width custom-placeholder" name="prefix" id="prefix" formControlName="prefix">
              <option value="" selected> </option>
              <option *ngFor="let prefix of prefixes" [value]="prefix">{{prefix}}</option>
            </select>
          </div>

          <div class="five columns">
            <label for="firstName">First name *</label>
            <input type="text" formControlName="firstName" id="firstName" name="firstName" class="form-control" placeholder="John" required>
            <div class="form-control-feedback"
                 *ngIf="firstName.errors && (firstName.dirty || firstName.touched)">
              <p *ngIf="form.hasError('required', 'firstName')" class="error">First name is required</p>
            </div>
          </div>
          <div class="five columns">
            <label for="lastName">Last name *</label>
            <input type="text" formControlName="lastName" id="lastName" name="lastName" class="form-control" placeholder="Doe" required>
            <div class="form-control-feedback"
                 *ngIf="lastName.errors && (lastName.dirty || lastName.touched)">
              <p *ngIf="form.hasError('required', 'lastName')" class="error">Last name is required</p>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="six columns">
            <label for="email">Email *</label>
            <input type="email" formControlName="email" id="email" name="email" class="form-control" placeholder="example@email.com" required>
            <div class="form-control-feedback"
                 *ngIf="email.errors && (email.dirty || email.touched)">
              <p *ngIf="form.hasError('required', 'email')" class="error">Email is required</p>
            </div>
          </div>
          <div class="six columns">
            <label for="password">Password *</label>
            <input type="password" formControlName="password" id="password" name="password" placeholder="*********" class="form-control" required>
            <div class="form-control-feedback"
                 *ngIf="password.errors && (password.dirty || password.touched)">
              <p *ngIf="password.errors.minlength" class="error">Password must be at least 6 characters long</p>
              <p *ngIf="form.hasError('required', 'password')" class="error">Password is required</p>
              <p *ngIf="!password.errors.minlength && password.errors.pattern" class="error">Password needs to have at least one uppercase one lowercase character and number</p>
            </div>
          </div>
        </div>
      </div>
      <button class="button-primary" type="submit" >Create</button>

    </form>
  </div>

</div>

