<app-navbar></app-navbar>

<div class="container">

  <h2>Press and Media Kit</h2>

  <div class="row">
    <div class="twelve columns">
      <br>
      <p>Information provided on this page is for news and media related issues.</p>

      <p>If you have any questions, please
        <a href="/contact">contact</a> us immediately. </p>
    </div>
  </div>

</div>
<app-footer></app-footer>