import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { AuthService } from '../../../auth/auth.service';

@Component({
  selector: 'app-feed-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  @Input() user;
  @Output() activeTabChanged: EventEmitter<Array<boolean>> = new EventEmitter();
  casesActive = true;
  aboutActive = false;
  draftsActive = false;
  currentUserId;

  constructor(private _authService: AuthService) { }

  ngOnInit() {
    this.currentUserId = this._authService.getCurrentUser() !== undefined ? this._authService.getCurrentUser().id : '';
  }

  tabClicked(tab) {
    switch (tab) {
      case 'cases':
        this.casesActive = true;
        this.aboutActive = false;
        this.draftsActive = false;
        break;
      case 'about':
        this.aboutActive = true;
        this.casesActive = false;
        this.draftsActive = false;
        break;
      case 'drafts':
        this.draftsActive = true;
        this.aboutActive = false;
        this.casesActive = false;
        break;
    }
    this.activeTabChanged.emit([this.casesActive, this.aboutActive, this.draftsActive]);
  }

  isOwner() {
    return this.currentUserId && this.currentUserId === +this.user.id;
  }

}
