import { Component, OnInit } from '@angular/core';
import { AdminService } from '../../admin.service';
import { ToastrService } from 'ngx-toastr';
import { ActivationEnd, Router} from '@angular/router';
import { saveAs } from 'file-saver/FileSaver';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-user-overall-kpis',
  templateUrl: './user-overall-kpis.component.html',
  styleUrls: ['../../admin.component.scss', './user-overall-kpis.component.scss']
})
export class UserOverallKpisComponent implements OnInit {
  public userOverallKpis;
  public userId;

  constructor(private _adminService: AdminService, private toastr: ToastrService,
              private router: Router, private datePipe: DatePipe) {
    this.router.events.subscribe(event => {
      if (event instanceof ActivationEnd) {
        if (event.snapshot.params.id) {
          this.userId = event.snapshot.params.id;
        }
      }
    });
  }

  ngOnInit() {
    this.getUserOverallKpis();
  }

  getUserOverallKpis() {
    this._adminService.getUserOverallKips(this.userId).subscribe(
      data => {
        this.userOverallKpis = data;
      }
    );
  }
  exportUserToCsv() {
    this._adminService.exportUserKpisToCsv(this.userId).subscribe(
      data => {
        const blob = new Blob([data], { type: 'text/csv'});
        saveAs(blob, this.userOverallKpis.first_name + '_' + this.userOverallKpis.last_name
          + this.datePipe.transform(Date.now(), 'yyyy-MM-dd') + '.csv');
        this.toastr.success('File is downloading');
      },
      err => {
        this.toastr.error('Unable to download file');
      }
    );
  }

}
