import { Component, OnInit } from '@angular/core';
import {AdminService} from '../admin.service';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'app-treatments',
  templateUrl: './treatments.component.html',
  styleUrls: ['../admin.component.scss']
})
export class TreatmentsComponent implements OnInit {
  public treatments;

  constructor(private _adminService: AdminService, private toastr: ToastrService) { }

  ngOnInit() {
    this.getTreatments();
  }

  getTreatments() {
    this._adminService.getTreatments().subscribe(
      data => {
        this.treatments = data;
      },
      error => {
        this.toastr.error('Unable to load treatments.');
      }
    );
  }

  deleteTreatment(id) {
    const treatment = {
      id: id,
      is_deleted: true
    };
    this._adminService.updateTreatment(treatment.id, treatment).subscribe(
      data => {
        this.toastr.success('Successfully deleted');
        this.getTreatments();
      }, err => {
        this.toastr.error('Could not delete treatment');
      }
    );
  }

}
