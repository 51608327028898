<i class="fa fa-chevron-left fa-2x back-button" aria-hidden="true" title="Go Back" (click)="goBack()"></i>

<div class="container" *ngIf="manufacturer">
  <div class="twelve columns">

    <form (ngSubmit)="editManufacturer()" #form="ngForm">
      <div class="form-group">
        <label for="first-name">Name</label>
        <input id="first-name" name="first_name" class="u-full-width" [(ngModel)]="manufacturer.name"/>
      </div>
      <div class="form-group">
        <label for="is-disabled">Is deleted</label>
        <input id="is-disabled" name="is_disabled" class="u-full-width" [(ngModel)]="manufacturer.is_deleted" type="checkbox"/>
      </div>
      <button type="submit" class="button save-button">Save</button>

    </form>
  </div>

</div>
