import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { AuthService } from '../auth/auth.service';
import {unescape} from 'querystring';

@Injectable()
export class DataService {

  private filters = new BehaviorSubject<any>({});
  currentFilters = this.filters.asObservable();

  private profileImage = new BehaviorSubject<string>('');
  private logoImage = new BehaviorSubject<string>('');
  currentImage = this.profileImage.asObservable();

  private firstName = new BehaviorSubject<string>('');
  currentName = this.firstName.asObservable();

  private providerUrl = new BehaviorSubject<string>('');
  currentProviderUrl = this.providerUrl.asObservable();

  constructor(private authService: AuthService) {
    const currentUser = authService.getCurrentUser();
    if (currentUser) {
      this.profileImage.next(currentUser.image_url);
      this.logoImage.next(currentUser.watermark_image_url);
      this.firstName.next(currentUser.first_name);
      this.providerUrl.next(currentUser.provider_profile_url);
    }
  }

  changeFilters(data) {
    this.filters.next(data);
  }

  changeProfileImage(data) {
    this.profileImage.next(data);
  }

  changeWtmkLogoImage(data) {
    this.logoImage.next(data);
  }

  changeName(data) {
    this.firstName.next(data);
  }

  changeUrl(data) {
    this.providerUrl.next(data);
  }

  buildThumbUrl(url) {
    const dataArray = url.split('/');
    dataArray.splice(4, 0, 'thumb');
    return dataArray.join('/');
  }

  getUuid(type) {
    const uuid = [];

    for (let i = 0; i < 5; i++) {
      uuid.push(Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1));
    }

    return uuid.join('-') + '.' + type;
  }
  dataURItoBlob(dataURI) {
    // convert base64/URLEncoded data component to raw binary data held in a string
    let byteString;
    if (dataURI.split(',')[0].indexOf('base64') >= 0) {
      byteString = atob(dataURI.split(',')[1]);
    } else {
      byteString = unescape(dataURI.split(',')[1]);
    }

    // separate out the mime component
    const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

    // write the bytes of the string to a typed array
    const ia = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ia], {type: mimeString});
  }
  checkUploadedFileType(type) {
    return type.toString() === 'image/jpeg' || type.toString() === 'image/png' ? true : false;
  }

  dataURLtoFile(dataUrl, filename) {
    var arr = dataUrl.split(','), mime = arr[0].match(/:(.*?);/)[1],
          bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
      while(n--){
          u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], filename, {type:mime});
  }

  getCaseAltValues(caseItem) {
    let brands = ' ';
    for (let i = 0; i < caseItem.brands.length; i++) {
      brands = brands + caseItem.brands[i].name + ' ';
    }

    let treatments = ' ';
    for (let i = 0; i < caseItem.treatments.length; i++) {
      treatments = treatments + caseItem.treatments[i].name + ' ';
    }

    const genderName =  caseItem.gender ? caseItem.gender.name : '';
    const skinTypeName =  caseItem.skin_type ? caseItem.skin_type.name : '';
    const skinTypeDescription =  caseItem.description ? caseItem.description.name : '';
    return genderName + ' ' + skinTypeName + ' ' + skinTypeDescription + brands  + treatments;
  }
}


