<div class="search-results"
     infiniteScroll
     [infiniteScrollDistance]="0.1"
     [infiniteScrollThrottle]="1000"
     (scrolled)="onScroll()">
<div class="news-table-box news-wrapper">
  <button type="button" class="button save-button" [routerLink]="['add']">Add new news</button>
  <button type="button" class="button save-button" [routerLink]="['banner']">Add new banner</button>
  <table class="table">
    <thead class="thead-light">
      <tr>
        <th>Title</th>
        <th>Body</th>
        <th>Image url</th>
        <th>Author</th>
        <th>Type</th>
        <th>Show news</th>
        <th>News url</th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let singleNews of news" style="height: 100px">
        <td class="custom-table-row">{{singleNews.title}}</td>
        <td class="custom-table-row">{{singleNews.body}}</td>
        <td class="custom-table-row">{{singleNews.image}}</td>
        <td>{{singleNews.author}}</td>
        <td>{{singleNews.news_type}}</td>
        <td>
          <input class="form-check-input" style="margin-left:0px !important" type="checkbox" (change)="onCheckboxChange(singleNews)" [(ngModel)]="singleNews.is_showed">
        </td>
        <td class="custom-table-row">{{singleNews.news_url}}</td>
        <td>
          <i class="fa fa-edit" aria-hidden="true" [routerLink]="['edit']" (click)="editButton(singleNews)"></i>
          <i class="fa fa-trash custom-buttons" aria-hidden="true" mwlConfirmationPopover popoverTitle="<h6>Delete news</h6>" placement="top"
            (confirm)="deleteSingleNews(singleNews)" (cancel)="cancelClicked = true"></i>
        </td>
      </tr>
    </tbody>
  </table>
  </div>
</div>
