<i class="fa fa-chevron-left fa-2x back-button" aria-hidden="true" title="Go Back" [routerLink]="['/admin/cases']"></i>

<div class="container" *ngIf="caseItem">
  <p class="provider">Provider name: {{caseItem?.user.first_name}} {{caseItem?.user.last_name}}</p>
  <div class="seven columns">
    <div class="row wrapper">
      <form>
        <div class="case-form">
          <div class="row">
            <p class="create-case">Patient details</p>
            <div class="edit-button-box">
              <button (click)="openModal(confirmPopup)">{{caseItem.is_deleted ? 'Restore case' : 'Delete case'}}</button>
            </div>
          </div>
          <div class="row">
            <div class="six columns">
              <div class="input-field">
                <label>Gender:</label>
                <p>{{caseItem?.gender.name}}</p>
              </div>
            </div>
            <div class="six columns">
              <div class="input-field">
                <label>Skin type:</label>
                <div class="skins">
                  <div class="skin-color" [style.background-color]="caseItem?.skin_type.color"></div>
                  <span class="skin-name">{{caseItem?.skin_type.name}}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="six columns">
              <div class="input-field">
                <label>Ethnicity:</label>
                <p>{{caseItem?.ethnicity_id !== null ? caseItem?.ethnicity.name : 'N/A'}}</p>
              </div>
            </div>
            <div class="six columns">
              <div class="input-field">
                <label>Age:</label>
                <p>{{caseItem?.patient_age}}</p>
              </div>
            </div>
          </div>
        </div>
        <div class="form-separator"></div>
        <div class="case-form">
          <p class="create-case">Case details</p>
          <div class="row">
            <div class="six columns">
              <div class="input-field">
                <label>Treatments:</label>
                <ul>
                  <li *ngFor="let treatment of caseItem?.treatments">{{treatment.name}}</li>
                </ul>
              </div>
            </div>
            <div class="six columns">
              <div class="input-field">
                <label>Brands:</label>
                <ul>
                  <li *ngFor="let brand of caseItem?.brands">{{brand.name}}</li>
                </ul>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="twelve columns">
              <label>Description:</label>
              <p>{{caseItem?.description}}</p>
            </div>
          </div>
          <div class="row">
            <div class="twelve columns">
              <label>Case notes:</label>
              <p>{{caseItem?.settings_notes}}</p>
            </div>
          </div>
        </div>
        <div class="form-separator"></div>
        <div class="case-form">
          <p class="create-case">Case Images</p>
          <div class="row" *ngFor="let image of caseItem?.case_images; let i = index">
            <div class="twelve columns">
              <label *ngIf="i == 0" class="image-button">Before</label>
              <label *ngIf="i > 0" class="image-button">Treatment {{i}}</label>
              <button class="row image-button"><a href="{{image.cropped_image_url}}" target="_blank">Cropped image</a></button>
              <button class="row image-button"><a href="{{image.cropped_watermark_image_url}}" target="_blank">Cropped watermarked image</a></button>
              <button class="row image-button"><a href="{{image.original_image_url}}" target="_blank">Original image</a></button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>

<ng-template #confirmPopup>
  <div class="modal-body text-center confirm-modal">
    <div class="header">
      <p>Are you sure you want to {{caseItem.is_deleted ? 'restore' : 'delete'}} this case?</p>
      <div class="separator"></div>
    </div>

    <a class="button button-delete" (click)="confirmDelete(caseItem.id)" >Yes</a>
    <a class="button decline-button" (click)="decline()" >No</a>
  </div>
</ng-template>
